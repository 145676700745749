import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Spin, Row, Col, Divider } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import 'antd/dist/antd.min.css';
import './financeCardInfo.css';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

function FinanceCardInfo({ title, loader, data }) {
  const { t } = useTranslation();

  return (
    <Card bordered={false} style={{ marginRight: 5 }}>
      {!loader ? (
        <>
          <Title
            style={{ color: '#010c33', fontStyle: 'normal', marginBottom: 0 }}
            level={2}
          >
            <center>
              {data?.lastMonth <= data?.thisMonth && <CaretUpOutlined />}
              {data?.lastMonth > data?.thisMonth ? (
                <CaretDownOutlined className="caretdown-outlined" />
              ) : null}
              {data?.lastMonth !== 0 && data?.thisMonth
                ? Math.abs(
                    ((data.thisMonth - data.lastMonth) / data.lastMonth) * 100
                  ).toFixed(2)
                : 0}
              %
            </center>
          </Title>
          <Title
            style={{
              color: '#010c33',
              fontStyle: 'normal',
              fontWeight: 400,
              marginTop: 0,
            }}
            level={5}
          >
            <center>{title}</center>
          </Title>
          <div style={{ marginTop: 20 }}>
            <Row style={{ justifyContent: 'center' }}>
              <Col>
                <Title
                  style={{
                    color: '#010c33',
                    fontStyle: 'normal',
                    marginBottom: 0,
                    fontSize: 20,
                  }}
                  level={4}
                >
                  <center>
                    $
                    {data?.thisMonth !== 0
                      ? (data?.thisMonth ?? 0).toFixed(2)
                      : 0}
                  </center>
                </Title>
                <Title
                  style={{
                    color: '#010c33',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    marginTop: 0,
                    fontSize: 14,
                  }}
                  level={5}
                >
                  <center>{t('pandl.thisMonth')}</center>
                </Title>
              </Col>
              <Divider
                type="vertical"
                style={{
                  height: '2.8em',
                  marginTop: 6,
                  borderInlineColor: 'rgb(89, 89, 89)',
                }}
              />
              <Col>
                <Title
                  style={{
                    color: '#010c33',
                    fontStyle: 'normal',
                    marginBottom: 0,
                    fontSize: 20,
                  }}
                  level={4}
                >
                  <center>
                    $
                    {data?.lastMonth !== 0
                      ? (data?.lastMonth ?? 0).toFixed(2)
                      : 0}
                  </center>
                </Title>
                <Title
                  style={{
                    color: '#010c33',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    marginTop: 0,
                    fontSize: 14,
                  }}
                  level={5}
                >
                  <center>{t('pandl.lastMonth')}</center>
                </Title>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <center>
          <Spin size="medium" />
        </center>
      )}
    </Card>
  );
}

FinanceCardInfo.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    lastMonth: PropTypes.number,
    thisMonth: PropTypes.number,
  }).isRequired,
  loader: PropTypes.bool.isRequired,
};
export default FinanceCardInfo;
