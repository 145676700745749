import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Spin, Row, Typography } from 'antd';
import {
  CheckOutlined,
  CodeSandboxOutlined,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import openNotification from '../../../../components/Toastr';
import restockOrdersAPI from '../../../../api/restock-orders';
import couriersApi from '../../../../api/courier';
import '../steps.css';
import ReviewShipment from '../../../order/components/steps/components/ReviewShipment';
import ReviewBoxes from '../../../order/components/steps/components/ReviewBoxes';
import ReviewDetailContent from '../../../order/components/steps/components/ReviewDetailContent';
import ReviewLabelsDocuments from './ReviewLabelsDocuments';
import InformationBox from '../../../order/components/steps/components/InformationBox';
import ShipmentTypeTag from '../../../shipments/components/ShipmentTypeTag';
import ShipmentStatusTag from '../../../shipments/components/ShipmentStatusTag';
import FBMCouriers from '../../../../utils/FBMCouriers';
import EditPickUpModal from '../../../order/components/steps/components/Pickup/EditPickUpModal';
import { courierShipmentStatus } from '../../../../utils/const';
import PendingShipmentPayment from '../../../order/components/steps/components/PendingShipmentPayment';
import ShipmentTypeEnum from '../../../shipments/enums/shipmentType.enum';

function FullOrder({ orderId }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [firstCourierShipment, setFirstCourierShipment] = useState(null);
  const [isPartnered, setIsPartnered] = useState(null);
  const [fdaProducts, setFdaProducts] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState({});
  const [commercialInvoiceRequirements, setCommercialInvoiceRequirements] =
    useState();
  const [openEditPickUpModal, setOpenEditPickUpModal] = useState(false);
  const language = useSelector((store) => store.Session.language);

  const getOrder = async () => {
    setLoading(true);
    try {
      const restockResponse = await restockOrdersAPI.getContent(orderId);
      setOrder(restockResponse.data);
      if (
        restockResponse.data?.courierShipments &&
        restockResponse.data?.courierShipments.length > 0
      )
        setFirstCourierShipment(restockResponse.data.courierShipments[0]);
      const partnered =
        restockResponse.data.warehouse.address.countryCode ===
        restockResponse.data.destination;
      setIsPartnered(partnered);
      setFdaProducts(
        restockResponse.data.products.filter(
          (product) => product.expirationDate !== null
        )
      );
      const courierResponse = await couriersApi.getCourierCredentials(false, 1);
      const data = courierResponse.data.results.map((value) => ({
        key: value.id,
        name: value.courierCompanyName,
        accountNumber: value.accountNumber,
      }));
      const actualCourier = data.find(
        (courier) => courier.key === Number(restockResponse.data.courier)
      );
      setSelectedCourier(actualCourier);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Error al obtener orden de restock.',
      });
    }
    setLoading(false);
  };

  const getCommercialInvoiceRequirements = async () => {
    if (
      [FBMCouriers.FEDEX, FBMCouriers.LAPCOURIER].includes(
        selectedCourier?.name
      )
    ) {
      try {
        const response = await couriersApi.commercialInvoiceRequirements(
          selectedCourier.name,
          order.warehouse.address.countryCode,
          order.destination
        );
        setCommercialInvoiceRequirements(response.data);
      } catch (error) {
        /* empty */
      }
    }
  };

  useEffect(async () => {
    if (!order) {
      getOrder();
    }
  }, []);

  useEffect(() => {
    if (order && selectedCourier?.name) getCommercialInvoiceRequirements();
  }, [order, selectedCourier]);

  return (
    <div id="boxes" className="text-align-left">
      <Spin spinning={loading}>
        <PendingShipmentPayment
          courierShipments={order?.courierShipments}
          setOrder={setOrder}
        />
        <Row style={{ fontSize: '24px', fontWeight: 500 }}>
          {t('orders.shipment.summary.title2')}
        </Row>
        <Row
          style={{ fontSize: '16px', marginBottom: '20px' }}
          justify="space-between"
        >
          <Col>
            [{t('shipments.nameTypes.RestockOrder')}] {order?.name}
          </Col>
          <Col>
            <Row>
              <ShipmentTypeTag
                text={t('shipments.types.restock')}
                icon={<CodeSandboxOutlined />}
              />
              <Divider type="vertical" style={{ alignSelf: 'center' }} />
              {new Date(order?.createdAt).toLocaleDateString(language, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
              <Divider type="vertical" style={{ alignSelf: 'center' }} />
              <ShipmentStatusTag
                status={(() => {
                  const foundItem = order?.courierShipments?.find((item) =>
                    [
                      courierShipmentStatus.PENDING_COLLECTION_METHOD,
                      courierShipmentStatus.PENDING_PAYMENT,
                    ].includes(item.status)
                  );

                  return foundItem?.status || order?.state;
                })()}
                type="RestockOrder"
              />
              {order?.courierShipments?.find(
                (item) =>
                  item.status ===
                  courierShipmentStatus.PENDING_COLLECTION_METHOD
              ) && (
                <>
                  <Divider type="vertical" style={{ alignSelf: 'center' }} />
                  <Button
                    onClick={() => {
                      setOpenEditPickUpModal(true);
                    }}
                    size="small"
                    shape="round"
                    style={{ fontSize: 12 }}
                  >
                    Agendar modo de recolección aquí
                  </Button>
                </>
              )}
            </Row>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={24} style={{ marginBottom: '20px' }}>
            <InformationBox
              icon={
                <ExclamationCircleTwoTone twoToneColor="rgb(250, 173, 20, .5)" />
              }
              textInformationBox={
                <div style={{ width: '100%' }}>
                  <Typography.Paragraph className="w-600">
                    {t('orders.shipment.summary.successfullShipment.title')}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="icon-color">
                    <CheckOutlined />{' '}
                    {t(
                      'orders.shipment.summary.successfullShipment.amazonLabel'
                    )}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="icon-color">
                    <CheckOutlined />{' '}
                    {t('orders.shipment.summary.successfullShipment.boxLabel')}
                  </Typography.Paragraph>
                </div>
              }
              status="warning"
              display="block"
            />
          </Col>
          <Col span={24}>
            <ReviewDetailContent
              products={order?.products}
              selectedCourier={selectedCourier}
              name={`[${t('shipments.nameTypes.RestockOrder')}] ${order?.name}`}
              destination={`${order?.fboFulfillmentCompany} ${order?.destination}`}
              warehouse={order?.warehouse}
              shipmentType="Restock"
              isNational={isPartnered}
              boxType={order?.isPack ? 'Uniforme' : 'Mixta'}
              cargoType={order?.isPalletized ? 'Paletizada' : 'Individual'}
            />
          </Col>
          <Col span={24}>
            <ReviewBoxes order={order} isNational={isPartnered} />
          </Col>
          {order?.courierShipments && (
            <Col span={24}>
              <ReviewShipment
                courierShipments={order?.courierShipments}
                order={order}
                selectedCourier={selectedCourier}
                getOrder={getOrder}
                setOrder={setOrder}
                typeOfOrder={ShipmentTypeEnum.RESTOCK}
              />
            </Col>
          )}
          <Col span={24}>
            <ReviewLabelsDocuments
              restockOrder={order}
              setOrder={setOrder}
              courierShipments={order?.courierShipments}
              isNational={isPartnered}
              fdaProducts={fdaProducts}
              commercialInvoiceRequirements={commercialInvoiceRequirements}
            />
          </Col>
        </Row>
      </Spin>
      {openEditPickUpModal && (
        <EditPickUpModal
          isOpen={openEditPickUpModal}
          setIsOpen={setOpenEditPickUpModal}
          courierShipment={firstCourierShipment}
          courierCredentialId={selectedCourier?.key}
          getOrder={getOrder}
          warehouseId={order?.warehouse?.id}
          order={{
            id: order?.id,
            type: ShipmentTypeEnum.RESTOCK,
          }}
          previousCollectionMethod={{
            pickUp: firstCourierShipment?.pickup?.id,
            deliveryOffice: !!order?.officeDeliveryDate,
          }}
        />
      )}
    </div>
  );
}

FullOrder.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default FullOrder;
