import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col, Card, Typography, Button, Space, Dropdown } from 'antd';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { Line } from '@ant-design/plots';
import { thousandsSeparator } from '../../../../../utils/functions';
import selectors from '../../../../../redux/analytic/dashboard/selectors';

const { Title } = Typography;

function ChartLine({
  titleChart,
  titleCard,
  dataSource,
  currency,
  tooltipProperties,
  handleDownloadCSV,
  handleDownloadPDF,
  dropdownMenu,
}) {
  const language = useSelector((store) => store.Session.language);
  const currencyState = useSelector(selectors.selectCurrency);
  const { t } = useTranslation();
  const [data, setData] = useState([
    {
      children: [],
    },
  ]);

  moment.locale(language);

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  const percentage = Number(dataSource?.percentage) || 0;

  let color = 'grey';
  let arrow = null;

  if (percentage > 0) {
    color = '#33BA6A';
    arrow = <ArrowUpOutlined id="arrowVariantGreen" />;
  } else if (percentage < 0) {
    color = '#F25154';
    arrow = <ArrowDownOutlined id="arrowVariantRed" />;
  }

  const config = {
    data: dataSource.children || [],
    xField: 'date',
    yField: 'value',
    color: ['#00E5A6', '#969696', '#E8E8E8'],
    seriesField: 'type',
    legend: {
      layout: 'horizontal',
      position: 'top-right',
      maxItemWidth: 300,
      itemName: {
        formatter: (type) => t(`home.charts.${type}.title`, type),
      },
    },
    yAxis: {
      style: {
        stroke: '#F4664A',
        lineDash: [2, 2],
      },
    },
    xAxis: {
      label: {
        formatter: (date) => {
          if (date?.length === 7) {
            return moment(date).format('MMM');
          }
          return moment(date).format('D MMM');
        },
      },
    },
    height: 250,
    tooltip: tooltipProperties,
  };

  return (
    <>
      <div className="chartLineStyleContentSales">
        <div className="headerChart">
          <div>
            <Row>
              <Col span={21}>
                <Title level={4}>{titleChart}</Title>
                <Title
                  level={4}
                  style={{ color: '#969696', fontSize: 12, marginTop: 10 }}
                >
                  {(() => {
                    if (typeof data.length === 'undefined') {
                      if (data.children[0].date.length === 7) {
                        return t('home.charts.monthlyView');
                      }
                      return t('home.charts.dailyView');
                    }
                    return '';
                  })()}
                </Title>
              </Col>
              <Col span={3}>
                <Dropdown
                  overlay={dropdownMenu(
                    'light',
                    handleDownloadCSV,
                    handleDownloadPDF
                  )}
                >
                  <Button className="btn-basic">
                    <Space>
                      {t('common.download')}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </div>
        </div>
        <Row style={{ marginTop: 15 }}>
          <Col>
            <Card
              bodyStyle={{ padding: 10 }}
              style={{
                background: '#00E5A633',
                opacity: 0.9,
                border: 'none',
                marginLeft: 5,
                minWidth: 200,
                borderRadius: 4,
              }}
            >
              <div className="chartLineStyleContentSales">
                <Title level={4} style={{ fontStyle: 'normal' }}>
                  {titleCard}
                </Title>
                <Title
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    marginTop: 10,
                    fontStyle: 'normal',
                  }}
                  level={4}
                >
                  {dataSource?.total > 0
                    ? thousandsSeparator(dataSource?.total)
                    : 0}
                  {currency ? ` ${currencyState}` : ''}
                </Title>
                <Title
                  style={{
                    fontSize: 17,
                    fontWeight: 'bold',
                    marginTop: 5,
                    color,
                    fontStyle: 'normal',
                  }}
                  level={4}
                >
                  {arrow} {Math.abs(percentage)}%
                </Title>
                <Title
                  level={4}
                  style={{
                    color: '#010C33',
                    fontFamily: 'Poppins',
                    fontSize: 12,
                    fontStyle: 'normal',
                  }}
                >
                  {typeof data.length === 'undefined'
                    ? `${moment(data.children[0].date).format(
                        "D MMM [']YY"
                      )} - ${moment(
                        data.children[data.children.length - 1].date
                      ).format("D MMM [']YY")}`
                    : ''}
                </Title>
              </div>
            </Card>
          </Col>
          <Col>
            <Card
              bodyStyle={{ padding: 12 }}
              style={{
                background: '#C4C4C433',
                opacity: 0.9,
                border: 'none',
                marginLeft: 5,
                minWidth: 150,
                borderRadius: 4,
              }}
            >
              <div className="chartLineStyleContentSales">
                <Title
                  level={4}
                  style={{ fontSize: '14px', fontStyle: 'normal' }}
                >
                  {t(
                    `home.charts.${currency ? 'totalSales' : 'soldUnits'}.title`
                  )}
                </Title>
                <Title
                  level={4}
                  style={{
                    fontSize: '12px',
                    marginTop: 0,
                    fontStyle: 'normal',
                    fontWeight: 100,
                  }}
                >
                  {t('home.charts.lastPeriod')}
                </Title>
                <Title
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    marginTop: 10,
                    fontStyle: 'normal',
                  }}
                  level={4}
                >
                  {dataSource?.totalCompare > 0
                    ? thousandsSeparator(dataSource?.totalCompare)
                    : 0}
                  {currency ? ` ${currencyState}` : ''}
                </Title>
                <Title
                  level={4}
                  style={{
                    color: '#010C33',
                    fontFamily: 'Poppins',
                    fontSize: 12,
                    fontStyle: 'normal',
                  }}
                >
                  {typeof data.length === 'undefined'
                    ? `${moment(data.children[1]?.dateOrigin).format(
                        "D MMM [']YY"
                      )} - ${moment(
                        data.children[data.children.length - 1].dateOrigin
                      ).format("D MMM [']YY")}`
                    : ''}
                </Title>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {dataSource && <Line {...config} />}
    </>
  );
}

export default ChartLine;

ChartLine.propTypes = {
  titleChart: PropTypes.string.isRequired,
  titleCard: PropTypes.string.isRequired,
  dataSource: PropTypes.arrayOf(Object).isRequired,
  currency: PropTypes.string.isRequired,
  tooltipProperties: PropTypes.instanceOf(Object).isRequired,
  handleDownloadCSV: PropTypes.func.isRequired,
  handleDownloadPDF: PropTypes.func.isRequired,
  dropdownMenu: PropTypes.func.isRequired,
};
