import {
  Button,
  Col,
  Row,
  Space,
  Table,
  Tooltip,
  Popconfirm,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { marketplaceCurrency, productStatuses } from '../../../utils/const';
import EditProductsModal from './EditProductsModal';
import productApi from '../../../api/product';
import openNotification from '../../../components/Toastr';
import { getErrorMessage } from '../../../api/api';

const { Text } = Typography;

function MarketplaceTable({
  pagination,
  expandable,
  renderProductColumn,
  loading,
  filteredProducts,
  rowSelection,
  getDataSources,
}) {
  const { t } = useTranslation();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const [listingData, setListingData] = useState(null);

  const handleEditProduct = (listingInformation) => {
    setListingData(listingInformation);
  };

  const deleteUnpublishedProduct = async (product) => {
    setLoadingAPI(true);
    await productApi
      .deleteProducts([product.id])
      .then((result) => {
        const response = result.data;
        if (response) {
          openNotification({
            status: true,
            content: t('catalogue.removeUnpublishedProductSuccessfully'),
          });
          getDataSources();
        } else {
          openNotification({
            status: false,
            content: t('catalogue.errorDeletingUnpublishedProduct'),
          });
        }
        setLoadingAPI(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
    setLoadingAPI(false);
  };

  const renderActionsColumn = (value, record) => {
    return (
      <Space>
        {['Amazon', 'Ebay', 'Mercadolibre'].some((m) =>
          record?.marketplace.includes(m)
        ) && (
          <Tooltip placement="topLeft" title={t('home.listing.edit')}>
            <Button
              type="text"
              icon={<EditOutlined style={{ fontSize: '16px' }} />}
              onClick={() => handleEditProduct(record)}
            />
          </Tooltip>
        )}
        {!record?.externalId && (
          <Popconfirm
            className="confirmDelete"
            placement="topRight"
            icon={<ExclamationCircleOutlined />}
            title={t('catalogue.confirmDeleteUnpublishedProduct')}
            onConfirm={() => deleteUnpublishedProduct(record)}
            okText="Sí"
            cancelText="No"
          >
            <Tooltip title={t('catalogue.deleteUnpublishedProduct')}>
              <Button
                icon={<DeleteOutlined />}
                type="text"
                style={{ fill: 'red', fontSize: '16px' }}
                loading={loadingAPI}
              />
            </Tooltip>
          </Popconfirm>
        )}
      </Space>
    );
  };

  const columnsByMkt = [
    {
      title: 'SKU',
      ellipsis: true,
      dataIndex: ['defaultCode'],
      key: 'sku',
      width: '20%',
      render: (value) => (
        <Tooltip title={value}>
          <Text style={{ fontSize: 12 }}>{value}</Text>
        </Tooltip>
      ),
    },
    {
      title: t('catalogue.table.product'),
      dataIndex: ['title'],
      key: 'title',
      align: 'center',
      ellipsis: true,
      width: '45%',
      render: (value, record) => renderProductColumn(value, record),
    },
    {
      title: t('catalogue.table.price'),
      dataIndex: ['price'],
      key: 'price',
      align: 'center',
      width: '10%',
      ellipsis: false,
      render: (value, record) => {
        if (!value || parseFloat(value) === 0) {
          return '-';
        }
        if (
          record.discountPrice &&
          new Date() >= new Date(record.discountPriceFrom) &&
          new Date() <= new Date(record.discountPriceTo)
        ) {
          return (
            <Row>
              <Col span={24}>
                <Text
                  style={{ fontSize: 10, textDecoration: 'line-through' }}
                >{`${`${value} `}${
                  marketplaceCurrency[record.marketplace]
                }`}</Text>
              </Col>
              {record.discountPrice && (
                <Col span={24}>
                  <Text
                    style={{ fontSize: 12 }}
                  >{`${`${record.discountPrice} `}${
                    marketplaceCurrency[record.marketplace]
                  }`}</Text>
                </Col>
              )}
            </Row>
          );
        }
        return (
          <Row>
            <Col span={24}>
              <Text style={{ fontSize: 12 }}>{`${`${value} `}${
                marketplaceCurrency[record.marketplace]
              }`}</Text>
            </Col>
          </Row>
        );
      },
    },
    {
      title: t('catalogue.table.variants'),
      dataIndex: ['children'],
      key: 'variant',
      width: '10%',
      align: 'center',
      ellipsis: false,
      render: (value, record) => {
        if (record.isVariant) {
          return '-';
        }
        return value?.length;
      },
    },
    {
      title: t('catalogue.table.status'),
      dataIndex: ['status'],
      key: 'id',
      width: '10%',
      align: 'center',
      ellipsis: false,
      render: (value, record) => {
        if (!record.isVariant && record.children?.length > 0) {
          return '';
        }
        if (productStatuses.INACTIVE.includes(value)) {
          if (record.inventory?.availableStock === 0) {
            return (
              <div className="pillBox">{t('catalogue.table.outOfStock')}</div>
            );
          }
          return (
            <div className="pillBox withError">
              {t('catalogue.table.inactive')}
            </div>
          );
        }
        if (productStatuses.ACTIVE.includes(value)) {
          return (
            <div className="pillBox withoutError">
              {t('catalogue.table.active')}
            </div>
          );
        }
        return <div className="pillBox">{`${value}`}</div>;
      },
    },
    {
      title: t('catalogue.table.actions'),
      ellipsis: true,
      dataIndex: ['actions'],
      key: 'actions',
      width: '10%',
      render: (value, record) => renderActionsColumn(value, record),
    },
  ];

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        dataSource={filteredProducts}
        loading={loading}
        columns={columnsByMkt}
        pagination={pagination}
        expandable={{
          ...expandable,
        }}
        rowSelection={{
          ...rowSelection,
        }}
        showHeader
        scroll={{ y: '100%' }}
      />
      {listingData && (
        <EditProductsModal
          listingToEdit={listingData}
          setListingToEdit={setListingData}
        />
      )}
    </>
  );
}

MarketplaceTable.propTypes = {
  pagination: PropTypes.objectOf(Object).isRequired,
  expandable: PropTypes.objectOf(Object).isRequired,
  renderProductColumn: PropTypes.func.isRequired,
  filteredProducts: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
  rowSelection: PropTypes.objectOf(Object).isRequired,
  getDataSources: PropTypes.func.isRequired,
};

export default MarketplaceTable;
