import React, { useEffect, useState } from 'react';
import './tablePandL.css';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import FinanceCardInfo from './financeCardInfo';
import FinanceChartLine from './financeChartLine';

function ChartsPandL({ data, financialRatiosLoader }) {
  const { t } = useTranslation();
  const [revenueData, setRevenueData] = useState({});
  const [salesCommissionsData, setSalesCommissionsData] = useState({});
  const [shippingData, setShippingData] = useState({});
  const [marketingData, setMarketingData] = useState({});
  const [otherCostsData, setOtherCostsData] = useState({});
  const [chartData, setChartData] = useState([]);

  const processRevenueData = (newData) => {
    const lastMonth =
      newData[0]?.lines.find((line) => line.name === 'Revenue')?.value || 0;
    const thisMonth =
      newData[1]?.lines.find((line) => line.name === 'Revenue')?.value || 0;

    return {
      lastMonth,
      thisMonth,
    };
  };

  const processSalesCommissionsData = (newData) => {
    const getCommissionValue = (periodIndex) => {
      const operatingExpenses = newData[periodIndex]?.lines.find(
        (line) => line.name === 'Operating Expenses'
      );
      const itemCommission =
        operatingExpenses?.breakdown?.['Item Commission'] || 0;
      const refundCommission =
        operatingExpenses?.breakdown?.['Refund Commission'] || 0;

      return itemCommission + refundCommission;
    };

    return {
      lastMonth: getCommissionValue(0),
      thisMonth: getCommissionValue(1),
    };
  };

  const processShippingData = (newData) => {
    const getShippingValue = (periodIndex) => {
      const operatingExpenses = newData[periodIndex]?.lines.find(
        (line) => line.name === 'Operating Expenses'
      );

      const itemFulfilmentFee =
        operatingExpenses?.breakdown?.['Item Fulfilment Fee'] || 0;
      const refundShippingChargeback =
        operatingExpenses?.breakdown?.['Refund Shipping Chargeback'] || 0;
      const itemShippingChargeback =
        operatingExpenses?.breakdown?.['Item Shipping Chargeback'] || 0;
      const refundFulfilmentFee =
        operatingExpenses?.breakdown?.['Refund Fulfilment Fee'] || 0;

      return (
        itemFulfilmentFee +
        refundShippingChargeback +
        itemShippingChargeback +
        refundFulfilmentFee
      );
    };

    return {
      lastMonth: getShippingValue(0),
      thisMonth: getShippingValue(1),
    };
  };

  const processMarketingData = (newData) => {
    const getMarketingValue = (periodIndex) => {
      return (
        newData[periodIndex]?.lines.find(
          (line) => line.name === 'Marketing Expenses'
        )?.value || 0
      );
    };

    return {
      lastMonth: getMarketingValue(0),
      thisMonth: getMarketingValue(1),
    };
  };

  const processOtherCostsData = (newData) => {
    const getOtherCostsValue = (periodIndex) => {
      return (
        newData[periodIndex]?.lines.find(
          (line) => line.name === 'Non-operating Expenses'
        )?.value || 0
      );
    };

    return {
      lastMonth: getOtherCostsValue(0),
      thisMonth: getOtherCostsValue(1),
    };
  };

  const formatChartData = (newData) => {
    return newData.map((periodData) => ({
      date: periodData.period,
      value:
        periodData.lines.find((line) => line.name === 'Revenue')?.value || 0,
    }));
  };

  useEffect(() => {
    const newData = data.slice(-2);
    const newRevenueData = processRevenueData(newData);
    const newSalesCommissionsData = processSalesCommissionsData(newData);
    const newShippingData = processShippingData(newData);
    const newMarketingData = processMarketingData(newData);
    const newOtherCostsData = processOtherCostsData(newData);
    const newChartData = formatChartData(data);
    setRevenueData(newRevenueData);
    setSalesCommissionsData(newSalesCommissionsData);
    setShippingData(newShippingData);
    setMarketingData(newMarketingData);
    setOtherCostsData(newOtherCostsData);
    setChartData(newChartData);
  }, [data]);

  return (
    <Row gutter={[6, 6]}>
      <Col span={24}>
        <FinanceCardInfo
          title={t('pandl.income')}
          data={revenueData}
          loader={financialRatiosLoader}
        />
      </Col>
      <Col span={6}>
        <FinanceCardInfo
          title={t('pandl.salesCommissions')}
          data={salesCommissionsData}
          loader={financialRatiosLoader}
        />
      </Col>
      <Col span={6}>
        <FinanceCardInfo
          title={t('pandl.shipping')}
          data={shippingData}
          loader={financialRatiosLoader}
        />
      </Col>
      <Col span={6}>
        <FinanceCardInfo
          title={t('pandl.advertising')}
          data={marketingData}
          loader={financialRatiosLoader}
        />
      </Col>
      <Col span={6}>
        <FinanceCardInfo
          title={t('pandl.otherCosts')}
          data={otherCostsData}
          loader={financialRatiosLoader}
        />
      </Col>
      <Col span={24}>
        <FinanceChartLine
          title={t('pandl.incomeDevelopment')}
          subtitle={t('pandl.monthlyVisualization')}
          data={chartData}
          loader={financialRatiosLoader}
        />
      </Col>
    </Row>
  );
}

ChartsPandL.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      period: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      lines: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
          breakdown: PropTypes.objectOf(PropTypes.number),
          percentage: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
  financialRatiosLoader: PropTypes.bool.isRequired,
};

export default ChartsPandL;
