import api from './api';

const prefix = '/paymentPlan';
const paymentPlanAPI = {
  get(id) {
    return api.get(`${prefix}/${id}`);
  },
  find(params) {
    return api.get(`${prefix}/find`, { params });
  },
  createStripeSubscriptionCheckoutSession(payload) {
    return api.post(`stripe/checkout/sessions/subscription`, payload);
  },
  createStripeShipmentCheckoutSession(payload) {
    return api.post(`stripe/checkout/sessions/shipment`, payload);
  },
  importShipmentPayment(payload) {
    return api.post(`stripe/shipment/import`, payload);
  },
};
export default paymentPlanAPI;
