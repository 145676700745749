import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, Spin, Typography, Button, Row, Col } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import SubscribeButton from './SubscribeButton';
import OnSubscriptionButton from './OnSubscriptionButton';

const { Title, Text } = Typography;
function LogisticsPlanCard({
  plan,
  loader,
  loggedIn,
  subscriptionPlanId,
  subscriptionId,
}) {
  const { t } = useTranslation();
  const subscribed = subscriptionPlanId === plan.id ? 'subscribed' : '';

  return (
    <Card className={`plan-card kam ${subscribed}`} bordered={false}>
      {!loader ? (
        <>
          <Row className="header" align="middle">
            <Col span={24}>
              <Title level={4} className="plan-title">
                <center>{plan.name}</center>
              </Title>
            </Col>
            {plan.price > 0 && (
              <Col span={24}>
                <Text level={2} className="plan-price">
                  <center>
                    US
                    {new Intl.NumberFormat('es-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }).format(plan.price)}
                  </center>
                </Text>
                <Text level={2} className="plan-price-detail">
                  <center>{t('plans.priceDetails')}</center>
                </Text>
              </Col>
            )}
          </Row>
          <Divider />
          {Array.from({ length: 7 }, (_, i) => (
            <Text level={2} italic className="plan-benefit">
              <center>
                <Text className="plan-benefit">
                  <Trans
                    i18nKey={`plans.logisticsPlansDetails.${i}`}
                    components={{ bold: <strong /> }}
                  />
                </Text>
              </center>
            </Text>
          ))}
          {plan.price > 0 &&
            (subscriptionPlanId ? (
              <OnSubscriptionButton
                planId={plan.id}
                loggedIn={loggedIn}
                subscriptionPlanId={subscriptionPlanId}
                subscriptionId={subscriptionId}
              />
            ) : (
              <SubscribeButton
                planId={plan.id}
                loggedIn={loggedIn}
                disabled={false}
              />
            ))}
          {!plan.price && (
            <Button className="empty-background-button">
              <div
                id="subscribe-button-link"
                className="empty-background-link"
                style={{ fontStyle: 'italic' }}
              >
                <center>
                  <span style={{ fontStyle: 'italic' }}>
                    {t('plans.contact')}
                  </span>
                </center>
              </div>
            </Button>
          )}
        </>
      ) : (
        <Card>
          <center>
            <Spin size="large" />
          </center>
        </Card>
      )}
    </Card>
  );
}

LogisticsPlanCard.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    currency: PropTypes.string,
    transactionsAmount: PropTypes.number,
    skusAmount: PropTypes.number,
    marketplacesAmount: PropTypes.number,
    type: PropTypes.oneOf(['TECH']),
  }).isRequired,
  loader: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  subscriptionPlanId: PropTypes.number,
  subscriptionId: PropTypes.number,
};

LogisticsPlanCard.defaultProps = {
  subscriptionPlanId: null,
  subscriptionId: null,
};

export default React.memo(LogisticsPlanCard);
