import { FilterOutlined } from '@ant-design/icons';
import { Col, Form, Row, Select, Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import selectors from '../../../redux/analytic/dashboard/selectors';
import useLocalStorage from '../../shared/hooks/useLocalStorage';
import './dashboardFilter.css';

const emptyValues = {
  partner: [],
  marketplace: [],
  periodInterval: 'MONTHLY',
};

function PandLFilter({
  partnerId,
  listMarketplaceAvailableClient,
  ejectFunctions,
}) {
  const { t } = useTranslation();
  const rawFilter = useSelector(selectors.selectRawFilter);
  const currencyState = useSelector(selectors.selectCurrency);
  const [form] = Form.useForm();
  const firstRender = useRef(true);
  const query = useLocalStorage(`query_${partnerId}`, emptyValues);
  const [initialValues] = useState(() => ({
    ...emptyValues,
    ...query,
  }));

  useEffect(() => {
    if (listMarketplaceAvailableClient?.length > 0) {
      const [market] = listMarketplaceAvailableClient;
      const filter = {
        ...rawFilter,
        marketplace: market,
        periodInterval: 'MONTHLY',
      };
      form.setFieldsValue(filter);
      ejectFunctions({
        ...rawFilter,
        marketplace: rawFilter.marketplace[0],
        currency: localStorage.getItem('defaultCurrency'),
        periodInterval: 'MONTHLY',
      });
    }
  }, [listMarketplaceAvailableClient]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form]);

  const onSubmit = (values) => {
    const formValues = values || {};
    formValues.dashboard = values.dashboard;
    ejectFunctions({
      ...values,
      marketplace: values.marketplace,
      currency: localStorage.getItem('defaultCurrency'),
    });
  };

  const onLoadDefaultQuery = () => {
    const defaultFilters = {
      partner: [partnerId],
      marketplace:
        listMarketplaceAvailableClient?.length > 0
          ? listMarketplaceAvailableClient[0]
          : [],
      periodInterval: 'MONTHLY',
    };
    form.setFieldsValue(defaultFilters);
  };

  useEffect(() => {
    onLoadDefaultQuery();
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const filters = form.getFieldValue();
    onSubmit(filters);
  }, [currencyState]);

  return (
    <Form
      name="formulario"
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      className="text-align-left form-style"
    >
      {listMarketplaceAvailableClient?.length > 0 ? (
        <>
          <Row gutter={24} justify="left">
            <Col span={6}>
              <Form.Item
                name="marketplace"
                label={t('dashboard.fields.marketplace')}
              >
                <Select maxTagCount="responsive" defaultValue="">
                  {listMarketplaceAvailableClient?.map((mkp) => (
                    <Select.Option key={mkp} value={mkp}>
                      {t(`dashboard.marketplaces.${mkp.replace(' ', '')}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="periodInterval"
                label={t('dashboard.fields.period')}
              >
                <Select
                  defaultValue={
                    form.getFieldValue('partner') !== '' ? 'MONTHLY' : ''
                  }
                  options={
                    form.getFieldValue('partner') !== ''
                      ? [
                          {
                            value: `MONTHLY`,
                            label: t('dashboard.filter.monthly'),
                          },
                        ]
                      : []
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Button icon={<FilterOutlined />} type="primary" htmlType="submit">
              {t('dashboard.button')}
            </Button>
          </Row>
        </>
      ) : (
        <Row gutter={24} justify="left">
          <Col span={6}>
            <Form.Item
              name="marketplace"
              label={t('dashboard.fields.marketplace')}
            >
              <span>{t('dashboard.messages.noData')}</span>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
}

PandLFilter.propTypes = {
  partnerId: PropTypes.number.isRequired,
  listMarketplaceAvailableClient: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  ejectFunctions: PropTypes.func.isRequired,
};

export default React.memo(PandLFilter);
