import { Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './tablePandL.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TablePandL({ data }) {
  const { t, i18n } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const formatPeriod = (period) => {
    const [year, month] = period.split('-');
    return `${month}-${year}`;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(value);
  };

  useEffect(() => {
    if (!Array.isArray(data) || data.length === 0) {
      setColumns([]);
      setRows([]);
      return;
    }

    const allLines = new Map();
    const allBreakdowns = new Map();

    data.forEach((item) => {
      item.lines.forEach((line) => {
        if (!allLines.has(line.name)) {
          allLines.set(line.name, {
            name: line.name,
            hasPercentage: !!line.percentage,
            hasBreakdown: false,
          });
        }

        const lineData = allLines.get(line.name);
        if (line.breakdown && Object.keys(line.breakdown).length > 0) {
          lineData.hasBreakdown = true;
        }

        if (line.breakdown) {
          if (!allBreakdowns.has(line.name)) {
            allBreakdowns.set(line.name, new Set());
          }
          Object.keys(line.breakdown).forEach((key) => {
            allBreakdowns.get(line.name).add(key);
          });
        }
      });
    });

    const newColumns = [
      {
        title: '',
        dataIndex: 'rowNames',
        key: 'rowNames',
        fixed: 'left',
        width: 300,
      },
      ...data.map((item) => ({
        title: formatPeriod(item.period),
        dataIndex: item.period,
        key: item.period,
        align: 'center',
      })),
    ];

    const newRows = [];

    const headerRow = {
      key: `header`,
      rowNames: '',
    };
    data.forEach((item) => {
      headerRow[item.period] = formatPeriod(item.period);
    });
    newRows.push(headerRow);

    allLines.forEach(({ name, hasPercentage, hasBreakdown }, index) => {
      const mainRow = {
        key: `line-${index}-${i18n.language}`,
        rowNames: (
          <Tooltip title={t(`pandl.financeTable.tooltips.${name}`)}>
            {t(`pandl.financeTable.${name}`)} <InfoCircleOutlined />
          </Tooltip>
        ),
        children: hasBreakdown ? [] : undefined,
      };

      data.forEach((item) => {
        const matchingLine = item.lines.find((l) => l.name === name);
        if (!hasBreakdown && matchingLine) {
          mainRow[item.period] = formatCurrency(matchingLine.value);
        } else {
          mainRow[item.period] = '';
        }
      });

      newRows.push(mainRow);

      if (hasPercentage) {
        const percentageRow = {
          key: `percentage-${index}-${i18n.language}`,
          rowNames: (
            <Tooltip title={t(`pandl.financeTable.tooltips.${name}`)}>
              % {t(`pandl.financeTable.${name}`)} <InfoCircleOutlined />
            </Tooltip>
          ),
        };

        data.forEach((item) => {
          const matchingLine = item.lines.find((l) => l.name === name);
          percentageRow[item.period] = matchingLine?.percentage
            ? `${matchingLine.percentage}`
            : '';
        });

        newRows.push(percentageRow);
      }

      if (allBreakdowns.has(name)) {
        const breakdownKeys = Array.from(allBreakdowns.get(name)).sort();
        breakdownKeys.forEach((key) => {
          const breakdownRow = {
            key: `breakdown-${index}-${key}-${i18n.language}`,
            rowNames: (
              <Tooltip title={t(`pandl.financeTable.tooltips.${key}`)}>
                → {t(`pandl.financeTable.${key}`)} <InfoCircleOutlined />
              </Tooltip>
            ),
          };

          data.forEach((item) => {
            const matchingLine = item.lines.find((l) => l.name === name);
            breakdownRow[item.period] =
              matchingLine && matchingLine.breakdown[key] !== undefined
                ? formatCurrency(matchingLine.breakdown[key])
                : '';
          });

          mainRow.children?.push(breakdownRow);
        });
      }

      if (allBreakdowns.has(name)) {
        const totalRow = {
          key: `total-${index}-${i18n.language}`,
          rowNames: (
            <Tooltip title={t(`pandl.financeTable.tooltips.${name}`)}>
              Total {t(`pandl.financeTable.${name}`)} <InfoCircleOutlined />
            </Tooltip>
          ),
        };

        data.forEach((item) => {
          const matchingLine = item.lines.find((l) => l.name === name);
          totalRow[item.period] = matchingLine
            ? formatCurrency(matchingLine.value)
            : '';
        });

        newRows.push(totalRow);
      }
    });

    setColumns(newColumns);
    setRows(newRows);
  }, [data, i18n.language]);

  return (
    <div className="table-pandl-container">
      <Table
        columns={columns}
        dataSource={rows}
        scroll={{ x: 'max-content' }}
        pagination={false}
        bordered
        showHeader={false}
        size="medium"
        rowClassName={(record) => {
          if (record.key.startsWith('total-')) return 'total-row';
          if (record.key.startsWith('line-')) return 'main-row';
          if (record.key.startsWith('percentage-')) return 'percentage-row';
          return '';
        }}
      />
    </div>
  );
}

TablePandL.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      period: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      lines: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
          breakdown: PropTypes.objectOf(PropTypes.number),
          percentage: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default TablePandL;
