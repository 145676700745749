import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Typography, Table, Tooltip, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import IndexGraphics from './components/IndexGraphics';
import actionsAnalitycs from '../../redux/analytic/dashboard/actions';
import { dateTypes, sellerMarketplaces } from '../../utils/const';
import dashboardApi from '../../api/dashboard';
import marketingApi from '../../api/marketing';
import openNotification from '../../components/Toastr';
import { getErrorMessage } from '../../api/api';
import DashboardFilter from '../home/components/DashboardFilter';
import PerformanceTables from './components/PerformanceTables';
import { convertToISOString, thousandsSeparator } from '../../utils/functions';
import { updateOnboarding } from '../../redux/onboarding/action';
import accessKeysApi from '../../api/aws-access-keys';
import { stage5Adv } from '../../stepsOnboarding';
import './marketing.css';

const { Text, Title, Link } = Typography;

function Marketing() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const session = useSelector((store) => store.Session.session);
  const history = useHistory();
  const onboardingStatus = useSelector((store) => store.Onboarding);
  const company = useSelector((store) => store.Partner.partner);

  const [listMarketplaceAvailableClient, setListMarketplaceAvailableClient] =
    useState([]);
  const [listSkus, setListSkus] = useState([]);

  const [dataTotalSales, setDataTotalSales] = useState(0);
  const [dataSalesPerAdvertising, setDataSalesPerAdvertising] = useState(0);
  const [dataOrganicSales, setDataOrganicSales] = useState(0);
  const [dataInvestment, setDataInvestment] = useState(0);
  const [dataAds, setDataAds] = useState(0);
  const [dataTacos, setDataTacos] = useState(0);
  const [dataSoldUnits, setDataSoldUnits] = useState(0);
  const [dataUnitsPerAdvertising, setDataUnitsPerAdvertising] = useState(0);
  const [dataOrganicUnits, setDataOrganicUnits] = useState(0);
  const [dataDualAxeGraph, setDataDualAxeGraph] = useState([]);
  const [dataPerformanceBySkuTable, setDataPerformanceBySkuTable] = useState(
    []
  );
  const [dataPerformanceByCampaignTable, setDataPerformanceByCampaignTable] =
    useState([]);
  const [dataImpressions, setDataImpressions] = useState(0);
  const [dataClicks, setDataClicks] = useState(0);
  const [dataCtr, setDataCtr] = useState(0);
  const [dataCvr, setDataCvr] = useState(0);
  const [dataCpc, setDataCpc] = useState(0);
  const [dataAcos, setDataAcos] = useState(0);

  const [hasAdvertising, setHasAdvertising] = useState(false);
  const [dataTotalSalesLoading, setDataTotalSalesLoading] = useState(false);
  const [dataSalesPerAdvertisingLoading, setDataSalesPerAdvertisingLoading] =
    useState(false);
  const [dataOrganicSalesLoading, setDataOrganicSalesLoading] = useState(false);
  const [dataInvestmentLoading, setDataInvestmentLoading] = useState(false);
  const [dataAdsLoading, setDataAdsLoading] = useState(false);
  const [dataTacosLoading, setDataTacosLoading] = useState(false);
  const [dataSoldUnitsLoading, setDataSoldUnitsLoading] = useState(false);
  const [dataUnitsPerAdvertisingLoading, setDataUnitsPerAdvertisingLoading] =
    useState(false);
  const [dataOrganicUnitsLoading, setDataOrganicUnitsLoading] = useState(false);
  const [dataDualAxeGraphLoading, setDataDualAxeGraphLoading] = useState(false);
  const [
    dataPerformanceBySkuTableLoading,
    setDataPerformanceBySkuTableLoading,
  ] = useState(false);
  const [
    dataPerformanceByCampaignTableLoading,
    setDataPerformanceByCampaignTableLoading,
  ] = useState(false);
  const [dataImpressionsLoading, setDataImpressionsLoading] = useState(false);
  const [dataClicksLoading, setDataClicksLoading] = useState(false);
  const [dataCtrLoading, setDataCtrLoading] = useState(false);
  const [dataCvrLoading, setDataCvrLoading] = useState(false);
  const [dataCpcLoading, setDataCpcLoading] = useState(false);
  const [dataAcosLoading, setDataAcosLoading] = useState(false);

  const handleOnboardingMarketingClick = () => {
    dispatch(updateOnboarding('Marketing', 0));
  };

  let partnerId = 1; // admin
  const { isAdmin } = session.userInfo;
  const isDemo = company?.isDemo;

  if (!isAdmin) {
    [partnerId] = session.userInfo.commercial_partner_id;
  }

  const columnsSKU = [
    {
      title: t('marketing.performanceBySkuTable.headers.product'),
      dataIndex: 'product',
      key: 'product',
      align: 'center',
      ellipsis: true,
      width: 200,
      render: (value, record, index) => (
        <div className="box">
          <Tooltip placement="top" title={`${index + 1}. ${record?.product}`}>
            <Text ellipsis style={{ fontWeight: 400 }}>{`${index + 1}. ${
              record?.product
            }`}</Text>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.investment'),
      dataIndex: 'totalInvestment',
      key: 'totalInvestment',
      align: 'center',
      render: (value, record) => (
        <div style={{ background: '#C4C4C433' }}>
          <Text style={{ fontWeight: 400 }}>
            $
            {Number(record?.totalInvestment) > 0
              ? thousandsSeparator(Math.round(Number(record?.totalInvestment)))
              : 0}
          </Text>
        </div>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.totalSales'),
      dataIndex: 'totalSales',
      key: 'totalSales',
      align: 'center',
      render: (value, record) => (
        <div style={{ background: '#00E5A633' }}>
          <Text style={{ fontWeight: 400 }}>
            $
            {Number(record?.totalSales) > 0
              ? thousandsSeparator(Math.round(Number(record?.totalSales)))
              : 0}
          </Text>
        </div>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.salesPerCampaign'),
      dataIndex: 'salesPerProduct',
      key: 'salesPerProduct',
      align: 'center',
      render: (value, record) => (
        <div style={{ background: '#010C331A' }}>
          <Text style={{ fontWeight: 400 }}>
            $
            {Number(record?.salesPerProduct) > 0
              ? thousandsSeparator(Math.round(Number(record?.salesPerProduct)))
              : 0}
          </Text>
        </div>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.totalUnits'),
      dataIndex: 'totalUnits',
      key: 'totalUnits',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.totalUnits) > 0
            ? thousandsSeparator(Number(record?.totalUnits))
            : 0}
        </Text>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.unitsPerCampaign'),
      dataIndex: 'unitsPerProduct',
      key: 'unitsPerProduct',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.unitsPerProduct) > 0
            ? thousandsSeparator(Number(record?.unitsPerProduct))
            : 0}
        </Text>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.adsSalesPercentage'),
      dataIndex: 'adsSalesPercentage',
      key: 'adsSalesPercentage',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.salesPerProduct) > 0 && Number(record?.totalSales) > 0
            ? thousandsSeparator(
                Math.round(
                  (Number(record?.salesPerProduct) /
                    Number(record?.totalSales)) *
                    100
                )
              )
            : 0}
          %
        </Text>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.tacos'),
      dataIndex: 'tacos',
      key: 'tacos',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.totalInvestment) > 0 && Number(record?.totalSales) > 0
            ? thousandsSeparator(
                Math.round(
                  (Number(record?.totalInvestment) /
                    Number(record?.totalSales)) *
                    100
                )
              )
            : 0}
          %
        </Text>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.impressions'),
      dataIndex: 'totalImpressions',
      key: 'totalImpressions',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.totalImpressions) > 0
            ? thousandsSeparator(Number(record?.totalImpressions))
            : 0}
        </Text>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.clicks'),
      dataIndex: 'totalClicks',
      key: 'totalClicks',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.totalClicks) > 0
            ? thousandsSeparator(Number(record?.totalClicks))
            : 0}
        </Text>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.ctr'),
      dataIndex: 'ctr',
      key: 'ctr',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.ctr) > 0
            ? Math.round((Number(record?.ctr) + Number.EPSILON) * 100) / 100
            : 0}
          %
        </Text>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.cvr'),
      dataIndex: 'cvr',
      key: 'cvr',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.cvr) > 0
            ? (Number(record?.cvr) + Number.EPSILON).toFixed(1)
            : 0}
          %
        </Text>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.cpc'),
      dataIndex: 'cpc',
      key: 'cpc',
      align: 'center',
      render: (value, record) => (
        <Text>
          ${Number(record?.cpc) > 0 ? Number(record?.cpc.toFixed(2)) : 0}
        </Text>
      ),
    },
    {
      title: t('marketing.performanceBySkuTable.headers.acos'),
      dataIndex: 'acos',
      key: 'acos',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.totalInvestment) > 0 &&
          Number(record?.salesPerProduct) > 0
            ? thousandsSeparator(
                Math.round(
                  (Number(record?.totalInvestment) /
                    Number(record?.salesPerProduct)) *
                    100
                )
              )
            : 0}
          %
        </Text>
      ),
    },
  ];

  const summarySKU = (pageData) => {
    let investmentTotal = 0;
    let salesTotal = 0;
    let advertisingTotalSales = 0;
    let unitsTotal = 0;
    let advertisingTotalUnits = 0;
    let impressionsTotal = 0;
    let clicksTotal = 0;

    pageData.forEach(
      ({
        totalInvestment,
        totalSales,
        salesPerProduct,
        totalUnits,
        unitsPerProduct,
        totalImpressions,
        totalClicks,
      }) => {
        investmentTotal += Number(totalInvestment);
        salesTotal += Number(totalSales);
        advertisingTotalSales += Number(salesPerProduct);
        unitsTotal += Number(totalUnits);
        advertisingTotalUnits += Number(unitsPerProduct);
        impressionsTotal += Number(totalImpressions);
        clicksTotal += Number(totalClicks);
      }
    );

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>
          <Text style={{ fontWeight: 400 }}>{t('common.total')}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} className="investmentTotal">
          <div style={{ background: '#C4C4C433' }}>
            <Text style={{ fontWeight: 400 }}>
              ${' '}
              {Number(investmentTotal) > 0
                ? thousandsSeparator(Math.round(Number(investmentTotal)))
                : 0}
            </Text>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} className="salesTotal">
          <div style={{ background: '#00E5A633' }}>
            <Text style={{ fontWeight: 400 }}>
              ${' '}
              {Number(salesTotal) > 0
                ? thousandsSeparator(Math.round(Number(salesTotal)))
                : 0}
            </Text>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3} className="advertisingTotalSales">
          <div style={{ background: '#010C331A' }}>
            <Text style={{ fontWeight: 400 }}>
              ${' '}
              {Number(advertisingTotalSales) > 0
                ? thousandsSeparator(Math.round(Number(advertisingTotalSales)))
                : 0}
            </Text>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4} className="unitsTotal">
          <Text>
            {Number(unitsTotal) > 0
              ? thousandsSeparator(Number(unitsTotal))
              : 0}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5} className="advertisingTotalUnits">
          <Text>
            {Number(advertisingTotalUnits) > 0
              ? thousandsSeparator(Number(advertisingTotalUnits))
              : 0}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} className="adsTotal">
          <Text>
            {Number(advertisingTotalSales) > 0 && Number(salesTotal)
              ? Math.round((Number(advertisingTotalSales) / salesTotal) * 100)
              : 0}
            %
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7} className="tacosTotal">
          <Text>
            {Number(investmentTotal) > 0 && Number(salesTotal)
              ? Math.round((Number(investmentTotal) / salesTotal) * 100)
              : 0}
            %
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8} className="impressionsTotal">
          <Text>
            {Number(impressionsTotal) > 0
              ? thousandsSeparator(Number(impressionsTotal))
              : 0}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={9} className="clicksTotal">
          <Text>
            {Number(clicksTotal) > 0
              ? thousandsSeparator(Number(clicksTotal))
              : 0}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={10} className="ctrTotal">
          <Text>
            {Number(impressionsTotal) > 0 && Number(clicksTotal)
              ? (
                  (Number(clicksTotal) / Number(impressionsTotal)) *
                  100
                ).toFixed(2)
              : 0}
            %
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={11} className="cvrTotal">
          <Text>
            {Number(clicksTotal) > 0 && Number(advertisingTotalUnits)
              ? (
                  (Number(advertisingTotalUnits) / Number(clicksTotal)) *
                  100
                ).toFixed(2)
              : 0}
            %
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={12} className="cpcTotal">
          <Text>
            $
            {Number(clicksTotal) > 0 && Number(investmentTotal)
              ? (Number(investmentTotal) / Number(clicksTotal)).toFixed(2)
              : 0}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={13} className="acosTotal">
          <Text>
            {Number(investmentTotal) > 0 && Number(advertisingTotalSales)
              ? Math.round(
                  (Number(investmentTotal) / advertisingTotalSales) * 100
                )
              : 0}
            %
          </Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const columnsCampaign = [
    {
      title: (
        <Tooltip
          placement="top"
          title={
            <div className="marketing-tooltip">
              <Text className="marketing-tooltip-text">
                {`${t(
                  'marketing.performanceByCampaignTable.campaignNameTooltip.0'
                )} \n${t(
                  'marketing.performanceByCampaignTable.campaignNameTooltip.1'
                )}\n`}
              </Text>
              <Link
                href="https://ayuda.lapmarketplace.com/hc/es-cl/articles/13882328355355"
                target="_blank"
              >
                {t('common.wantKnowMore')}
              </Link>
            </div>
          }
        >
          <Text>
            {t('marketing.performanceByCampaignTable.headers.campaignName')}
          </Text>
          <InfoCircleOutlined className="marketing-tooltip-icon" />
        </Tooltip>
      ),
      dataIndex: 'campaignName',
      key: 'campaignName',
      align: 'center',
      ellipsis: true,
      width: 400,
      render: (value, record, index) => (
        <Row className="box">
          <Tooltip
            placement="top"
            title={`${index + 1}. ${record?.campaignName}`}
          >
            <Text style={{ fontWeight: 400 }}>{`${index + 1}. ${
              record?.campaignName
            }`}</Text>
          </Tooltip>
        </Row>
      ),
    },
    {
      title: t('marketing.performanceByCampaignTable.headers.investment'),
      dataIndex: 'totalInvestment',
      key: 'totalInvestment',
      align: 'center',
      render: (value, record) => (
        <div style={{ background: '#C4C4C433' }}>
          <Text style={{ fontWeight: 400 }}>
            ${thousandsSeparator(Math.round(Number(record?.totalInvestment)))}
          </Text>
        </div>
      ),
    },
    {
      title: t('marketing.performanceByCampaignTable.headers.salesPerCampaign'),
      dataIndex: 'salesPerCampaign',
      key: 'salesPerCampaign',
      align: 'center',
      render: (value, record) => (
        <div style={{ background: '#00E5A633' }}>
          <Text style={{ fontWeight: 400 }}>
            ${thousandsSeparator(Math.round(Number(record?.salesPerCampaign)))}
          </Text>
        </div>
      ),
    },
    {
      title: t('marketing.performanceByCampaignTable.headers.unitsPerCampaign'),
      dataIndex: 'unitsPerCampaign',
      key: 'unitsPerCampaign',
      align: 'center',
      render: (value, record) => (
        <div style={{ background: '#010C331A' }}>
          <Text style={{ fontWeight: 400 }}>
            {thousandsSeparator(Math.round(Number(record?.unitsPerCampaign)))}
          </Text>
        </div>
      ),
    },
    {
      title: t('marketing.performanceByCampaignTable.headers.impressions'),
      dataIndex: 'totalImpressions',
      key: 'totalImpressions',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.totalImpressions) > 0
            ? thousandsSeparator(Math.round(Number(record?.totalImpressions)))
            : 0}
        </Text>
      ),
    },
    {
      title: t('marketing.performanceByCampaignTable.headers.clicks'),
      dataIndex: 'totalClicks',
      key: 'totalClicks',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.totalClicks) > 0
            ? thousandsSeparator(Math.round(Number(record?.totalClicks)))
            : 0}
        </Text>
      ),
    },
    {
      title: t('marketing.performanceByCampaignTable.headers.ctr'),
      dataIndex: 'ctr',
      key: 'ctr',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.ctr) > 0
            ? Math.round((Number(record?.ctr) + Number.EPSILON) * 100) / 100
            : 0}
          %
        </Text>
      ),
    },
    {
      title: t('marketing.performanceByCampaignTable.headers.cvr'),
      dataIndex: 'cvr',
      key: 'cvr',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.cvr) > 0
            ? (Number(record?.cvr) + Number.EPSILON).toFixed(1)
            : 0}
          %
        </Text>
      ),
    },
    {
      title: t('marketing.performanceByCampaignTable.headers.cpc'),
      dataIndex: 'cpc',
      key: 'cpc',
      align: 'center',
      render: (value, record) => (
        <Text>
          ${Number(record?.cpc) > 0 ? Number(record?.cpc.toFixed(2)) : 0}
        </Text>
      ),
    },
    {
      title: t('marketing.performanceByCampaignTable.headers.acos'),
      dataIndex: 'acos',
      key: 'acos',
      align: 'center',
      render: (value, record) => (
        <Text>
          {Number(record?.totalInvestment) > 0 &&
          Number(record?.salesPerCampaign) > 0
            ? thousandsSeparator(
                Math.round(
                  (Number(record?.totalInvestment) /
                    Number(record?.salesPerCampaign)) *
                    100
                )
              )
            : 0}
          %
        </Text>
      ),
    },
  ];

  const summaryCampaign = (pageData) => {
    let investmentTotal = 0;
    let advertisingTotalSales = 0;
    let advertisingTotalUnits = 0;
    let impressionsTotal = 0;
    let clicksTotal = 0;

    pageData.forEach(
      ({
        totalInvestment,
        salesPerCampaign,
        unitsPerCampaign,
        totalImpressions,
        totalClicks,
      }) => {
        investmentTotal += Number(totalInvestment);
        advertisingTotalSales += Number(salesPerCampaign);
        advertisingTotalUnits += Number(unitsPerCampaign);
        impressionsTotal += Number(totalImpressions);
        clicksTotal += Number(totalClicks);
      }
    );

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>
          <Text style={{ fontWeight: 400 }}>{t('common.total')}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} className="investmentTotal">
          <div style={{ background: '#C4C4C433' }}>
            <Text style={{ fontWeight: 400 }}>
              $
              {Number(investmentTotal) > 0
                ? thousandsSeparator(Math.round(Number(investmentTotal)))
                : 0}
            </Text>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} className="advertisingTotalSales">
          <div style={{ background: '#00E5A633' }}>
            <Text style={{ fontWeight: 400 }}>
              ${thousandsSeparator(Math.round(Number(advertisingTotalSales)))}
            </Text>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3} className="advertisingTotalUnits">
          <div style={{ background: '#010C331A' }}>
            <Text style={{ fontWeight: 400 }}>
              {thousandsSeparator(Math.round(Number(advertisingTotalUnits)))}
            </Text>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4} className="impressionsTotal">
          <Text>
            {Number(impressionsTotal) > 0
              ? thousandsSeparator(Math.round(Number(impressionsTotal)))
              : 0}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5} className="clicksTotal">
          <Text>
            {Number(clicksTotal) > 0
              ? thousandsSeparator(Math.round(Number(clicksTotal)))
              : 0}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} className="ctrTotal">
          <Text>
            {Number(impressionsTotal) > 0 && Number(clicksTotal)
              ? (
                  (Number(clicksTotal) / Number(impressionsTotal)) *
                  100
                ).toFixed(2)
              : 0}
            %
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7} className="cvrTotal">
          <Text>
            {Number(clicksTotal) > 0 && Number(advertisingTotalUnits)
              ? (
                  (Number(advertisingTotalUnits) / Number(clicksTotal)) *
                  100
                ).toFixed(2)
              : 0}
            %
          </Text>{' '}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8} className="cpcTotal">
          $
          {Number(clicksTotal) > 0 && Number(investmentTotal)
            ? (Number(investmentTotal) / Number(clicksTotal)).toFixed(2)
            : 0}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={9} className="acosTotal">
          <Text>
            {Number(investmentTotal) > 0 && Number(advertisingTotalSales)
              ? Math.round(
                  (Number(investmentTotal) / advertisingTotalSales) * 100
                )
              : 0}
            %
          </Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const getTotalSales = async (formFilter) => {
    setDataTotalSalesLoading(true);
    await marketingApi
      .getTotalSales(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataTotalSales(response?.data);
        }
        setDataTotalSalesLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getSalesPerAdvertising = async (formFilter) => {
    setDataSalesPerAdvertisingLoading(true);
    await marketingApi
      .getSalesPerAdvertising(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataSalesPerAdvertising(response?.data);
        }
        setDataSalesPerAdvertisingLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getOrganicSales = async (formFilter) => {
    setDataOrganicSalesLoading(true);
    await marketingApi
      .getOrganicSales(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataOrganicSales(response?.data);
        }
        setDataOrganicSalesLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getInvestment = async (formFilter) => {
    setDataInvestmentLoading(true);
    await marketingApi
      .getInvestment(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataInvestment(response?.data);
        }
        setDataInvestmentLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getAds = async (formFilter) => {
    setDataAdsLoading(true);
    await marketingApi
      .getAds(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataAds(response?.data);
        }
        setDataAdsLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getTacos = async (formFilter) => {
    setDataTacosLoading(true);
    await marketingApi
      .getTacos(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataTacos(response?.data);
        }
        setDataTacosLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getSoldUnits = async (formFilter) => {
    setDataSoldUnitsLoading(true);
    await marketingApi
      .getSoldUnits(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataSoldUnits(response?.data);
        }
        setDataSoldUnitsLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getUnitsPerAdvertising = async (formFilter) => {
    setDataUnitsPerAdvertisingLoading(true);
    await marketingApi
      .getUnitsPerAdvertising(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataUnitsPerAdvertising(response?.data);
        }
        setDataUnitsPerAdvertisingLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getOrganicUnits = async (formFilter) => {
    setDataOrganicUnitsLoading(true);
    await marketingApi
      .getOrganicUnits(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataOrganicUnits(response?.data);
        }
        setDataOrganicUnitsLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getDataDualAxeGraph = async (formFilter) => {
    setDataDualAxeGraphLoading(true);
    await marketingApi
      .getDataDualAxeGraph(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataDualAxeGraph(response?.data);
        }
        setDataDualAxeGraphLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getDataPerformanceBySkuTable = async (formFilter) => {
    setDataPerformanceBySkuTableLoading(true);
    await marketingApi
      .getDataPerformanceBySkuTable(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataPerformanceBySkuTable(response?.data);
        }
        setDataPerformanceBySkuTableLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getDataPerformanceByCampaignTable = async (formFilter) => {
    setDataPerformanceByCampaignTableLoading(true);
    await marketingApi
      .getDataPerformanceByCampaignTable(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataPerformanceByCampaignTable(response?.data);
        }
        setDataPerformanceByCampaignTableLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getImpressions = async (formFilter) => {
    setDataImpressionsLoading(true);
    await marketingApi
      .getImpressions(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataImpressions(response?.data);
        }
        setDataImpressionsLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getClicks = async (formFilter) => {
    setDataClicksLoading(true);
    await marketingApi
      .getClicks(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataClicks(response?.data);
        }
        setDataClicksLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getCtr = async (formFilter) => {
    setDataCtrLoading(true);
    await marketingApi
      .getCtr(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataCtr(response?.data);
        }
        setDataCtrLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getCvr = async (formFilter) => {
    setDataCvrLoading(true);
    await marketingApi
      .getCvr(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataCvr(response?.data);
        }
        setDataCvrLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getCpc = async (formFilter) => {
    setDataCpcLoading(true);
    await marketingApi
      .getCpc(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataCpc(response?.data);
        }
        setDataCpcLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getAcos = async (formFilter) => {
    setDataAcosLoading(true);
    await marketingApi
      .getAcos(formFilter)
      .then((response) => {
        if (response?.data) {
          setDataAcos(response?.data);
        }
        setDataAcosLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getMarketplaces = async (partner) => {
    let ptrs = [];
    if (!isAdmin) {
      ptrs = [partnerId];
    } else {
      ptrs = [partner];
    }
    const defaultFilters = {
      dashboard: 'marketing',
      partner: ptrs,
      sku: [],
      marketplace: Object.values(sellerMarketplaces),
      dateType: dateTypes.PURCHASE_DATE,
      dateRangeOne: convertToISOString([moment().add(-1, 'M'), moment()]),
    };

    let marketAvailable = [];
    if (isDemo) {
      marketAvailable = ['Amazon', 'Amazon Canada', 'Amazon Mexico'];
    } else {
      await dashboardApi
        .getMarketplacesPerClient(defaultFilters)
        .then((response) => {
          if (response?.data?.length > 0) {
            response?.data.forEach((e) => {
              if (e.name.includes('Amazon')) {
                marketAvailable.push(e.name);
              }
            });
          }
        })
        .catch((error) => {
          openNotification({ status: false, content: getErrorMessage(error) });
        });
    }
    dispatch(
      actionsAnalitycs.setFilter({
        ...defaultFilters,
        dashboard: 'marketing',
        dateRangeOne: [moment().startOf('month'), moment()],
        marketplace: marketAvailable,
      })
    );
    setListMarketplaceAvailableClient(marketAvailable);
  };

  const ejectFunctions = (params) => {
    const values = {
      marketplace: params.marketplace,
      partner: params.partner,
      sku: params.sku,
      period: params.period || 'currentMonth',
      currency: localStorage.getItem('defaultCurrency'),
      dateType: params.dateType,
      dateRangeOne: params.dateRangeOne,
      category: '',
      timezone: params.timezone,
    };
    setDataTotalSales([]);
    setDataSalesPerAdvertising([]);
    setDataOrganicSales([]);
    setDataInvestment([]);
    setDataAds([]);
    setDataTacos([]);
    setDataSoldUnits([]);
    setDataUnitsPerAdvertising([]);
    setDataOrganicUnits([]);
    setDataDualAxeGraph([]);
    setDataPerformanceBySkuTable([]);
    setDataPerformanceByCampaignTable([]);
    setDataImpressions([]);
    setDataClicks([]);
    setDataCtr([]);
    setDataCvr([]);
    setDataCpc([]);
    setDataAcos([]);
    if (values) {
      dispatch(actionsAnalitycs.setFilter(values));
      getTotalSales(values);
      getSalesPerAdvertising(values);
      getOrganicSales(values);
      getInvestment(values);
      getAds(values);
      getTacos(values);
      getSoldUnits(values);
      getUnitsPerAdvertising(values);
      getOrganicUnits(values);
      getDataDualAxeGraph(values);
      getDataPerformanceBySkuTable(values);
      getDataPerformanceByCampaignTable(values);
      getImpressions(values);
      getClicks(values);
      getCtr(values);
      getCvr(values);
      getCpc(values);
      getAcos(values);
    }
  };

  const changeCurrency = (currency) => {
    localStorage.setItem('defaultCurrency', currency);
    dispatch(actionsAnalitycs.defaultCurrency(currency));
  };

  useEffect(() => {
    const existDefaultCurrency = localStorage.getItem('defaultCurrency');
    if (!existDefaultCurrency) {
      changeCurrency('USD');
    } else {
      changeCurrency(existDefaultCurrency);
    }
  }, []);

  const getSkusperMarketplace = async (param, rangeOne, partner, period) => {
    let ptrs = [];
    if (!isAdmin) {
      ptrs = [partnerId];
    } else {
      ptrs = partner;
    }
    const defaultFilters = {
      dashboard: 'marketing',
      partner: ptrs,
      marketplace: param || 'Amazon',
      category: ['sales', 'stock'],
      compare: false,
      dateType: dateTypes.PURCHASE_DATE,
      dateRangeOne:
        rangeOne?.length > 0
          ? convertToISOString(rangeOne)
          : convertToISOString([moment().add(-1, 'M'), moment()]),
      dateRangeTwo: [],
      fulfillment: '',
      period: period || 'currentMonth',
    };

    await dashboardApi
      .getSkusPerMarketplace(defaultFilters)
      .then((response) => {
        const skuAvailable = [];
        if (response?.data && response?.data.length > 0) {
          response?.data.forEach((e) => {
            skuAvailable.push({
              value: e.sku,
              label: e.sku,
            });
          });
          skuAvailable.splice(0, 0, {
            value: '',
            label: 'All',
          });
          dispatch(
            actionsAnalitycs.setFilter({
              ...defaultFilters,
              listSkus: skuAvailable,
            })
          );
          setListSkus(skuAvailable);
        } else {
          setListSkus([]);
        }
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const loadMyAdsAccount = async () => {
    if (isDemo) {
      setHasAdvertising(true);
      getMarketplaces();
      getSkusperMarketplace();
    } else {
      const adsAccount = await accessKeysApi.getUserCredentials(
        session?.userInfo?.commercial_partner_id[0],
        'Amazon',
        'Advertising'
      );
      if (adsAccount?.data?.type === 'Advertising') {
        setHasAdvertising(true);
        if (!isAdmin) {
          getMarketplaces();
          getSkusperMarketplace();
        }
      } else {
        setHasAdvertising(false);
      }
    }
  };

  useEffect(() => {
    loadMyAdsAccount();
  }, []);

  const handleSkipTourCallback = (data) => {
    const { action, lifecycle } = data;
    if (
      (action === 'close' && lifecycle === 'complete') ||
      action === 'reset'
    ) {
      dispatch(updateOnboarding(null, null));
    }
  };

  return (
    <>
      <div
        className="content-div"
        style={{
          backgroundColor: '#f9f9f9',
          paddingBottom: 5,
          paddingTop: 70,
          paddingLeft: 20,
          paddingRight: 15,
        }}
      >
        <Row className="home-listing-main-row listings-main">
          <Col span={24} xs={24} sm={24} md={24}>
            <Card className="ventas-home-listing-card">
              <Row>
                <Col xs={24} span={24} className="intro-filters">
                  {hasAdvertising ? (
                    <DashboardFilter
                      dashboard="marketing"
                      getMarketplaces={getMarketplaces}
                      ejectFunctions={ejectFunctions}
                      listMarketplaceAvailableClient={
                        listMarketplaceAvailableClient
                      }
                      listSkus={listSkus}
                      isAdmin={isAdmin}
                      multiMarket={false}
                      sku
                      getSkusperMarketplace={getSkusperMarketplace}
                      timezoneFilter
                      partnerId={partnerId}
                    />
                  ) : (
                    <>
                      <h2>{t('marketing.notConnected.title')}</h2>
                      <span style={{ display: 'flex', width: '500px' }}>
                        {t('marketing.notConnected.description')}
                      </span>
                      <br />
                      <Button
                        className="btn-conectar"
                        onClick={
                          handleOnboardingMarketingClick ||
                          (() => history.push('/my-account?step=2'))
                        }
                      >
                        {t('marketing.notConnected.button')}
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="step5Dashboards">
        <div
          className="content-div"
          style={{
            backgroundColor: '#f9f9f9',
            paddingBottom: 5,
            paddingTop: 5,
            paddingLeft: 20,
            paddingRight: 15,
          }}
        >
          <IndexGraphics
            dataInvestmentLoading={dataInvestmentLoading}
            dataInvestment={dataInvestment}
            dataSoldUnits={dataSoldUnits}
            dataUnitsPerAdvertising={dataUnitsPerAdvertising}
            dataSoldUnitsLoading={dataSoldUnitsLoading}
            dataUnitsPerAdvertisingLoading={dataUnitsPerAdvertisingLoading}
            dataOrganicUnits={dataOrganicUnits}
            dataOrganicUnitsLoading={dataOrganicUnitsLoading}
            dataClicks={dataClicks}
            dataClicksLoading={dataClicksLoading}
            dataImpressions={dataImpressions}
            dataImpressionsLoading={dataImpressionsLoading}
            dataCtr={dataCtr}
            dataCvr={dataCvr}
            dataCpc={dataCpc}
            dataCtrLoading={dataCtrLoading}
            dataCvrLoading={dataCvrLoading}
            dataCpcLoading={dataCpcLoading}
            dataSalesPerAdvertisingLoading={dataSalesPerAdvertisingLoading}
            dataSalesPerAdvertising={dataSalesPerAdvertising}
            dataTotalSalesLoading={dataTotalSalesLoading}
            dataTotalSales={dataTotalSales}
            dataOrganicSalesLoading={dataOrganicSalesLoading}
            dataOrganicSales={dataOrganicSales}
            dataAdsLoading={dataAdsLoading}
            dataAds={dataAds}
            dataTacosLoading={dataTacosLoading}
            dataTacos={dataTacos}
            dataAcosLoading={dataAcosLoading}
            dataAcos={dataAcos}
            dataDualAxesChart={dataDualAxeGraph}
            dataDualAxeGraphLoading={dataDualAxeGraphLoading}
            hasAdvertising={hasAdvertising}
          />
        </div>
        <div
          className="content-div"
          style={{
            backgroundColor: '#f9f9f9',
            paddingBottom: 5,
            paddingTop: 5,
            paddingLeft: 20,
            paddingRight: 15,
          }}
        >
          <Row>
            <Col span={24}>
              <div className="marketing-table">
                <Card
                  bordered={false}
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    paddingBottom: 15,
                    paddingRight: 5,
                  }}
                >
                  <div className="chartLineStyleContentSales">
                    <Title
                      level={2}
                      style={{ marginBottom: 24, marginTop: 10 }}
                    >
                      {t('marketing.performanceBySkuTitle')}
                    </Title>
                  </div>
                  <PerformanceTables
                    dataSource={dataPerformanceBySkuTable}
                    loading={dataPerformanceBySkuTableLoading}
                    columns={columnsSKU}
                    summary={summarySKU}
                  />
                </Card>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="content-div"
          style={{
            backgroundColor: '#f9f9f9',
            paddingBottom: 5,
            paddingTop: 5,
            paddingLeft: 20,
            paddingRight: 15,
          }}
        >
          <Row>
            <Col span={24}>
              <div className="marketing-table">
                <Card
                  bordered={false}
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    paddingBottom: 15,
                    paddingRight: 5,
                  }}
                >
                  <div className="chartLineStyleContentSales">
                    <Title
                      level={2}
                      style={{ marginBottom: 24, marginTop: 10 }}
                    >
                      {t('marketing.performanceByCampaignTitle')}
                    </Title>
                  </div>
                  <PerformanceTables
                    dataSource={dataPerformanceByCampaignTable}
                    loading={dataPerformanceByCampaignTableLoading}
                    columns={columnsCampaign}
                    summary={summaryCampaign}
                  />
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {onboardingStatus.state === 'Dashboard' &&
        onboardingStatus.step === 0 && (
          <Joyride
            steps={stage5Adv}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            disableScrolling
            spotlightClicks
            continuous
            hideBackButton
          />
        )}
    </>
  );
}

export default Marketing;
