import { Col, Layout, Row, Steps } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PickupSummaryStep from './steps/PickupSummaryStep';
import ProductBoxStep from './steps/ProductBoxStep';
import 'antd/dist/antd.min.css';
import './FedexSend.css';
import couriersAPI from '../../../../../api/courier';
import warehousesAPI from '../../../../../api/warehouses';

const { Content } = Layout;
const { Step } = Steps;

function FedexSend() {
  const location = useLocation();
  const { orders, warehouseId, courierId, fboOrigin } = location?.state || {};
  const [actualStep, setActualStep] = useState(0);
  const [data, setData] = useState([]);
  const [courierCredentials, setCourierCredentials] = useState();
  const [shipmentType, setShipmentType] = useState();
  const [warehouse, setWarehouse] = useState();
  const [products, setProducts] = useState([]);

  if (orders?.length > 0) {
    orders[0].orderItems = orders[0].orderItems.filter(
      (product) => product.fulfillmentType === 'FBM'
    );
  }

  const findCourierCredential = async () => {
    const companyCourierCredentials = await couriersAPI.getCourierCredentials(
      100,
      1
    );
    const courierCreds = companyCourierCredentials?.data?.results?.find(
      (cred) => parseInt(courierId, 10) === cred.id
    );

    setCourierCredentials(courierCreds);
  };
  const getWarehouse = async () => {
    const response = await warehousesAPI.getWarehouse(warehouseId);
    setWarehouse(response.data);
  };

  useEffect(() => {
    findCourierCredential();
  }, [courierId]);

  useEffect(() => {
    if (warehouse) {
      const originCountryCode = warehouse.address?.countryCode;
      const destinationCountryCode = orders[0].destinationAddress?.countryCode;

      setShipmentType(
        originCountryCode === destinationCountryCode
          ? 'NATIONAL'
          : 'INTERNATIONAL'
      );
    }
  }, [warehouse]);

  useEffect(() => {
    getWarehouse();
  }, []);

  return (
    <div className="content-div initialContent">
      <div className="container-fedexsend">
        <Col span={12} offset={6}>
          <Steps
            current={[(actualStep + 1).toString()]}
            onChange={(step) => {
              setActualStep(step);
            }}
          >
            <Step
              key="1"
              title="Detalles y contenido"
              status={actualStep === 1 ? 'wait' : 'finish'}
            />
            <Step key="2" disabled={actualStep < 1} title="Generar Envío" />
          </Steps>
        </Col>
        <Row className="side-bar-margin-top">
          <Col span={24}>
            <Layout>
              <Content
                className="site-layout-background"
                style={{
                  margin: 0,
                  minHeight: 280,
                }}
              >
                {actualStep === 0 && (
                  <ProductBoxStep
                    orders={orders}
                    setActualStep={setActualStep}
                    setData={setData}
                    warehouseId={warehouseId}
                    courierCredentials={courierCredentials}
                    initialUnitMeasure={
                      orders &&
                      Object.keys(
                        orders.filter((order) => order.boxes.length > 0)
                      ).length > 0 &&
                      orders.filter((order) => order.boxes.length > 0)[0]
                        .boxes[0].box.measureUnit
                    }
                    shipmentType={shipmentType}
                    isPalletized={false}
                    products={products}
                    setProducts={setProducts}
                  />
                )}
                {actualStep === 1 && (
                  <PickupSummaryStep
                    orders={orders}
                    warehouse={warehouse}
                    fboOrigin={fboOrigin}
                    data={data}
                    courierId={courierId}
                    courierCredentials={courierCredentials}
                    products={products}
                  />
                )}
              </Content>
            </Layout>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FedexSend;
