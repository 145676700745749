import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Typography,
  Switch,
  Space,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import openNotification from '../../../components/Toastr';
import courierApi from '../../../api/courier';
import { updateOnboarding } from '../../../redux/onboarding/action';
import fulfillmentAPI from '../../../api/fulfillment';

function CourierAvatar({
  imgPadding,
  imgHeight,
  icon,
  name,
  label,
  formContent: FormContent,
  loadTable,
  couriersCredentials,
  isSwitch,
  isSwitchDisabled,
  tooltipText,
  isFulfillment,
}) {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const [singleCredentials, setSingleCredentials] = useState(null);
  const finalLabel = useMemo(() => {
    if (label) {
      return label;
    }
    return name;
  }, [label, name]);
  const onboardingStatus = useSelector((store) => store.Onboarding);

  useEffect(() => {
    const credentialByName = couriersCredentials.find((c) => c.name === name);
    setSingleCredentials(credentialByName);
  }, [couriersCredentials]);

  const onFinish = async (values) => {
    try {
      const payload = {
        ...values,
        ...(isFulfillment
          ? { fulfillmentCompanyName: name }
          : { courierName: name, pickupDelayDays: 1 }),
      };
      setLoading(true);
      await (isFulfillment
        ? fulfillmentAPI.createCourierCredential(payload)
        : courierApi.syncCourierCredentials(payload));
      setIsModalOpen(false);
      openNotification({
        status: true,
        content: `Se ha conectado su cuenta de ${finalLabel} correctamente.`,
      });
      loadTable();
    } catch (error) {
      openNotification({
        status: false,
        content: `Error al conectar su cuenta de ${finalLabel}.`,
      });
    }
    setLoading(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClick = async () => {
    try {
      const response = await (isFulfillment
        ? fulfillmentAPI.getCourierShipmentTypes(name)
        : courierApi.getCourierShipmentTypes(name));
      setShipmentTypes(response.data);
    } catch (error) {
      setShipmentTypes([]);
    }
    setIsModalOpen(true);
    if (
      onboardingStatus.state === 'Courier' &&
      onboardingStatus.step === 2 &&
      name === 'Fedex'
    ) {
      dispatch(updateOnboarding('Courier', 3));
    }
  };

  const onSwitch = async (values) => {
    try {
      const payload = { fbo: values, courierName: name, pickupDelayDays: 1 };
      setLoading(true);
      await courierApi.syncCourierCredentials(payload);
      setLoading(false);
      setIsModalOpen(false);
      openNotification({
        status: true,
        content: `Se ha ${
          values ? 'conectado' : 'desconectado'
        } su cuenta de ${name} correctamente.`,
      });
    } catch {
      openNotification({
        status: false,
        content: `Error al conectar su cuenta de ${name}.`,
      });
    }
  };

  return (
    <>
      <Card
        style={{
          borderRadius: '10px',
          marginBottom: '18px',
        }}
        className="courierAvatarCard"
      >
        <Row justify="space-between">
          <div style={{ display: 'flex' }}>
            <Col>
              <Card
                style={{ width: 100, textAlign: 'center' }}
                className="courierAvatarImageCard"
              >
                <div style={{ justifyContent: 'center' }}>
                  <img
                    height={imgHeight}
                    width="100%"
                    src={icon}
                    style={imgPadding ? { padding: imgPadding } : null}
                    alt={`img_${name}`}
                  />
                </div>
              </Card>
            </Col>

            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Text style={{ marginLeft: '20px', fontSize: '16px' }}>
                {finalLabel}
              </Text>
              <Space>
                <br />
                <Col>
                  <Tooltip title={tooltipText}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
              </Space>
            </Col>
          </div>

          {name !== 'Envíos LAP' && (
            <div style={{ display: 'flex', width: '28%' }}>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                {isSwitch ? (
                  <Switch
                    defaultChecked={
                      !!(singleCredentials && singleCredentials.fbo)
                    }
                    disabled={isSwitchDisabled}
                    onChange={onSwitch}
                  />
                ) : (
                  <Button
                    style={{
                      backgroundColor: `${
                        singleCredentials ? '#33BA6A' : '#CACACA'
                      }`,
                      color: `${singleCredentials ? 'white' : 'black'}`,
                      width: '150px',
                      height: '42px',
                    }}
                    onClick={onClick}
                    className={
                      (singleCredentials
                        ? 'courierConnected'
                        : 'courierNotConnected') +
                      (name === 'Fedex' ? ' step3Courier' : '')
                    }
                  >
                    {singleCredentials ? 'Conectado' : 'Conectar'}
                  </Button>
                )}
              </Col>
            </div>
          )}
        </Row>
      </Card>

      <Modal open={isModalOpen} onCancel={handleCancel} footer={null}>
        <FormContent
          loading={loading}
          courierCredentials={couriersCredentials.find((c) => c.name === name)}
          name={name}
          onFinish={onFinish}
          shipmentTypes={shipmentTypes}
          autoComplete="off"
        />
      </Modal>
    </>
  );
}

export default CourierAvatar;

CourierAvatar.defaultProps = {
  imgPadding: null,
  label: null,
};

CourierAvatar.propTypes = {
  imgPadding: PropTypes.string,
  imgHeight: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formContent: PropTypes.func,
  loadTable: PropTypes.func,
  couriersCredentials: PropTypes.instanceOf(Array),
  isSwitch: PropTypes.bool.isRequired,
  isSwitchDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string.isRequired,
  isFulfillment: PropTypes.bool,
};

CourierAvatar.defaultProps = {
  isFulfillment: false,
  formContent: () => {},
  loadTable: () => {},
  couriersCredentials: [],
};
