import { Line } from '@ant-design/plots';
import { Card, Col, Row, Spin, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Title } = Typography;

function FinanceChartLine({ title, subtitle, data, loader }) {
  const config = {
    data,
    xField: 'date',
    yField: 'value',
    legend: {
      layout: 'horizontal',
      position: 'top-right',
      maxItemWidth: 300,
    },
  };

  return (
    <Card>
      {!loader ? (
        <>
          <div className="chartLineStyleContentSales">
            <div className="headerChart">
              <Row>
                <Col span={21}>
                  <Title level={4}>{title}</Title>
                  <Title
                    level={4}
                    style={{ color: '#969696', fontSize: 12, marginTop: 10 }}
                  >
                    {subtitle}
                  </Title>
                </Col>
              </Row>
            </div>
          </div>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Line {...config} />
        </>
      ) : (
        <center>
          <Spin size="medium" />
        </center>
      )}
    </Card>
  );
}

FinanceChartLine.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  loader: PropTypes.bool.isRequired,
};

export default FinanceChartLine;
