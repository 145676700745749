import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { courierShipmentStatus } from '../../../../../utils/const';
import paymentPlanAPI from '../../../../../api/payment-plan';

function ShipmentPayment({ courierShipments }) {
  const { t } = useTranslation();

  const onClick = async () => {
    const payload = {
      courierShipmentIds: courierShipments.map((s) => s.id),
      cancelUrl: window.location.href,
    };
    const { data: url } =
      await paymentPlanAPI.createStripeShipmentCheckoutSession(payload);
    if (url) {
      window.location.href = url;
    } else {
      throw Error(t('checkout.error.stripe'));
    }
  };

  return courierShipments?.some(
    (cs) => cs.status === courierShipmentStatus.PENDING_PAYMENT
  ) ? (
    <div
      style={{
        position: 'absolute',
        inset: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(1.5px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
      }}
    >
      <Row>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '25px',
            fontWeight: 500,
            fontSize: 'large',
          }}
        >
          {t('orders.shipment.unpaidAlert.1')}
        </Col>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="primary" onClick={onClick}>
            {t('checkout.pay')}
          </Button>
        </Col>
      </Row>
    </div>
  ) : null;
}

ShipmentPayment.propTypes = {
  courierShipments: PropTypes.arrayOf(Object).isRequired,
};

export default ShipmentPayment;
