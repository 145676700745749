import { getAllTimezones } from 'countries-and-timezones';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Joyride from 'react-joyride';
import { Row, Col, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import DashboardFilter from './components/DashboardFilter';
import actionsAnalitycs from '../../redux/analytic/dashboard/actions';
import { getErrorMessage } from '../../api/api';
import openNotification from '../../components/Toastr';
import IndexChart from './components/charts/IndexDetalles';
import { dateTypes, sellerMarketplaces } from '../../utils/const';
import dashboardApi from '../../api/dashboard';
import 'antd/dist/antd.min.css';
import './home.css';
import {
  convertToISOString,
  formatDateStringForTooltips,
} from '../../utils/functions';
import { stage5Sales } from '../../stepsOnboarding';
import { updateOnboarding } from '../../redux/onboarding/action';

function Ventas() {
  const dispatch = useDispatch();
  const history = useHistory();
  const session = useSelector((store) => store.Session.session);
  const language = useSelector((store) => store.Session.language);

  const onboardingStatus = useSelector((store) => store.Onboarding);
  const [listMarketplaceAvailableClient, setListMarketplaceAvailableClient] =
    useState([]);
  const [listSkus, setListSkus] = useState([]);
  const [dataSearchProducts, setDataSearchProducts] = useState([]);
  const [dataAllTotalSales, setDataAllTotalSales] = useState([]);
  const [dataAllTotalSalesLoading, setDataAllTotalSalesLoading] =
    useState(false);
  const [dataSearchProductsLoading, setDataSearchProductsLoading] =
    useState(false);
  const [dataTopSalesProductsPerSku, setDataTopSalesProductsPerSku] = useState(
    []
  );
  const [dataTopQuantityProductsPerSku, setDataTopQuantityProductsPerSku] =
    useState([]);
  const [
    dataTopSalesProductsPerSkuLoading,
    setDataTopSalesProductsPerSkuLoading,
  ] = useState(false);
  const [
    dataTopQuantityProductsPerSkuLoading,
    setDataTopQuantityProductsPerSkuLoading,
  ] = useState(false);
  const [dataSoldUnits, setDataSoldUnits] = useState([]);
  const [dataSoldUnitsLoading, setDataSoldUnitsLoading] = useState(false);

  let partnerId = 1; // admin
  const { isAdmin } = session.userInfo;
  const timezones = getAllTimezones();
  const defaultTimezone =
    Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Santiago';

  if (!isAdmin) {
    [partnerId] = session.userInfo.commercial_partner_id;
  }

  const getMarketplaces = async (partner, period) => {
    let ptrs = [];
    if (!isAdmin) {
      ptrs = [partnerId];
    } else {
      ptrs = [partner];
    }
    const defaultFilters = {
      dashboard: 'sales',
      partner: ptrs,
      sku: [],
      marketplace: Object.values(sellerMarketplaces),
      category: ['sales', 'stock'],
      compare: false,
      dateType: dateTypes.PURCHASE_DATE,
      dateRangeOne: convertToISOString([moment().add(-1, 'M'), moment()]),
      dateRangeTwo: [],
      fulfillment: '',
      period: period || 'currentMonth',
      currency: localStorage.getItem('defaultCurrency'),
    };

    await dashboardApi
      .getMarketplacesPerClient(defaultFilters)
      .then((response) => {
        if (response?.data?.length > 0) {
          const marketAvailable = [];
          response?.data.forEach((e) => {
            if (e.name === 'Amazon') {
              marketAvailable.unshift(e.name);
            } else {
              marketAvailable.push(e.name);
            }
          });
          dispatch(
            actionsAnalitycs.setFilter({
              ...defaultFilters,
              dashboard: 'sales',
              dateRangeOne: [moment().startOf('month'), moment()],
              marketplace: marketAvailable,
              currency: localStorage.getItem('defaultCurrency'),
            })
          );
          setListMarketplaceAvailableClient(marketAvailable);
        } else {
          setListMarketplaceAvailableClient([]);
        }
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getSkusperMarketplace = async (param, rangeOne, partner, period) => {
    const dateRangeOne =
      rangeOne?.length > 0
        ? convertToISOString(rangeOne)
        : [
            moment().add(-1, 'M').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
          ];
    let ptrs = [];
    if (!isAdmin) {
      ptrs = [partnerId];
    } else {
      ptrs = partner;
    }
    const timezoneOffsetStr =
      timezones[defaultTimezone]?.utcOffsetStr ||
      timezones['America/Santiago']?.utcOffsetStr;
    const timezone = Number(timezoneOffsetStr.slice(0, 3));
    const defaultFilters = {
      dashboard: 'sales',
      partner: ptrs,
      marketplace: param || 'Amazon',
      category: ['sales', 'stock'],
      compare: false,
      dateType: dateTypes.PURCHASE_DATE,
      dateRangeOne,
      dateRangeTwo: [],
      fulfillment: '',
      period: period || 'currentMonth',
      currency: localStorage.getItem('defaultCurrency'),
      timezone,
    };
    await dashboardApi
      .getSkusPerMarketplace(defaultFilters)
      .then((response) => {
        const skuAvailable = [];
        if (response?.data && response?.data.length > 0) {
          response?.data.forEach((e) => {
            skuAvailable.push({
              value: e.sku,
              label: e.sku,
            });
          });
          skuAvailable.splice(0, 0, {
            value: '',
            label: 'All',
          });
          dispatch(
            actionsAnalitycs.setFilter({
              ...defaultFilters,
              listSkus: skuAvailable,
            })
          );
          setListSkus(skuAvailable);
        } else {
          setListSkus([]);
        }
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  useEffect(() => {
    getMarketplaces();
    if (!isAdmin) {
      getSkusperMarketplace();
    }
  }, []);

  const changeCurrency = (currency) => {
    localStorage.setItem('defaultCurrency', currency);
    dispatch(actionsAnalitycs.defaultCurrency(currency));
  };

  useEffect(() => {
    const existDefaultCurrency = localStorage.getItem('defaultCurrency');
    if (!existDefaultCurrency) {
      changeCurrency('USD');
    } else {
      changeCurrency(existDefaultCurrency);
    }
  }, []);

  const searchProducts = async (formFilter) => {
    setDataSearchProductsLoading(true);
    await dashboardApi
      .searchProducts(formFilter)
      .then((response) => {
        if (response?.data?.length > 0) {
          setDataSearchProducts(response?.data);
        }
        setDataSearchProductsLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const allTotalSales = async (formFilter) => {
    setDataAllTotalSalesLoading(true);
    await dashboardApi
      .allTotalSales(formFilter)
      .then((response) => {
        if (response?.data?.children) {
          const allTotalSalesResponse = response?.data?.children.map((item) => {
            const newItem = item;
            if (newItem?.date) {
              newItem.dateLabel = formatDateStringForTooltips(
                newItem?.date,
                language
              );
            }
            return newItem;
          });
          response.data.children = allTotalSalesResponse;
          setDataAllTotalSales(response.data);
        }
        setDataAllTotalSalesLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const allSoldUnits = async (formFilter) => {
    setDataSoldUnitsLoading(true);
    await dashboardApi
      .soldUnitsGraph(formFilter)
      .then((response) => {
        if (response?.data?.children) {
          const soldUnitsResponse = response?.data?.children.map((item) => {
            const newItem = item;
            if (newItem?.date) {
              newItem.dateLabel = formatDateStringForTooltips(
                newItem?.date,
                language
              );
            }
            return newItem;
          });
          response.data.children = soldUnitsResponse;
          setDataSoldUnits(response.data);
        }
        setDataSoldUnitsLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const topSalesProductsPerSku = async (formFilter) => {
    setDataTopSalesProductsPerSkuLoading(true);
    await dashboardApi
      .topSalesProductsPerSku(formFilter)
      .then((response) => {
        if (response?.data.length > 0) {
          setDataTopSalesProductsPerSku(response?.data);
        }
        setDataTopSalesProductsPerSkuLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };
  const topQuantityProductsPerSku = async (formFilter) => {
    setDataTopQuantityProductsPerSkuLoading(true);
    await dashboardApi
      .topQuantityProductsPerSku(formFilter)
      .then((response) => {
        if (response?.data.length > 0) {
          setDataTopQuantityProductsPerSku(response?.data);
        }
        setDataTopQuantityProductsPerSkuLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const ejectFunctions = (values) => {
    setDataSearchProducts([]);
    setDataAllTotalSales([]);
    setDataSoldUnits([]);
    setDataTopSalesProductsPerSku([]);
    setDataTopQuantityProductsPerSku([]);
    if (values) {
      dispatch(actionsAnalitycs.setFilter(values));
      allTotalSales(values);
      searchProducts(values);
      topSalesProductsPerSku(values);
      topQuantityProductsPerSku(values);
      allSoldUnits(values);
    }
  };

  const handleSkipTourCallback = (data) => {
    const { action, lifecycle, type } = data;
    if (action === 'close' && lifecycle === 'complete') {
      dispatch(updateOnboarding(null, null));
    } else if (
      type === 'error:target_not_found' &&
      onboardingStatus.state === 'Dashboard' &&
      onboardingStatus.step === 0
    ) {
      history.push('/publicidad');
    }
  };

  return (
    <>
      <div
        className="content-div"
        style={{
          backgroundColor: '#f9f9f9',
          paddingBottom: 0,
          paddingTop: 70,
          paddingLeft: 20,
          paddingRight: 15,
        }}
      >
        <Row className="home-listing-main-row listings-main">
          <Col xs={24} md={24} lg={24} xl={24} span={12}>
            <Card className="ventas-home-listing-card">
              <Row>
                <Col xs={24} span={24} className="intro-filters">
                  <DashboardFilter
                    dashboard="sales"
                    getMarketplaces={getMarketplaces}
                    ejectFunctions={ejectFunctions}
                    listMarketplaceAvailableClient={
                      listMarketplaceAvailableClient
                    }
                    listSkus={listSkus}
                    isAdmin={isAdmin}
                    multiMarket={false}
                    sku
                    getSkusperMarketplace={getSkusperMarketplace}
                    partnerId={partnerId}
                    timezoneFilter
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <div
        className="content-div step3Dashboards"
        style={{
          backgroundColor: '#f9f9f9',
          paddingBottom: 20,
          paddingTop: 5,
          paddingLeft: 40,
          paddingRight: 15,
        }}
      >
        <IndexChart
          dataSearchProducts={dataSearchProducts}
          dataSearchProductsLoading={dataSearchProductsLoading}
          dataAllTotalSales={dataAllTotalSales}
          dataAllTotalSalesLoading={dataAllTotalSalesLoading}
          dataTopSalesProductsPerSku={dataTopSalesProductsPerSku}
          dataTopQuantityProductsPerSku={dataTopQuantityProductsPerSku}
          dataTopSalesProductsPerSkuLoading={dataTopSalesProductsPerSkuLoading}
          dataTopQuantityProductsPerSkuLoading={
            dataTopQuantityProductsPerSkuLoading
          }
          dataSoldUnits={dataSoldUnits}
          dataSoldUnitsLoading={dataSoldUnitsLoading}
          currency={localStorage.getItem('defaultCurrency')}
        />
      </div>
      {onboardingStatus.state === 'Dashboard' &&
        onboardingStatus.step === 0 && (
          <Joyride
            steps={stage5Sales}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            disableScrolling
            spotlightClicks
            continuous
            hideBackButton
          />
        )}
    </>
  );
}

export default Ventas;
