import fileDownload from 'js-file-download';
import api from './api';

const prefix = '/dashboardV2';

const dashboardV2API = {
  getFinancialSummary: (filter) => {
    return api.get(`${prefix}/finance-report`, { params: filter });
  },
  downloadFinancialSummaryCSV(filter) {
    const filename = 'finance-report.csv';
    return api({
      url: `${prefix}/finance-report-csv-download`,
      method: 'GET',
      responseType: 'blob',
      params: filter,
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  },
};

export default dashboardV2API;
