import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import JsPDF from 'jspdf';
import moment from 'moment';
import {
  LoadingOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { Row, Col, Card, Typography, Spin, Button, Space, Menu } from 'antd';
import selectors from '../../../../redux/analytic/dashboard/selectors';
import dashboardApi from '../../../../api/dashboard';
import DashboardTable from '../DashboardTable';
import ChartLine from './components/chartLine';
import ChartDonut from './components/chartDonut';
import 'antd/dist/antd.min.css';
import {
  thousandsSeparator,
  formatDateStringForTooltips,
} from '../../../../utils/functions';
import openNotification from '../../../../components/Toastr';

const { Title } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

function Index({
  dataSearchProducts,
  dataSearchProductsLoading,
  dataAllTotalSales,
  dataAllTotalSalesLoading,
  dataTopSalesProductsPerSku,
  dataTopQuantityProductsPerSku,
  dataTopSalesProductsPerSkuLoading,
  dataTopQuantityProductsPerSkuLoading,
  dataSoldUnits,
  dataSoldUnitsLoading,
  currency,
}) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.Session.language);
  const [loadingFile, setLoadingFile] = useState(false);
  const currencyState = useSelector(selectors.selectCurrency);

  const rawfilter = useSelector(selectors.selectRawFilter);

  const tooltipProperties = {
    showTitle: false,
    title: 'dateLabel',
    domStyles: {
      'g2-tooltip': {
        'text-align': 'left',
        fontFamily: 'Poppins',
      },
      'g2-tooltip-item': {
        position: 'absolute',
        fontFamily: 'Poppins',
      },
      'g2-tooltip-value': {
        display: 'block',
        'font-weight': 'bold',
        float: 'none',
        margin: '0.5em 1em',
        'font-size': '20px',
        fontFamily: 'Poppins',
      },
    },
  };

  const allTotalSalesTooltip = {
    ...tooltipProperties,
    customItems: (originalItems) => {
      return originalItems.map((row) => {
        const tooltipRow = row;
        tooltipRow.name = formatDateStringForTooltips(
          tooltipRow.data.dateOrigin,
          language
        );
        tooltipRow.value = `${thousandsSeparator(
          parseInt(tooltipRow.data.value, 10)
        )}${currency ? ` ${currencyState}` : ''}`;
        return tooltipRow;
      });
    },
  };

  const handleDownloadSalesCSV = async () => {
    setLoadingFile(true);
    return dashboardApi
      .downloadTotalSalesCSV(rawfilter)
      .then(() => {
        setLoadingFile(false);
      })
      .catch(() => {
        openNotification({
          status: false,
          content: t('download.csvError'),
        });
      });
  };
  const handleDownloadUnitsCSV = async () => {
    setLoadingFile(true);
    return dashboardApi
      .downloadTotalSoldUnitsCSV(rawfilter)
      .then(() => {
        setLoadingFile(false);
      })
      .catch(() => {
        openNotification({
          status: false,
          content: t('download.csvError'),
        });
      });
  };

  const handleDownloadSkuCSV = async () => {
    setLoadingFile(true);
    return dashboardApi
      .downloadSearchProductsCSV(rawfilter)
      .then(() => {
        setLoadingFile(false);
      })
      .catch(() => {
        openNotification({
          status: false,
          content: t('download.csvError'),
        });
      });
  };

  const handleDownloadSalesPDF = async () => {
    const doc = new JsPDF();

    doc.text('Ventas Totales', 80, 15);

    doc.setFontSize(10);
    doc.text(
      typeof dataSoldUnits.length === 'undefined'
        ? `${moment(dataSoldUnits.children[0]?.dateOrigin).format(
            "D MMM [']YY"
          )} - ${moment(
            dataSoldUnits.children[dataSoldUnits.children.length - 2].dateOrigin
          ).format("D MMM [']YY")}`
        : '',
      150,
      10
    );

    const chart = document.getElementById('graficoSales');
    const canvas = chart.querySelector('canvas');
    const chartDataURI = canvas.toDataURL('image/png', 1.0);
    doc.addImage(chartDataURI, 'PNG', 10, 25, 180, 70);

    doc.save('totalSalesGraph.pdf');
  };

  const handleDownloadUnitsPDF = async () => {
    const doc = new JsPDF();

    doc.text(t('home.charts.totalUnits'), 80, 15);

    doc.setFontSize(10);
    doc.text(
      typeof dataAllTotalSales.length === 'undefined'
        ? `${moment(dataAllTotalSales.children[0]?.dateOrigin).format(
            "D MMM [']YY"
          )} - ${moment(
            dataAllTotalSales.children[dataAllTotalSales.children.length - 2]
              .dateOrigin
          ).format("D MMM [']YY")}`
        : '',
      150,
      10
    );

    const chart = document.getElementById('graficoUnits');
    const canvas = chart.querySelector('canvas');
    const chartDataURI = canvas.toDataURL('image/png', 1.0);
    doc.addImage(chartDataURI, 'PNG', 10, 25, 180, 70);

    doc.save('totalUnitsGraph.pdf');
  };

  const handleDownloadSalesPiePDF = async () => {
    const doc = new JsPDF();

    doc.text(t('home.charts.totalSalesBySku'), 60, 15);

    doc.setFontSize(10);
    doc.text(
      typeof dataAllTotalSales.length === 'undefined'
        ? `${moment(dataAllTotalSales.children[0]?.dateOrigin).format(
            "D MMM [']YY"
          )} - ${moment(
            dataAllTotalSales.children[dataAllTotalSales.children.length - 2]
              .dateOrigin
          ).format("D MMM [']YY")}`
        : '',
      150,
      10
    );

    const chart = document.getElementById('rosquillaVentas');
    const canvas = chart.querySelector('canvas');
    const chartDataURI = canvas.toDataURL('image/png', 1.0);
    doc.addImage(chartDataURI, 'PNG', 35, 30, 140, 80);

    doc.save('totalSalesGraphBySKU.pdf');
  };

  const handleDownloadUnitsPiePDF = async () => {
    const doc = new JsPDF();

    doc.text(t('home.charts.soldUnitsBySku'), 60, 15);

    doc.setFontSize(10);
    doc.text(
      typeof dataAllTotalSales.length === 'undefined'
        ? `${moment(dataAllTotalSales.children[0]?.dateOrigin).format(
            "D MMM [']YY"
          )} - ${moment(
            dataAllTotalSales.children[dataAllTotalSales.children.length - 2]
              .dateOrigin
          ).format("D MMM [']YY")}`
        : '',
      150,
      10
    );

    const chart = document.getElementById('rosquillaUnidades');
    const canvas = chart.querySelector('canvas');
    const chartDataURI = canvas.toDataURL('image/png', 1.0);
    doc.addImage(chartDataURI, 'PNG', 35, 30, 140, 80);

    doc.save('totalUnitsGraphBySKU.pdf');
  };

  const dropdownMenu = (theme, handleCSV, handlePDF) => (
    <Menu theme={theme}>
      <Menu.Item key="csv" onClick={handleCSV}>
        <Space>{t('download.csv')} </Space>
        <Space>
          {loadingFile ? (
            <Spin style={{ marginTop: '3px' }} size="small" />
          ) : (
            <VerticalAlignBottomOutlined />
          )}
        </Space>
      </Menu.Item>
      <Menu.Item key="pdf" onClick={handlePDF}>
        <Space>{t('download.pdf')} </Space>
        <Space>
          {loadingFile ? (
            <Spin style={{ marginTop: '3px' }} size="small" />
          ) : (
            <VerticalAlignBottomOutlined style={{ marginLeft: '3px' }} />
          )}
        </Space>
      </Menu.Item>
    </Menu>
  );

  const soldUnitsTooltip = {
    ...tooltipProperties,
    customItems: (originalItems) => {
      return originalItems.map((row) => {
        const tooltipRow = row;
        tooltipRow.name = formatDateStringForTooltips(
          tooltipRow.data.dateOrigin,
          language
        );
        tooltipRow.value = thousandsSeparator(
          parseInt(tooltipRow.data.value, 10)
        );
        return tooltipRow;
      });
    },
  };

  const filterArrayToString = (params) => {
    const object = {
      ...params,
      marketplace: params.marketplace.toString(),
      dateRangeOne: params.dateRangeOne.toString(),
      partner: params.partner.toString(),
      category: params.category.toString(),
      sku: params.sku,
      dateType: params.dateType,
    };
    return object;
  };

  return (
    <>
      <Row id="graficoSales">
        <Col span={24}>
          <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
            {!dataAllTotalSalesLoading ? (
              <Row>
                <Col span={24}>
                  <ChartLine
                    titleChart={`${t('home.charts.totalSales.title')}: `}
                    titleCard={t('home.charts.totalSales.title')}
                    dataSource={dataAllTotalSales || {}}
                    currency
                    tooltipProperties={allTotalSalesTooltip}
                    handleDownloadCSV={handleDownloadSalesCSV}
                    handleDownloadPDF={handleDownloadSalesPDF}
                    dropdownMenu={dropdownMenu}
                  />
                </Col>
              </Row>
            ) : (
              <div>
                <center>
                  <Spin style={{ marginTop: '10%' }} indicator={antIcon} />
                </center>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <Row id="graficoUnits">
        <Col span={24}>
          <Card bordered={false} style={{ width: '100%', marginTop: 10 }}>
            {!dataSoldUnitsLoading ? (
              <Row>
                <Col span={24}>
                  <ChartLine
                    titleChart={`${t('home.charts.totalUnits')}: `}
                    titleCard={t('home.charts.soldUnits.title')}
                    dataSource={dataSoldUnits || {}}
                    currency={false}
                    tooltipProperties={soldUnitsTooltip}
                    loadingFile={loadingFile}
                    handleDownloadCSV={handleDownloadUnitsCSV}
                    handleDownloadPDF={handleDownloadUnitsPDF}
                    dropdownMenu={dropdownMenu}
                  />
                </Col>
              </Row>
            ) : (
              <div>
                <center>
                  <Spin style={{ marginTop: '10%' }} indicator={antIcon} />
                </center>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col id="rosquillaVentas" span={12}>
          <div className="detalle-table" style={{ marginRight: 5 }}>
            <Card bordered={false} style={{ width: '100%', textAlign: 'left' }}>
              <Row>
                <Col span={16}>
                  <div className="chartLineStyleContentSales">
                    <Title level={2}>{t('home.charts.totalSales.title')}</Title>
                    <span style={{ fontStyle: 'italic' }}>
                      {t('home.charts.breakdownBySku')}
                    </span>
                  </div>
                </Col>
                <Col span={4}>
                  <Button
                    className="btn-basic"
                    onClick={handleDownloadSalesPiePDF}
                  >
                    <Space>
                      {t('download.pdf')}{' '}
                      {loadingFile ? (
                        <Spin style={{ marginTop: '3px' }} size="small" />
                      ) : (
                        <VerticalAlignBottomOutlined />
                      )}
                    </Space>
                  </Button>
                </Col>
              </Row>
              {!dataTopSalesProductsPerSkuLoading ? (
                <ChartDonut dataSource={dataTopSalesProductsPerSku} currency />
              ) : (
                <div>
                  <center>
                    <Spin style={{ marginTop: '10%' }} indicator={antIcon} />
                  </center>
                </div>
              )}
            </Card>
          </div>
        </Col>
        <Col id="rosquillaUnidades" span={12}>
          <div className="detalle-table" style={{ marginLeft: 5 }}>
            <Card bordered={false} style={{ width: '100%', textAlign: 'left' }}>
              <Row>
                <Col span={16}>
                  <div className="chartLineStyleContentSales">
                    <Title level={2}>{t('home.charts.soldUnits.title')}</Title>
                    <span style={{ fontStyle: 'italic' }}>
                      {t('home.charts.breakdownBySku')}
                    </span>
                  </div>
                </Col>
                <Col span={4}>
                  <Button
                    className="btn-basic"
                    onClick={handleDownloadUnitsPiePDF}
                  >
                    <Space>
                      {t('download.pdf')}{' '}
                      {loadingFile ? (
                        <Spin style={{ marginTop: '3px' }} size="small" />
                      ) : (
                        <VerticalAlignBottomOutlined />
                      )}
                    </Space>
                  </Button>
                </Col>
              </Row>
              {!dataTopQuantityProductsPerSkuLoading ? (
                <ChartDonut
                  dataSource={dataTopQuantityProductsPerSku}
                  currency={false}
                />
              ) : (
                <div>
                  <center>
                    <Spin style={{ marginTop: '10%' }} indicator={antIcon} />
                  </center>
                </div>
              )}
            </Card>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="detalle-table">
            <Card bordered={false} style={{ width: '100%', textAlign: 'left' }}>
              <div className="chartLineStyleContentSales">
                <Row>
                  <Col span={20}>
                    <Title level={2} style={{ marginBottom: 24 }}>
                      {' '}
                      {t('home.charts.breakdownBySku')}{' '}
                    </Title>
                  </Col>
                  <Col span={4}>
                    <Button
                      className="btn-basic"
                      onClick={handleDownloadSkuCSV}
                    >
                      <Space>
                        {t('download.csv')}{' '}
                        {loadingFile ? (
                          <Spin style={{ marginTop: '3px' }} size="small" />
                        ) : (
                          <VerticalAlignBottomOutlined />
                        )}
                      </Space>
                    </Button>
                  </Col>
                </Row>
              </div>
              <DashboardTable
                dataSource={dataSearchProducts}
                rawfilter={rawfilter}
                filterArrayToString={filterArrayToString}
                loading={dataSearchProductsLoading}
                currency
              />
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
}

Index.propTypes = {
  dataSearchProducts: PropTypes.arrayOf(Object).isRequired,
  dataSearchProductsLoading: PropTypes.bool.isRequired,
  dataAllTotalSales: PropTypes.arrayOf(Object).isRequired,
  dataAllTotalSalesLoading: PropTypes.bool.isRequired,
  dataTopSalesProductsPerSku: PropTypes.arrayOf(Object).isRequired,
  dataTopQuantityProductsPerSku: PropTypes.arrayOf(Object).isRequired,
  dataTopSalesProductsPerSkuLoading: PropTypes.bool.isRequired,
  dataTopQuantityProductsPerSkuLoading: PropTypes.bool.isRequired,
  dataSoldUnits: PropTypes.arrayOf(Object).isRequired,
  dataSoldUnitsLoading: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
};

export default Index;
