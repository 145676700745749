import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import '../shipments.css';

function ShipmentStatusTag({ status, type }) {
  const { t } = useTranslation();
  const restockStatus = {
    Draft: { color: 'default', text: t('orders.status.Draft') },
    Working: { color: 'warning', text: t('orders.status.Working') },
    ReadyToShip: { color: 'warning', text: t('orders.status.ReadyToShip') },
    Shipped: { color: 'processing', text: t('orders.status.Shipped') },
    Delivered: { color: 'success', text: t('orders.status.Delivered') },
    CheckedIn: { color: 'processing', text: t('orders.status.CheckedIn') },
    Receiving: { color: 'processing', text: t('orders.status.Receiving') },
    Closed: { color: 'success', text: t('orders.status.Closed') },
    Error: { color: 'error', text: t('orders.status.Error') },
    Cancelled: { color: 'default', text: t('orders.status.Cancelled') },
    Deleted: { color: 'default', text: t('orders.status.Deleted') },
    InTransit: { color: 'processing', text: t('orders.status.InTransit') },
    PendingCollectionMethod: {
      color: 'orange',
      text: t('orders.status.PendingCollectionMethod'),
    },
    PendingPayment: { color: 'red', text: t('orders.status.PendingPayment') },
  };

  const saleOrderStatus = {
    Shipped: { color: 'success', text: t('orders.status.Shipped') },
    ReadyForPickup: {
      color: 'warning',
      text: t('orders.status.ReadyForPickup'),
    },
    Cancelled: { color: 'default', text: t('orders.status.Cancelled') },
    Working: { color: 'warning', text: 'En tránsito' },
    PendingCollectionMethod: {
      color: 'orange',
      text: t('orders.status.PendingCollectionMethod'),
    },
    PendingPayment: { color: 'red', text: t('orders.status.PendingPayment') },
  };

  const thirdPartyStatus = {
    Draft: { color: 'default', text: t('orders.status.Draft') },
    Cancelled: { color: 'error', text: t('orders.status.Cancelled') },
    Shipped: { color: 'success', text: t('orders.status.Shipped') },
    PendingCollectionMethod: {
      color: 'orange',
      text: t('orders.status.PendingCollectionMethod'),
    },
    PendingPayment: { color: 'red', text: t('orders.status.PendingPayment') },
  };

  const options = {
    RestockOrder: restockStatus,
    SaleOrder: saleOrderStatus,
    ThirdPartyShipment: thirdPartyStatus,
  };
  const color = options[type][status]?.color;
  const text = options[type][status]?.text;

  return (
    <div>
      {color && text && (
        <Tag className="shipment-status-tag" color={color}>
          {text}
        </Tag>
      )}
    </div>
  );
}

ShipmentStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ShipmentStatusTag;
