import { Card, Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import * as queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import companiesAPI from '../../api/company';
import openNotification from '../../components/Toastr';
import CompanyData from '../myAccount/components/steps/CompanyData';
import Checkout from './components/Checkout';
import './subscriptions.css';
import planAPI from '../../api/plan';
import paymentPlanAPI from '../../api/payment-plan';
import { getErrorMessage } from '../../api/api';

function Subscriptions() {
  const companyId = useSelector(
    (store) => store.Session.session.userInfo.company_id[0]
  );
  const history = useHistory();
  const { t } = useTranslation();

  const { planId, cancellingId } = queryString.parse(window.location.search);
  const [paymentPlan, setPaymentPlan] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [submitPaymentForm, setSubmitPaymentForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState();

  const [billingForm] = Form.useForm();
  const [paymentForm] = Form.useForm();
  const billingCountry = Form.useWatch('countryCode', billingForm);
  const paymentMethod = Form.useWatch('paymentMethod', paymentForm);

  useEffect(async () => {
    if (!submitPaymentForm) return;
    await paymentForm
      .validateFields()
      .then(() => paymentForm.submit())
      .catch(() => setLoading(false));
    paymentForm.submit();
  }, [submitPaymentForm]);

  const saveBillingInformation = async (values) => {
    const updateCompanyPayload = {
      name: values.name,
      contactName: values.contactName,
      email: values.email,
      fantasyName: values.fantasyName,
      ref: values.ref,
      industry: values.industry,
      countryCode: values.countryCode,
    };
    const updateCompanyAddressPayload = {
      streetName: values.streetName,
      additionalAddressInfo: values.additionalAddressInfo,
      city: values.city,
      countryCode: values.countryCode,
      stateOrProvinceCode: values.stateOrProvinceCode ?? values.countryCode,
      postalCode: values.postalCode,
    };
    // Update Company columns
    await companiesAPI.update(companyId, updateCompanyPayload);
    const method = currentAddress
      ? companiesAPI.updateCompanyAddress
      : companiesAPI.createCompanyAddress;
    const response = await method(companyId, updateCompanyAddressPayload);
    setCurrentAddress(response.data);
  };

  const onBillingFormFinish = async (values) => {
    try {
      setLoading(true);
      await saveBillingInformation(values);
      setSubmitPaymentForm(true);
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
      setLoading(false);
    }
  };

  const onApplyCoupon = async (value) => {
    const params = {
      planId,
      couponCode: value,
      entityType: billingCountry === 'CL' ? 'NATIONAL' : 'INTERNATIONAL',
      paymentMethod,
    };
    try {
      const { data } = await paymentPlanAPI.find(params);
      if (!data.length) {
        throw new Error(t('checkout.error.invalidCoupon'));
      }
      setPaymentPlan(data?.[0]);
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    }
  };

  const onPaymentFormFinish = async (values) => {
    try {
      const cancellingQueryParam = cancellingId
        ? `&cancellingId=${cancellingId}`
        : '';
      if (values.paymentMethod === 'Mercado Pago') {
        history.push(
          `/checkout/mercadoPago?paymentPlanId=${
            paymentPlan.id
          }&email=${billingForm.getFieldValue('email')}${cancellingQueryParam}`
        );
      } else if (values.paymentMethod === 'PayPal') {
        history.push(
          `/checkout/payPal?paymentPlanId=${paymentPlan.id}${cancellingQueryParam}`
        );
      } else if (values.paymentMethod === 'Stripe') {
        const priceIds = [paymentPlan.externalId];
        const extraPlan = paymentForm.getFieldValue('extraPlan');
        if (extraPlan) {
          priceIds.push(extraPlan);
        }
        const { data: url } =
          await paymentPlanAPI.createStripeSubscriptionCheckoutSession({
            priceIds,
            cancelUrl: window.location.href,
            cancellingId,
          });
        if (url) {
          window.location.href = url;
        } else {
          throw Error(t('checkout.error.stripe'));
        }
      }
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    } finally {
      setSubmitPaymentForm(false);
      setLoading(false);
    }
  };

  const submitForms = async () => {
    await billingForm.validateFields().catch(() =>
      openNotification({
        status: false,
        content: t('checkout.error.billingInfo'),
      })
    );
    billingForm.submit();
  };

  useEffect(() => {
    const getPlan = async () => {
      try {
        const response = await planAPI.get(planId);
        setPlan(response.data);
      } catch (error) {
        openNotification({ status: false });
      }
    };
    getPlan();
  }, []);

  useEffect(() => {
    const getEntityType = () => {
      if (billingCountry === 'CL') return 'NATIONAL';
      return 'INTERNATIONAL';
    };

    const getPaymentPlan = async () => {
      if (!paymentMethod) return;
      const entityType = getEntityType();
      const couponCode = paymentForm.getFieldValue('couponCode') || null;
      const params = {
        planId,
        couponCode,
        entityType,
        paymentMethod,
      };
      let newPaymentPlan = null;
      try {
        const response = await paymentPlanAPI.find(params);
        if (!response.data?.[0]) throw new Error();
        newPaymentPlan = response.data?.[0];
      } catch (error) {
        openNotification({
          status: false,
          content: getErrorMessage(
            t('checkout.error.unavailablePaymentMethod')
          ),
        });
      } finally {
        setPaymentPlan(newPaymentPlan);
      }
    };
    if (billingCountry) {
      getPaymentPlan();
    }
  }, [billingCountry, paymentMethod]);
  return (
    <Col className="subscriptionsContainer" span={24}>
      <Row>
        <Col span={14}>
          {billingForm && (
            <Card className="billingInformationContainer">
              <CompanyData
                form={billingForm}
                onFinish={onBillingFormFinish}
                showBillingForm
                setCurrentAddress={setCurrentAddress}
              />
            </Card>
          )}
        </Col>
        <Col span={10}>
          <Card className="checkoutContainer">
            <Checkout
              form={paymentForm}
              loading={loading}
              submitForms={submitForms}
              onFinish={onPaymentFormFinish}
              plan={plan}
              paymentPlan={paymentPlan}
              billingCountry={billingCountry}
              onApplyCoupon={onApplyCoupon}
            />
          </Card>
        </Col>
      </Row>
    </Col>
  );
}

export default Subscriptions;
