import api from './api';

const prefix = '/couriers';
const couriersAPI = {
  createCourierShipment: (courier, saleOrderId) => {
    const body = {
      saleOrderId,
    };
    return api.post(`${prefix}/${courier}/shipment`, body);
  },
  findCourierShipments: (params) => {
    return api.get(`${prefix}/courierShipments/find`, { params });
  },
  createMultipleCourierShipments: (courier, payload) =>
    api.post(`${prefix}/${courier}/shipments`, payload),
  cancelCourierShipment: (courier, shipmentId) =>
    api.put(`${prefix}/${courier}/shipment/${shipmentId}/cancel`),
  getShipmentDocument: (courier, shipmentId) =>
    api.get(`${prefix}/${courier}/shipment/${shipmentId}/labels`),
  getMultipleShipmentDocument: (courier, trackingNumbers) => {
    const params = {
      masterTrackingNumbers: trackingNumbers,
    };
    return api.get(`${prefix}/${courier}/shipments/labels`, { params });
  },
  syncCourierCredentials: (body) =>
    api.post(`${prefix}/courierCredential`, body),
  getCourierCredentials: (limit, page) => {
    const params = {
      take: limit,
      page,
    };
    return api.get(`${prefix}/credentials`, { params });
  },
  getPickupAvailability: (courier, payload) =>
    api.post(`${prefix}/${courier}/pickup/availability`, payload),
  getLabels: (courier, trackingNumber) =>
    api.get(`${prefix}/${courier}/shipment/${trackingNumber}/labels`),
  getCourierShipmentTypes: (courier) =>
    api.get(`${prefix}/${courier}/types/shipment`),
  updateCourierShipmentTypes: (courier, payload) =>
    api.post(`${prefix}/${courier}/types/shipment`, payload),
  updateCourierShipmentStatus: (payload) =>
    api.put(`${prefix}/shipment/status`, payload),
  updateOrderStatus: (payload) =>
    api.put(`${prefix}/shipment/order/status`, payload),
  getAllShipmentTypes: (courier, payload) =>
    api.post(`${prefix}/${courier}/types/shipment/order/all`, payload),
  getShipmentTypes: (courier, params) =>
    api.get(`${prefix}/${courier}/types/location/shipment`, { params }),
  getRatesAndTransitTimes: (courier, payload) =>
    api.post(`${prefix}/${courier}/rates`, payload),
  createPickup: (courier, payload) =>
    api.post(`${prefix}/${courier}/pickup`, payload),
  updatePickup: (courier, pickUpId, payload) =>
    api.put(`${prefix}/${courier}/pickup/${pickUpId}`, payload),
  cancelPickup: (courier, id) =>
    api.put(`${prefix}/${courier}/pickup/${id}/cancel`),
  getCourierCredentialsByCoverage: (
    saleOrderIds,
    warehouseId,
    destinationCountryCode = null,
    destinationStateOrProvinceCode = null
  ) => {
    const params = {
      saleOrderIds,
      warehouseId,
      destinationCountryCode,
      destinationStateOrProvinceCode,
    };
    return api.get(`${prefix}/coverage/credentials`, { params });
  },
  getCouriersByCoverage: (origin, destination) => {
    const params = {
      originCountryCode: origin.countryCode,
      originStateOrProvinceCode: origin.stateOrProvinceCode,
      destinationCountryCode: destination.countryCode,
      destinationStateOrProvinceCode: destination.stateOrProvinceCode,
    };
    return api.get(`${prefix}/coverage`, { params });
  },
  uploadDocument: (file, payload, courier) => {
    const formData = new FormData();
    formData.append('file', file);
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return api({
      url: `${prefix}/${courier}/document/upload`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  },
  requestAccount: (formData) => {
    return api({
      url: `${prefix}/request-account`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  },
  commercialInvoiceRequirements: (courier, origin, destination) => {
    return api.get(
      `${prefix}/${courier}/customsDocuments/commercialInvoice/requirements`,
      { params: { origin, destination } }
    );
  },
};
export default couriersAPI;
