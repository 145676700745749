import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Modal,
  Radio,
  Row,
  Tooltip,
} from 'antd';
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import FedexPickup from './FedexPickup';
import CheckCards from '../CheckCards';
import { courierShipmentStatus } from '../../../../../../utils/const';
import restockOrdersAPI from '../../../../../../api/restock-orders';
import FBMCouriers from '../../../../../../utils/FBMCouriers';
import openNotification from '../../../../../../components/Toastr';
import { getErrorMessage } from '../../../../../../api/api';
import couriersAPI from '../../../../../../api/courier';
import ShipmentTypeEnum from '../../../../../shipments/enums/shipmentType.enum';

function EditPickUpModal({
  isOpen,
  setIsOpen,
  courierShipment,
  courierCredentialId,
  getOrder,
  warehouseId,
  order,
  previousCollectionMethod,
}) {
  const { t } = useTranslation();
  const [radioDefault, setRadioDefault] = useState(false);
  const [isPickUp, setIsPickUp] = useState();
  const [deliveryOfficeForm] = Form.useForm();
  const [deliveryOfficeDate, setDeliveryOfficeDate] = useState(null);
  const [sendContentLoading, setSendContentLoading] = useState(false);

  const formRef = React.useRef(null);
  const fedexPickupRef = useRef(null);

  const handleCancelModal = () => {
    setIsOpen(false);
  };

  const handleNewPickUp = () => {
    getOrder();
    setIsOpen(false);
  };

  const disabledPreviousDate = (current) => {
    return current && current < moment(new Date()).subtract(1, 'day');
  };

  const onFinishDelivery = async (values) => {
    return values;
  };

  const onSubmitButtonClickPickUp = () => {
    return new Promise((resolve, reject) => {
      formRef.current
        .validateFields()
        .then(() => {
          const formValues = formRef.current.getFieldsValue();
          resolve(formValues);
        })
        .catch((error) => {
          openNotification({ status: false, content: getErrorMessage(error) });
          reject(error);
        });
    });
  };

  const onSubmitButtonClickDelivery = () => {
    return new Promise((resolve, reject) => {
      deliveryOfficeForm
        .validateFields()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          openNotification({ status: false, content: getErrorMessage(error) });
          reject(error);
        });
    });
  };

  const sendContent = async () => {
    setSendContentLoading(true);
    try {
      if (
        [FBMCouriers.FEDEX, FBMCouriers.LAPCOURIER].includes(
          courierShipment.courierCompanyName
        )
      ) {
        if (previousCollectionMethod && previousCollectionMethod.pickUp) {
          await couriersAPI.cancelPickup(
            courierShipment.courierCompanyName,
            previousCollectionMethod.pickUp
          );
        }
        if (
          previousCollectionMethod &&
          previousCollectionMethod.deliveryOffice &&
          order?.type === ShipmentTypeEnum.RESTOCK
        ) {
          // Only Restock
          await restockOrdersAPI.update(order.id, {
            officeDeliveryDate: null,
          });
        }

        if (isPickUp) {
          const pickUpValues = await onSubmitButtonClickPickUp();
          const result = await fedexPickupRef.current.onPickupFinish(
            pickUpValues
          );
          if (!result) {
            setSendContentLoading(false);
            return;
          }
        } else if (
          !isPickUp &&
          isPickUp !== null &&
          order?.type === ShipmentTypeEnum.RESTOCK
        ) {
          // Only Restock
          await onSubmitButtonClickDelivery();
          await restockOrdersAPI.update(order.id, {
            officeDeliveryDate: deliveryOfficeDate,
          });
        }
      }

      if (
        [FBMCouriers.LAPCOURIER].includes(courierShipment.courierCompanyName) &&
        courierShipment?.status ===
          courierShipmentStatus.PENDING_COLLECTION_METHOD
      ) {
        const payload = {
          status: courierShipmentStatus.READY_FOR_PICKUP,
        };
        if (order?.type === ShipmentTypeEnum.RESTOCK) {
          payload.restockOrderId = Number(order?.id);
        } else if (order?.type === ShipmentTypeEnum.THIRDPARTYSHIPMENT) {
          payload.thirdPartyShipmentId = Number(order?.id);
        } else if (order?.type === ShipmentTypeEnum.SALEORDER) {
          payload.saleOrderId = Number(order?.id);
        }
        await couriersAPI.updateCourierShipmentStatus(payload);
      }

      openNotification({
        status: true,
        content: t('orders.newOrder.shipment.success.description'),
        info: t('orders.newOrder.shipment.success.title'),
      });
      handleNewPickUp();
    } catch (error) {
      openNotification({
        status: false,
        content: error?.response?.data?.message,
      });
    } finally {
      setSendContentLoading(false);
      await getOrder();
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancelModal}
      footer={false}
      width="80%"
    >
      <div className="title-content">Modo de recolección</div>
      <Radio.Group
        className="cardRadioGroupContent"
        name="pickUpSelected"
        onChange={(e) => {
          setRadioDefault(true);
          setIsPickUp(e.target.value);
        }}
        style={{ marginBottom: 18 }}
      >
        <CheckCards
          isPack={isPickUp}
          radioDefault={radioDefault}
          inputRadioActive={{
            border: '1px solid rgba(0, 229, 166, 1)',
            backgroundColor: 'rgba(0, 229, 166, 0.05)',
          }}
          tag={false}
          title={t('orders.newOrder.pickUp.radioOneTitle')}
          text={t('orders.newOrder.pickUp.radioOneText')}
          value
        />
        <CheckCards
          isPack={isPickUp}
          radioDefault={radioDefault}
          inputRadioActive={{
            border: '1px solid rgba(0, 229, 166, 1)',
            backgroundColor: 'rgba(0, 229, 166, 0.05)',
          }}
          tag={false}
          title={t('orders.newOrder.pickUp.radioTwoTitle')}
          text={t('orders.newOrder.pickUp.radioTwoText')}
          value={false}
        />
      </Radio.Group>
      {isPickUp !== undefined && (
        <Card style={{ borderRadius: 4 }}>
          {isPickUp !== undefined && isPickUp && (
            <FedexPickup
              ref={fedexPickupRef}
              warehouseId={warehouseId}
              courierCredentialId={courierCredentialId}
              showConfirmButton={false}
              formRef={formRef}
              order={order}
            />
          )}
          {isPickUp !== undefined && !isPickUp && (
            <Row gutter={[32, 32]}>
              <Col>
                <Form
                  layout="vertical"
                  form={deliveryOfficeForm}
                  onFinish={onFinishDelivery}
                >
                  <Form.Item
                    label={
                      <div>
                        {t('orders.state.pickUp.officeDelivery')}:{' '}
                        <Tooltip title={t('orders.state.pickUp.referenceDate')}>
                          <InfoCircleOutlined />
                          &nbsp;
                        </Tooltip>
                      </div>
                    }
                    name="officeDeliveryDate"
                    rules={[
                      {
                        required: true,
                        message: 'Ingrese la fecha de entrega.',
                      },
                    ]}
                  >
                    <DatePicker
                      className="datePickerStyle"
                      placeholder="YYYY-MM-DD"
                      disabledDate={disabledPreviousDate}
                      onChange={(value) => setDeliveryOfficeDate(value)}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          )}
        </Card>
      )}
      <Row style={{ marginTop: 15, justifyContent: 'end' }}>
        <Col>
          <Button
            className="next-step-shipment"
            onClick={sendContent}
            loading={sendContentLoading}
          >
            {t('orders.alertButtonSend')} <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

EditPickUpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  courierShipment: PropTypes.isRequired,
  courierCredentialId: PropTypes.string.isRequired,
  getOrder: PropTypes.func.isRequired,
  warehouseId: PropTypes.number.isRequired,
  order: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
  previousCollectionMethod: PropTypes.shape({
    pickUp: PropTypes.number,
    deliveryOffice: PropTypes.bool,
  }),
};

EditPickUpModal.defaultProps = {
  previousCollectionMethod: null,
};

export default EditPickUpModal;
