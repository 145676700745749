import React, { useEffect, useState } from 'react';
import { Col, Divider, Spin, Row, Typography } from 'antd';
import {
  CheckOutlined,
  ExclamationCircleTwoTone,
  TeamOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import openNotification from '../../../components/Toastr';
import couriersApi from '../../../api/courier';
import '../../order/components/steps/steps.css';
import ReviewShipment from '../../order/components/steps/components/ReviewShipment';
import ReviewBoxes from '../../order/components/steps/components/ReviewBoxes';
import ReviewLabelsDocuments from '../../order/components/steps/components/ReviewLabelsDocuments';
import ReviewDetailContent from '../../order/components/steps/components/ReviewDetailContent';
import InformationBox from '../../order/components/steps/components/InformationBox';
import ShipmentTypeTag from '../components/ShipmentTypeTag';
import ShipmentStatusTag from '../components/ShipmentStatusTag';
import thirdPartyShipmentAPI from '../../../api/thirdPartyShipment';
import { courierShipmentStatus } from '../../../utils/const';
import PendingShipmentPayment from '../../order/components/steps/components/PendingShipmentPayment';
import ShipmentTypeEnum from '../enums/shipmentType.enum';

function ThirdPartyShipmentReview({
  thirdPartyShipmentId,
  commercialInvoiceRequirements,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [thirdPartyShipment, setThirdPartyShipment] = useState();
  const [isNational, setIsNational] = useState(null);
  const [selectedCourier, setSelectedCourier] = useState({});
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const language = useSelector((store) => store.Session.language);

  const getThirdPartyShipment = async () => {
    setLoading(true);
    try {
      const thirdPartyShipmentResponse =
        await thirdPartyShipmentAPI.getThirdPartyShipmentById(
          thirdPartyShipmentId
        );
      setThirdPartyShipment(thirdPartyShipmentResponse.data);
      setIsNational(
        thirdPartyShipmentResponse.data.warehouse.address.countryCode ===
          thirdPartyShipmentResponse.data.recipientAddress.countryCode
      );
      const courierResponse = await couriersApi.getCourierCredentials(false, 1);
      const data = courierResponse.data.results.map((value) => ({
        key: value.id,
        name: value.courierCompanyName,
        accountNumber: value.accountNumber,
      }));
      const actualCourier = data.find(
        (courier) =>
          courier.name ===
          thirdPartyShipmentResponse.data.courierShipment.courierCompanyName
      );
      setSelectedCourier(actualCourier);
      const actualDate = new Date(thirdPartyShipmentResponse.data.createdAt)
        .toLocaleDateString(language, {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })
        .toString()
        .toUpperCase();
      setName(`[${t('shipments.nameTypes.ThirdPartyShipment')}] ${actualDate}`);
      setDate(actualDate);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Error al obtener orden de envío a terceros.',
      });
    }
    setLoading(false);
  };

  useEffect(async () => {
    getThirdPartyShipment();
  }, []);

  return (
    <div id="boxes" className="text-align-left">
      <Spin spinning={loading}>
        <PendingShipmentPayment
          courierShipments={
            thirdPartyShipment?.courierShipment
              ? [thirdPartyShipment.courierShipment]
              : []
          }
          setOrder={setThirdPartyShipment}
        />
        <Row style={{ fontSize: '24px', fontWeight: 500 }}>
          {t('orders.shipment.summary.title2')}
        </Row>
        <Row
          style={{ fontSize: '16px', marginBottom: '20px' }}
          justify="space-between"
        >
          <Col>{name}</Col>
          <Col>
            <Row>
              <ShipmentTypeTag
                text={t('shipments.types.thirdPartyShipments')}
                icon={<TeamOutlined />}
              />
              <Divider type="vertical" />
              {date}
              <Divider type="vertical" />
              <ShipmentStatusTag
                status={
                  [
                    courierShipmentStatus.PENDING_COLLECTION_METHOD,
                    courierShipmentStatus.PENDING_PAYMENT,
                  ].includes(thirdPartyShipment?.courierShipment?.status)
                    ? thirdPartyShipment?.courierShipment?.status
                    : thirdPartyShipment?.status
                }
                type="ThirdPartyShipment"
              />
            </Row>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={24} style={{ marginBottom: '20px' }}>
            <InformationBox
              icon={
                <ExclamationCircleTwoTone twoToneColor="rgb(250, 173, 20, .5)" />
              }
              textInformationBox={
                <div style={{ width: '100%' }}>
                  <Typography.Paragraph className="w-600">
                    {t('orders.shipment.summary.successfullShipment.title')}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="icon-color">
                    <CheckOutlined />{' '}
                    {thirdPartyShipment?.isPalletized
                      ? t(
                          'orders.shipment.summary.successfullShipment.thirdPartyShipmentPalletLabel'
                        )
                      : t(
                          'orders.shipment.summary.successfullShipment.thirdPartyShipmentBoxLabel'
                        )}
                  </Typography.Paragraph>
                </div>
              }
              status="warning"
              display="block"
            />
          </Col>
          <Col span={24}>
            <ReviewDetailContent
              selectedCourier={selectedCourier}
              name={name}
              destination={thirdPartyShipment?.recipientAddress.countryCode}
              warehouse={thirdPartyShipment?.warehouse}
              shipmentType="Tercero"
              isNational={isNational}
              cargoType={
                thirdPartyShipment?.isPalletized ? 'Paletizada' : 'Individual'
              }
            />
          </Col>
          <Col span={24}>
            <ReviewBoxes
              thirdPartyShipment={thirdPartyShipment}
              isNational={isNational}
            />
          </Col>
          <Col span={24}>
            <ReviewShipment
              courierShipments={
                thirdPartyShipment?.courierShipment
                  ? [thirdPartyShipment.courierShipment]
                  : []
              }
              selectedCourier={selectedCourier}
              boxes={thirdPartyShipment?.boxes}
              getOrder={getThirdPartyShipment}
              setOrder={setThirdPartyShipment}
              warehouse={thirdPartyShipment?.warehouse}
              typeOfOrder={ShipmentTypeEnum.THIRDPARTYSHIPMENT}
              orderId={thirdPartyShipmentId}
            />
          </Col>
          <Col span={24}>
            <ReviewLabelsDocuments
              commercialInvoiceRequirements={commercialInvoiceRequirements}
              courierShipments={
                thirdPartyShipment?.courierShipment
                  ? [thirdPartyShipment.courierShipment]
                  : []
              }
              setOrder={setThirdPartyShipment}
              isNational={isNational}
              fdaProducts={[{}]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

ThirdPartyShipmentReview.propTypes = {
  thirdPartyShipmentId: PropTypes.string.isRequired,
  commercialInvoiceRequirements: PropTypes.shape({
    electronicDocumentsAllowed: PropTypes.bool,
    documentGeneration: PropTypes.bool,
    letterheadRequired: PropTypes.bool,
    signatureRequired: PropTypes.bool,
  }).isRequired,
};

export default ThirdPartyShipmentReview;
