import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Col,
  Collapse,
  Card,
  Image,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { DownOutlined, PictureOutlined, UpOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { forEach } from 'lodash';

function ReviewBoxes({ order, isNational, thirdPartyShipment }) {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState('');
  const [palletDataSource, setPalletDataSource] = useState([]);
  const [boxProductDataSource, setBoxProductDataSource] = useState([]);
  const [measureUnit, setMeasureUnit] = useState('CM');
  const [totalBoxes, setTotalBoxes] = useState(0);
  const [totalPallets, setTotalPallets] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalFOB, setTotalFOB] = useState(0);

  const { Panel } = Collapse;
  const expandIcon = ({ isActive }) =>
    isActive ? (
      <DownOutlined className="icon-title" />
    ) : (
      <UpOutlined className="icon-title" />
    );

  const renderName = (text) => {
    return (
      <Row align="middle" justify="start">
        <Col span={24}>
          <Space>
            {text?.productTemplate?.image2 ? (
              <Avatar
                shape="square"
                className="avatar-picture"
                src={<Image src={`${text?.productTemplate?.image2}`} />}
              />
            ) : (
              <Avatar
                shape="square"
                className="avatar-picture"
                icon={<PictureOutlined />}
              />
            )}
            <Row>
              <Col span={24}>
                <Tooltip placement="topLeft" title={text?.title}>
                  <Typography.Text className="title-product-contents-table">
                    {text?.title}
                  </Typography.Text>
                </Tooltip>
              </Col>
              <Col span={24} className="SKU-product-contents-table">
                <Tooltip title={text?.title}>SKU: {text?.defaultCode}</Tooltip>
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
    );
  };
  const renderBoxSize = (text) => {
    return (
      <Row>
        <Col
          align="middle"
          justify="center"
          style={{ textAlign: 'center' }}
          span={24}
        >
          {text?.name}
        </Col>
        <Col
          align="middle"
          justify="center"
          style={{ textAlign: 'center', fontSize: 12 }}
          span={24}
        >
          ({text?.length} x {text?.width} x {text?.height} {measureUnit})
        </Col>
      </Row>
    );
  };
  const boxProductColumns = [
    {
      title: t('common.product', { count: 1 }),
      dataIndex: 'product',
      key: 'product',
      width: '30%',
      render: renderName,
    },
    {
      title: t('orders.shipment.summary.numberOfBoxes'),
      dataIndex: 'boxesQuantity',
      key: 'boxesQuantity',
      width: '17.5%',
      align: 'center',
    },
    {
      title: t('orders.shipment.summary.unitsPerBox'),
      dataIndex: 'unitPerBoxes',
      key: 'unitPerBoxes',
      width: '17.5%',
      align: 'center',
    },
    {
      title: t('orders.shipment.summary.boxSize'),
      dataIndex: 'boxSize',
      key: 'boxSize',
      width: '17.5%',
      render: renderBoxSize,
      align: 'center',
    },
    {
      title: t('orders.shipment.summary.boxUnitWeight'),
      dataIndex: 'boxUnitWeight',
      key: 'boxUnitWeight',
      width: '17.5%',
      align: 'center',
    },
  ];

  const palletColumns = [
    {
      title: `# ${
        thirdPartyShipment && !thirdPartyShipment.isPalletized
          ? t('common.box')
          : 'Pallet'
      }`,
      dataIndex: 'palletNumber',
      key: 'palletNumber',
    },
    {
      title: t('common.length'),
      dataIndex: 'length',
      key: 'length',
    },
    {
      title: t('common.width'),
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: t('common.height'),
      dataIndex: 'height',
      key: 'height',
    },
    {
      title: t('common.weight'),
      dataIndex: 'weight',
      key: 'weight',
    },
  ];

  const onChangeTab = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (order && Object.keys(order).length !== 0) {
      setActiveKey(order.shipmentPlan.InboundShipmentPlans[0].ShipmentId);
    }
  }, [order]);

  useEffect(() => {
    if (thirdPartyShipment && Object.keys(thirdPartyShipment).length !== 0) {
      const actualMeasureUnit = thirdPartyShipment.boxes[0].box.measureUnit;
      setMeasureUnit(actualMeasureUnit);
      setActiveKey(thirdPartyShipment.id.toString());
      const boxesPalletsDataSource = [];
      if (thirdPartyShipment.boxes.length > 0) {
        forEach(thirdPartyShipment.boxes, (box, index) => {
          boxesPalletsDataSource.push({
            palletNumber: index + 1,
            length: `${box.box.length} ${measureUnit}`,
            width: `${box.box.width} ${measureUnit}`,
            height: `${box.box.height} ${measureUnit}`,
            weight: `${box.weight} ${measureUnit === 'CM' ? 'KG' : 'LB'}`,
          });
        });
      }
      if (thirdPartyShipment.isPalletized) {
        setTotalPallets(thirdPartyShipment.boxes.length);
        setPalletDataSource(boxesPalletsDataSource);
      } else {
        setPalletDataSource(boxesPalletsDataSource);
        setTotalBoxes(thirdPartyShipment.boxes.length);
      }
      setTotalWeight(
        `${thirdPartyShipment.boxes.reduce(
          (acumulador, box) => acumulador + Number(box.weight),
          0
        )} ${actualMeasureUnit === 'CM' ? 'KG' : 'LB'}`
      );
      setTotalFOB(
        thirdPartyShipment.products.reduce(
          (acumulador, product) =>
            acumulador + product.quantity * product.unitFOB,
          0
        )
      );
    }
  }, [thirdPartyShipment]);

  useEffect(() => {
    if (order && Object.keys(order).length !== 0 && activeKey) {
      const filteredRestockOrderBoxes = order.restockOrderBoxes.filter(
        (restockOrderBox) => restockOrderBox.shipmentId === activeKey
      );
      const actualProductBox = [];
      filteredRestockOrderBoxes.forEach((box) => {
        box.products.forEach((product) => {
          actualProductBox.push({
            product: product.product,
            boxesQuantity: box.shippingAmount,
            unitPerBoxes: product.quantity,
            boxSize: box.box,
            boxUnitWeight: `${box.weight} ${
              measureUnit === 'CM' ? 'KG' : 'LB'
            }`,
          });
        });
      });
      setBoxProductDataSource(actualProductBox);
      setTotalBoxes(
        filteredRestockOrderBoxes.reduce(
          (acumulador, box) => acumulador + box.shippingAmount,
          0
        )
      );
      setTotalWeight(
        `${filteredRestockOrderBoxes.reduce(
          (acumulador, box) => acumulador + Number(box.weight),
          0
        )} ${
          filteredRestockOrderBoxes[0].box.measureUnit === 'CM' ? 'KG' : 'LB'
        }`
      );
      setTotalFOB(
        filteredRestockOrderBoxes.reduce(
          (acumulador, box) =>
            acumulador +
            box.products.reduce(
              (fobAcumulator, product) =>
                fobAcumulator + product.product.productTemplate.unitFOB,
              0
            ),
          0
        )
      );
      setMeasureUnit(filteredRestockOrderBoxes[0].box.measureUnit);
      const actualPallets = [];
      if (order.pallets && order.pallets.length > 0) {
        const filteredRestockOrderPallets = order.pallets.find(
          (restockOrderBox) => restockOrderBox.shipmentId === activeKey
        );
        forEach(filteredRestockOrderPallets.pallets, (pallet, index) => {
          actualPallets.push({
            palletNumber: index + 1,
            length: `${pallet.length} ${measureUnit}`,
            width: `${pallet.width} ${measureUnit}`,
            height: `${pallet.height} ${measureUnit}`,
            weight: `${pallet.weight} ${measureUnit === 'CM' ? 'KG' : 'LB'}`,
          });
        });
        setTotalPallets(filteredRestockOrderPallets.pallets.length);
        setPalletDataSource(actualPallets);
      }
    }
  }, [order, activeKey]);

  return (
    <Collapse
      className="revision-restock"
      expandIcon={expandIcon}
      defaultActiveKey={['Boxes']}
    >
      <Panel
        key="Boxes"
        header={
          <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
            {t('orders.newOrder.boxes.titleForm')}
          </Typography.Text>
        }
      >
        <Tabs type="card" activeKey={activeKey} onChange={onChangeTab}>
          {order?.shipmentPlan?.InboundShipmentPlans?.map(
            (shipmentPlan, index) => (
              <Tabs.TabPane
                tab={`${t('common.destination')} N° ${index + 1}`}
                key={`${shipmentPlan.ShipmentId}`}
              >
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <Card
                      size="small"
                      title="Contenido"
                      style={{ borderRadius: 4 }}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={4}>
                          <Card
                            size="small"
                            style={{ borderLeft: '4px solid #4D81E5' }}
                          >
                            <p
                              style={{
                                color: '#646464',
                                marginBottom: 6,
                              }}
                            >
                              {t('common.product', { count: 2 })}
                            </p>
                            {shipmentPlan.Items.length}
                          </Card>
                        </Col>
                        <Col span={4}>
                          <Card
                            size="small"
                            style={{ borderLeft: '4px solid #4D81E5' }}
                          >
                            <p
                              style={{
                                color: '#646464',
                                marginBottom: 6,
                              }}
                            >
                              {t('common.units')}
                            </p>
                            {shipmentPlan.Items.reduce(
                              (acumulador, item) => acumulador + item.Quantity,
                              0
                            )}
                          </Card>
                        </Col>
                        <Col span={4}>
                          <Card
                            size="small"
                            style={{ borderLeft: '4px solid #4D81E5' }}
                          >
                            <p
                              style={{
                                color: '#646464',
                                marginBottom: 6,
                              }}
                            >
                              {t('common.boxes')}
                            </p>
                            {totalBoxes}
                          </Card>
                        </Col>
                        {totalPallets > 0 && (
                          <Col span={4}>
                            <Card
                              size="small"
                              style={{ borderLeft: '4px solid #4D81E5' }}
                            >
                              <p
                                style={{
                                  color: '#646464',
                                  marginBottom: 6,
                                }}
                              >
                                Pallets
                              </p>
                              {totalPallets}
                            </Card>
                          </Col>
                        )}
                        <Col span={4}>
                          <Card
                            size="small"
                            style={{ borderLeft: '4px solid #4D81E5' }}
                          >
                            <p
                              style={{
                                color: '#646464',
                                marginBottom: 6,
                              }}
                            >
                              {t('common.weight')}
                            </p>
                            {totalWeight}
                          </Card>
                        </Col>
                        <Col span={4}>
                          {!isNational && (
                            <Card
                              size="small"
                              style={{ borderLeft: '4px solid #4D81E5' }}
                            >
                              <p
                                style={{
                                  color: '#646464',
                                  marginBottom: 6,
                                }}
                              >
                                FOB
                              </p>
                              {totalFOB}
                            </Card>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      size="small"
                      title={t(
                        'orders.shipment.summary.boxProductConfiguration'
                      )}
                      style={{ borderRadius: 4 }}
                    >
                      <Table
                        dataSource={boxProductDataSource}
                        columns={boxProductColumns}
                        pagination={{ position: ['none', 'none'] }}
                      />
                    </Card>
                  </Col>
                  {totalPallets > 0 && (
                    <Col span={24}>
                      <Card
                        size="small"
                        title={t('orders.shipment.summary.palletConfiguration')}
                        style={{ borderRadius: 4 }}
                      >
                        <Table
                          dataSource={palletDataSource}
                          columns={palletColumns}
                          pagination={{ position: ['none', 'none'] }}
                        />
                      </Card>
                    </Col>
                  )}
                </Row>
              </Tabs.TabPane>
            )
          )}
          {thirdPartyShipment && (
            <Tabs.TabPane
              tab={`${t('common.destination')} N° 1`}
              key={thirdPartyShipment.id.toString()}
            >
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Card
                    size="small"
                    title={t('common.content')}
                    style={{ borderRadius: 4 }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={4}>
                        <Card
                          size="small"
                          style={{ borderLeft: '4px solid #4D81E5' }}
                        >
                          <p
                            style={{
                              color: '#646464',
                              marginBottom: 6,
                            }}
                          >
                            {t('common.product', { count: 2 })}
                          </p>
                          {thirdPartyShipment.products.length}
                        </Card>
                      </Col>
                      <Col span={4}>
                        <Card
                          size="small"
                          style={{ borderLeft: '4px solid #4D81E5' }}
                        >
                          <p
                            style={{
                              color: '#646464',
                              marginBottom: 6,
                            }}
                          >
                            {t('common.units')}
                          </p>
                          {thirdPartyShipment.products.reduce(
                            (acumulador, product) =>
                              acumulador + product.quantity,
                            0
                          )}
                        </Card>
                      </Col>
                      <Col
                        span={4}
                        hidden={
                          thirdPartyShipment && thirdPartyShipment.isPalletized
                        }
                      >
                        <Card
                          size="small"
                          style={{ borderLeft: '4px solid #4D81E5' }}
                        >
                          <p
                            style={{
                              color: '#646464',
                              marginBottom: 6,
                            }}
                          >
                            {t('common.boxes')}
                          </p>
                          {totalBoxes}
                        </Card>
                      </Col>
                      {totalPallets > 0 && (
                        <Col span={4}>
                          <Card
                            size="small"
                            style={{ borderLeft: '4px solid #4D81E5' }}
                          >
                            <p
                              style={{
                                color: '#646464',
                                marginBottom: 6,
                              }}
                            >
                              Pallets
                            </p>
                            {totalPallets}
                          </Card>
                        </Col>
                      )}
                      <Col span={4}>
                        <Card
                          size="small"
                          style={{ borderLeft: '4px solid #4D81E5' }}
                        >
                          <p
                            style={{
                              color: '#646464',
                              marginBottom: 6,
                            }}
                          >
                            {t('common.weight')}
                          </p>
                          {totalWeight}
                        </Card>
                      </Col>
                      {!isNational && (
                        <Col span={4}>
                          <Card
                            size="small"
                            style={{ borderLeft: '4px solid #4D81E5' }}
                          >
                            <p
                              style={{
                                color: '#646464',
                                marginBottom: 6,
                              }}
                            >
                              FOB
                            </p>
                            {totalFOB}
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card
                    size="small"
                    title={
                      thirdPartyShipment.isPalletized
                        ? t('orders.shipment.summary.palletConfiguration')
                        : t('orders.shipment.summary.boxConfiguration')
                    }
                    style={{ borderRadius: 4 }}
                  >
                    <Table
                      dataSource={palletDataSource}
                      columns={palletColumns}
                      pagination={{ position: ['none', 'none'] }}
                    />
                  </Card>
                </Col>
              </Row>
            </Tabs.TabPane>
          )}
        </Tabs>
      </Panel>
    </Collapse>
  );
}

ReviewBoxes.propTypes = {
  order: PropTypes.shape({
    shipmentPlan: PropTypes.shape({
      InboundShipmentPlans: PropTypes.arrayOf(
        PropTypes.shape({
          ShipmentId: PropTypes.string,
          Items: PropTypes.arrayOf(
            PropTypes.shape({
              Quantity: PropTypes.number,
            })
          ),
        })
      ),
    }),
    restockOrderBoxes: PropTypes.arrayOf(
      PropTypes.shape({
        box: PropTypes.shape({
          length: PropTypes.number,
          width: PropTypes.number,
          height: PropTypes.number,
        }),
        weight: PropTypes.number,
        products: PropTypes.arrayOf(
          PropTypes.shape({
            product: PropTypes.shape({
              productTemplate: PropTypes.shape({
                image2: PropTypes.string,
                unitFOB: PropTypes.number,
              }),
              title: PropTypes.string,
              defaultCode: PropTypes.string,
            }),
            quantity: PropTypes.number,
          })
        ),
      })
    ).isRequired,
    isPalletized: PropTypes.bool,
    pallets: PropTypes.arrayOf(Object),
  }),
  isNational: PropTypes.bool.isRequired,
  thirdPartyShipment: PropTypes.shape({
    id: PropTypes.number,
    boxes: PropTypes.arrayOf(
      PropTypes.shape({
        box: PropTypes.shape({
          length: PropTypes.number,
          width: PropTypes.number,
          height: PropTypes.number,
          measureUnit: PropTypes.string,
        }),
        weight: PropTypes.number,
      })
    ),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        unitFOB: PropTypes.number,
      })
    ),
    isPalletized: PropTypes.bool,
    pallets: PropTypes.arrayOf(Object),
  }),
};

ReviewBoxes.defaultProps = {
  order: null,
  thirdPartyShipment: null,
};

export default ReviewBoxes;
