import PropTypes from 'prop-types';
import { Card, Tag, Row, Col, Radio, Typography, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

function ShipmentTypeAndPriceCard({
  tag,
  courier,
  title,
  date,
  totalCost,
  discountCost,
  currency,
  cardKey,
  checked,
  onChange,
  rate,
}) {
  const { t } = useTranslation();

  const parseCurrency = (currencyCode) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: currencyCode,
    });
  };

  const collapseHeader = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {discountCost && (
        <div
          className="shipment-options-card-discountCost"
          style={{ display: 'flex', justifyContent: 'space-between', gap: 16 }}
        >
          <span
            style={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              flex: 1,
            }}
          >
            {t('shipmentOptions.totalServiceCost', { courier })}
          </span>
          <span>{parseCurrency(currency).format(discountCost)}</span>
        </div>
      )}
      <div
        style={{ display: 'flex', justifyContent: 'space-between', gap: 16 }}
        className="shipment-options-card-cost"
      >
        <span
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            flex: 1,
          }}
        >
          {discountCost
            ? t('shipmentOptions.totalLAPCost')
            : t('shipmentOptions.totalServiceCost', { courier })}
        </span>
        <span>{parseCurrency(currency).format(totalCost)}</span>
      </div>
    </div>
  );

  const priceDetail = rate?.map((r) => (
    <Row>
      <Col
        span={14}
        style={{
          fontSize: 13,
          width: '100%',
          color: '#010C33',
          fontWeight: 500,
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        }}
      >
        {r.name}
      </Col>
      <Col span={10} style={{ display: 'flex', justifyContent: 'right' }}>
        {`${parseCurrency(currency).format(r.value)}`}
      </Col>
    </Row>
  ));

  return (
    <Card
      className="shipment-options-card"
      style={
        checked
          ? {
              border: '1px solid rgba(0, 229, 166, 1)',
              backgroundColor: 'rgba(0, 229, 166, 0.05)',
              borderRadius: 4,
            }
          : { borderRadius: 4 }
      }
    >
      <Row align="middle" wrap={false}>
        <Col flex="20px" style={{ marginRight: 10 }}>
          <Radio value={cardKey} checked={checked} onChange={onChange} />
        </Col>
        <Col flex="auto">
          <Row wrap>
            <Col span={14} style={{ display: 'flex' }}>
              <Row align="middle" justify="space-between">
                {tag && (
                  <Col span={24}>
                    <Tag
                      color="rgba(0, 229, 166, 0.5)"
                      className="shipment-options-card-tag"
                    >
                      {tag}
                    </Tag>
                  </Col>
                )}
                <Col span={24}>
                  <Typography.Title className="shipment-options-card-title">
                    {title}
                  </Typography.Title>
                </Col>
                {date && (
                  <Col span={24}>
                    <Typography.Title className="shipment-options-card-date">
                      {date}
                    </Typography.Title>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={10}>
              <Row style={{ justifyContent: 'end' }}>
                <Tag
                  color="rgba(250, 250, 250, 1)"
                  className="shipment-options-card-priceTag"
                  style={{ width: '100%' }}
                >
                  <Collapse ghost>
                    <Collapse.Panel header={collapseHeader}>
                      {priceDetail}
                    </Collapse.Panel>
                  </Collapse>
                </Tag>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

ShipmentTypeAndPriceCard.propTypes = {
  tag: PropTypes.string,
  courier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  totalCost: PropTypes.string.isRequired,
  discountCost: PropTypes.string,
  cardKey: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  rate: PropTypes.arrayOf(Object).isRequired,
};

ShipmentTypeAndPriceCard.defaultProps = {
  tag: null,
  date: null,
  discountCost: null,
};

export default ShipmentTypeAndPriceCard;
