import { DualAxes } from '@ant-design/plots';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatDateStringForTooltips,
  thousandsSeparator,
} from '../../../utils/functions';
import selectors from '../../../redux/analytic/dashboard/selectors';

function DualAxesChart({ dataSource }) {
  const language = useSelector((store) => store.Session.language);
  const { t } = useTranslation();
  const columnData = dataSource?.columnDataSource || [];
  const lineData = dataSource?.lineDataSource || [];
  const currencyState = useSelector(selectors.selectCurrency);

  const config = {
    data: [columnData, lineData],
    xField: 'month',
    yField: ['value', 'value'],
    yAxis: {
      value: {
        label: null,
        grid: null,
      },
    },
    xAxis: {
      label: {
        formatter: (month) => {
          moment.locale('es');
          if (month?.length === 7) {
            return moment(month).format('MMM');
          }
          return moment(month).format('D MMM');
        },
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'top-right',
      selected: {
        investment: true,
        organicSales: true,
        salesPerAd: true,
        TACOS: false,
        'Ads %': false,
        totalSales: false,
      },
    },
    geometryOptions: [
      {
        geometry: 'column',
        isGroup: true,
        isStack: true,
        seriesField: 'type',
        groupField: 'name',
        marginRatio: 0,
        color: ['#C4C4C433', '#00E5A6', '#D9D9D9', '#010C33'],
      },
      {
        geometry: 'line',
        seriesField: 'name',
        isStack: true,
        color: ['#969696', '#85B6FF80'],
      },
    ],
    tooltip: {
      customItems: (originalItems) => {
        return originalItems.map((tooltipRow) => {
          let value = 0;
          if (
            tooltipRow.name.includes('investment') ||
            tooltipRow.name.includes('sales')
          ) {
            value = `${thousandsSeparator(
              parseInt(tooltipRow.data.value, 10)
            )} ${currencyState}`;
          } else {
            value = `${thousandsSeparator(
              parseInt(tooltipRow.data.value, 10)
            )}%`;
          }
          return {
            ...tooltipRow,
            name: t(
              `marketing.indexGraphics.${tooltipRow.name}.title`,
              tooltipRow.name
            ),
            value,
          };
        });
      },
      title: (xValue) => formatDateStringForTooltips(xValue, language),
    },
  };

  return (
    <DualAxes
      data={config.data}
      xField={config.xField}
      yField={config.yField}
      yAxis={config.yAxis}
      xAxis={config.xAxis}
      legend={config.legend}
      geometryOptions={config.geometryOptions}
      tooltip={config.tooltip}
    />
  );
}

export default React.memo(DualAxesChart);

DualAxesChart.propTypes = {
  dataSource: PropTypes.instanceOf(Object).isRequired,
};
