import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'antd/dist/antd.min.css';
import openNotification from '../../components/Toastr';
import ShipmentTypeAndPriceCard from './components/ShipmentTypeAndPriceCard';
import { getErrorMessage } from '../../api/api';
import shipmentsAPI from '../../api/shipments';

function ServiceSelect({
  shipments,
  setRatesAndTransitTimesPayload,
  setLoadingRates,
  setRates,
  setRate,
  selectedService,
  setSelectedService,
  courier,
}) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.Session.language);
  const [services, setServices] = useState({});

  useEffect(() => {
    const getOrderRates = async () => {
      setSelectedService(undefined);
      setLoadingRates(true);
      let result = [];

      let promises = shipments.map(async (shipment) => {
        const payload = setRatesAndTransitTimesPayload(shipment);
        return shipmentsAPI.getShipmentRates(payload);
      });

      try {
        const responses = await Promise.all(promises);
        result = responses.map((r) => r.data);
        setRates(result);
      } catch (error) {
        openNotification({ status: false, content: getErrorMessage(error) });
      } finally {
        setLoadingRates(false);
      }
      if (!result?.length) return;
      const servicesByShipment = result.map((r) => {
        return new Set(r.map((o) => o.serviceName));
      });
      const commonServices = servicesByShipment
        ? Array.from(
            servicesByShipment.reduce(
              (intersection, set) =>
                new Set([...intersection].filter((item) => set.has(item))),
              servicesByShipment[0]
            )
          )
        : [];
      const newServices = {};
      promises = [];
      commonServices.forEach((commonService) => {
        const promise = new Promise((resolve) => {
          const commonServiceRates = {
            name: commonService,
            totalRate: 0,
            totalRateWithoutDiscount: 0,
            rate: [],
            totalNetRate: 0,
            totalNetRateWithoutDiscount: 0,
            discount: 0,
          };
          result.forEach((optionsByShipment) => {
            const matchingOption = optionsByShipment.find(
              (option) => option.serviceName === commonService
            );
            commonServiceRates.currency = matchingOption.currency;
            if (!commonServiceRates.estimatedDeliveryDate) {
              const matchingDate = matchingOption.estimatedDeliveryDate;
              commonServiceRates.estimatedDeliveryDate = matchingDate
                ? new Date(matchingDate)
                : null;
            }
            commonServiceRates.totalRate += matchingOption.totalRate;
            commonServiceRates.totalRateWithoutDiscount +=
              matchingOption.totalRateWithoutDiscount.value;
            commonServiceRates.rate.push(...matchingOption.rate);
            const netRate = matchingOption.rate.find((r) => r.type === 'NET');
            if (netRate) {
              commonServiceRates.totalNetRate += netRate.value;
              commonServiceRates.totalNetRateWithoutDiscount +=
                netRate.value /
                (1 - matchingOption.totalRateWithoutDiscount.discount);
            }
          });
          commonServiceRates.discount = (
            ((commonServiceRates.totalNetRateWithoutDiscount -
              commonServiceRates.totalNetRate) /
              commonServiceRates.totalNetRateWithoutDiscount) *
            100
          ).toFixed(2);
          commonServiceRates.rate = Object.values(
            commonServiceRates.rate.reduce((acc, item) => {
              if (!acc[item.type]) {
                acc[item.type] = { ...item };
              } else {
                acc[item.type].value += item.value;
              }
              return acc;
            }, {})
          );
          newServices[commonService] = commonServiceRates;
          resolve();
        });
        promises.push(promise);
      });
      await Promise.all(promises);
      setServices(newServices);
    };
    getOrderRates();
  }, []);

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{ width: '100%', marginTop: 15 }}
    >
      {Object.entries(services).map(([key, option]) => (
        <ShipmentTypeAndPriceCard
          key={key}
          courier={courier}
          tag={option.discount > 0 ? `${option.discount} % OFF` : null}
          title={option.name}
          date={`${t('common.estimatedDateOfDelivery')}: ${
            option.estimatedDeliveryDate
              ? option.estimatedDeliveryDate.toLocaleDateString(language)
              : '-'
          }`}
          totalCost={option.totalRate}
          currency={option.currency}
          discount={option.discount / 100}
          discountCost={
            option.discount > 0 ? option.totalRateWithoutDiscount : null
          }
          cardKey={key}
          checked={selectedService === key}
          onChange={(e) => {
            setSelectedService(e.target.value);
            setRate(
              option.rate.map((r) => ({
                type: r.type,
                value: r.value,
                currency: option.currency,
              }))
            );
          }}
          rate={option.rate}
        />
      ))}
    </Space>
  );
}

ServiceSelect.propTypes = {
  shipments: PropTypes.arrayOf(Object).isRequired,
  setLoadingRates: PropTypes.func.isRequired,
  setRates: PropTypes.func.isRequired,
  setRate: PropTypes.func,
  setRatesAndTransitTimesPayload: PropTypes.func.isRequired,
  selectedService: PropTypes.string.isRequired,
  setSelectedService: PropTypes.func.isRequired,
  courier: PropTypes.string.isRequired,
};

ServiceSelect.defaultProps = {
  setRate: () => {},
};

export default ServiceSelect;
