import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { courierShipmentStatus } from '../../../../../utils/const';
import paymentPlanAPI from '../../../../../api/payment-plan';
import openNotification from '../../../../../components/Toastr';
import { getErrorMessage } from '../../../../../api/api';

function PendingShipmentPayment({ courierShipments, setOrder }) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const stripeCheckoutSessionId = searchParams?.get('session_id');
  const prevShipmentIdsRef = useRef(new Set());

  const importShipmentPayment = async () => {
    const payload = {};
    if (stripeCheckoutSessionId) {
      payload.sessionId = stripeCheckoutSessionId;
    } else if (courierShipments?.length > 0) {
      payload.courierShipmentIds = courierShipments.map((i) => i.id);
    }
    if (!Object.keys(payload).length) return;
    try {
      const { data } = await paymentPlanAPI.importShipmentPayment(payload);
      setOrder((prevOrder) => ({
        ...prevOrder,
        ...(prevOrder.courierShipment && { courierShipment: data?.[0] }),
        ...(prevOrder.courierShipments && { courierShipments: data }),
      }));
    } catch (error) {
      openNotification({
        info: t('common.warning'),
        status: 'warning',
        content: getErrorMessage(error),
      });
    }
  };

  useEffect(async () => {
    if (!courierShipments || !courierShipments.length) return;
    const pendingPayments = courierShipments.filter(
      (cs) => cs.status === courierShipmentStatus.PENDING_PAYMENT
    );
    if (!pendingPayments.length) return;
    const newShipmentIds = new Set(pendingPayments.map((i) => i.id));
    const hasNewShipments = [...newShipmentIds].some(
      (id) => !prevShipmentIdsRef.current.has(id)
    );
    if (!hasNewShipments) return;
    prevShipmentIdsRef.current = newShipmentIds;
    await importShipmentPayment();
  }, [courierShipments]);

  return null;
}

PendingShipmentPayment.propTypes = {
  courierShipments: PropTypes.arrayOf(Object).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default PendingShipmentPayment;
