import React, { useEffect, useRef, useState } from 'react';
import {
  Affix,
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import {
  DownloadOutlined,
  InfoCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import 'antd/dist/antd.min.css';
import { useTranslation } from 'react-i18next';
import { validateMessages } from '../../../../../../utils/const';
import FedexFbmProductTable from '../components/FedexFbmProductTable';
import boxApi from '../../../../../../api/box';
import orderSalesApi from '../../../../../../api/order-sales';
import FedexFbmBoxTable from '../components/FedexFbmBoxTable';
import openNotification from '../../../../../../components/Toastr';
import productApi from '../../../../../../api/product';
import FBMCouriers from '../../../../../../utils/FBMCouriers';
import CreateBoxModal from '../components/CreateBoxModal';

function ProductBoxStep({
  orders,
  setActualStep,
  setData,
  warehouseId,
  courierCredentials,
  initialUnitMeasure,
  shipmentType,
  isPalletized,
  products,
  setProducts,
}) {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const { Item } = Form;
  const [radioDefault, setRadioDefault] = useState(false);
  const [unitMeasure, setUnitMeasurement] = useState(
    initialUnitMeasure || 'CM'
  );
  const [boxes, setBoxes] = useState([]);
  const [showCreateBoxModal, setShowCreateBoxModal] = useState(false);
  const [loadingDraft, setloadingDraft] = useState(false);
  const [initialBoxesNumber, setInitialBoxesNumber] = useState({});

  const [taxIdEnable, setTaxIdEnable] = useState(false);
  const taxIdOptions = [
    'PERSONAL_NATIONAL',
    'PERSONAL_STATE',
    'FEDERAL',
    'BUSINESS_NATIONAL',
    'BUSINESS_STATE',
    'BUSINESS_UNION',
  ];

  const mounted = useRef(false);

  const marginRadio = {
    marginBottom: 0,
  };

  const inputRadioActive = {
    border: '1px solid',
  };

  const step = 1;

  useEffect(async () => {
    try {
      const ordersIds = orders?.map((order) => {
        return order.id;
      });
      const response = await productApi.getBySalesOrdersId(ordersIds);
      setProducts(response.data);
      setData([]);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al cargar las ordenes',
      });
    }
  }, []);

  useEffect(() => {
    if (!initialUnitMeasure) {
      if (
        [FBMCouriers.FEDEX_US].includes(courierCredentials?.courierCompanyName)
      ) {
        setUnitMeasurement('IN');
        form.setFieldValue('unitMeasurement', 'IN');
      } else {
        form.setFieldValue('unitMeasurement', 'CM');
      }
    }
  }, [courierCredentials]);

  const syncProducts = async () => {
    const changeProducts = {};
    const formValues = form.getFieldsValue();
    products.forEach((product) => {
      changeProducts[product.id] = {
        weight: formValues[`weight-${product.defaultCode}`],
        weightUnity: formValues.weightUnity,
        productTemplate: {
          harmonizedCode: formValues[`harmonizedCode-${product.defaultCode}`],
          unitFOB: formValues[`fob-${product.defaultCode}`],
          countryOfManufacture:
            formValues[`countryOfManufacture-${product.defaultCode}`],
          weight: formValues[`weight-${product.defaultCode}`],
          weightUnity: formValues.weightUnity,
        },
      };
    });
    try {
      const response = await productApi.bulkUpdate(changeProducts);
      return response.status;
    } catch (error) {
      openNotification({
        status: false,
        content: t('orders.shipment.products.saveError'),
      });
    }
    return false;
  };

  const validateWeight = () => {
    const formValues = form.getFieldsValue();
    const correctWeight = orders.reduce((productBoxWeight, saleOrder) => {
      const productsWeight = saleOrder.orderItems.reduce(
        (accumulator, currentValue) => {
          const currentProduct = products.find(
            (product) => product.defaultCode === currentValue.sku
          );
          if (currentProduct)
            return (
              accumulator +
              formValues[`weight-${currentProduct.defaultCode}`] *
                currentValue.quantity
            );
          return accumulator;
        },
        0
      );
      const boxesWeight = formValues.boxes[saleOrder.id].reduce(
        (accumulator, currentValue) => accumulator + currentValue.weight,
        0
      );
      const validation = productBoxWeight && boxesWeight > productsWeight;
      if (!validation) {
        openNotification({
          status: false,
          content: `[${saleOrder.orderId}] ${t(
            'thirdPartyShipments.errors.productBoxWeight'
          )} ${productsWeight}`,
        });
      }
      return validation;
    }, true);
    return correctWeight;
  };

  const syncBoxes = async () => {
    const payload = [];
    orders?.forEach((saleOrder) => {
      const boxIndexes = Object.keys(form.getFieldValue().boxes[saleOrder.id]);
      const orderPayload = { id: saleOrder.id, boxes: [] };
      boxIndexes.forEach((boxIndex) => {
        const selectedBox = boxes.filter(
          (box) =>
            box.name === form.getFieldValue().boxes[saleOrder.id][boxIndex].name
        );
        if (selectedBox.length > 0) {
          orderPayload.boxes.push({
            weight: {
              value:
                form.getFieldValue().boxes[saleOrder.id][boxIndex].weight ||
                null,
            },
            id: selectedBox[0].id,
          });
        }
      });
      payload.push(orderPayload);
    });
    try {
      const response = await orderSalesApi.updateSaleOrderBoxes(payload);
      return response.status;
    } catch (error) {
      openNotification({
        status: false,
        content: t('orders.shipment.boxConfiguration.saveError'),
      });
    }
    return false;
  };

  const onFinish = async (formFields) => {
    const validWeight = validateWeight();
    const updateProductsStatus = await syncProducts();
    const updateBoxesStatus = await syncBoxes();
    if (
      updateProductsStatus === 200 &&
      updateBoxesStatus === 200 &&
      validWeight
    ) {
      const shipments = orders?.map((order) => {
        const formBoxes = [];
        if (formFields.boxes[order.id]?.length > 0) {
          formFields.boxes[order.id].forEach((box) => {
            formBoxes.push({
              weight: {
                units: unitMeasure === 'CM' ? 'KG' : 'LB',
                value: box.weight,
              },
              dimensions: {
                length: parseFloat(box.length),
                width: parseFloat(box.width),
                height: parseFloat(box.height),
                units: unitMeasure,
              },
            });
          });
        } else {
          openNotification({
            status: false,
            content: t('orders.shipment.boxConfiguration.addBoxesMessage'),
          });
        }
        return {
          saleOrderId: order.id,
          boxes: formBoxes,
          warehouseId,
          ...(taxIdEnable && {
            taxId: {
              number: formFields[`number_${order.id}`],
              tinType: formFields[`tinType_${order.id}`],
              usage: formFields[`usage_${order.id}`],
            },
          }),
        };
      });
      setData(shipments);
      setActualStep(1);
    }
  };

  const getBoxes = async () => {
    const newBoxes = await boxApi.getByCompanyId(unitMeasure, isPalletized);
    if (newBoxes.data) {
      setBoxes(newBoxes.data);
    }
  };

  const handleSendDraft = async () => {
    setloadingDraft(true);
    const updateProductsStatus = await syncProducts();
    const updateBoxesStatus = await syncBoxes();
    if (updateProductsStatus === 200 && updateBoxesStatus === 200) {
      openNotification({
        status: true,
        content: t('orders.shipment.successDraft'),
      });
    }
    setloadingDraft(false);
  };

  useEffect(() => {
    form.setFieldValue('unitMeasurement', initialUnitMeasure || 'CM');
  }, []);

  useEffect(() => {
    getBoxes();
  }, [unitMeasure]);

  useEffect(() => {
    if (!showCreateBoxModal && mounted.current) {
      getBoxes();
    } else if (!mounted.current) {
      mounted.current = true;
    }
  }, [showCreateBoxModal]);

  useEffect(() => {
    if (mounted.current) {
      const boxesFormData = {};
      const numberOfBoxes = {};
      orders?.forEach((order) => {
        if (order.boxes.length > 0) {
          const saleOrderBoxes = {};
          order.boxes.forEach((saleOrderBox) => {
            const selectedBox = boxes.filter(
              (box) => box.id === saleOrderBox.box.id
            );
            if (selectedBox.length > 0) {
              saleOrderBoxes[Object.keys(saleOrderBoxes).length] = {
                name: selectedBox[0].name,
                length: selectedBox[0].length,
                width: selectedBox[0].width,
                height: selectedBox[0].height,
                weight: saleOrderBox.weight,
              };
            }
          });
          boxesFormData[order.id] = saleOrderBoxes;
          numberOfBoxes[order.id] = Object.keys(saleOrderBoxes).length;
        }
      });
      setInitialBoxesNumber(numberOfBoxes);
      form.setFieldsValue({
        boxes: boxesFormData,
      });
    }
  }, [boxes]);

  return (
    <>
      <div style={{ justifyContent: 'center', display: 'flex' }}>
        <Card
          size="small"
          style={{
            width: 1000,
            background: '#010C330D',
          }}
        >
          <p style={{ fontWeight: 400 }}>
            <InfoCircleOutlined
              style={{
                fontSize: 24,
                position: 'relative',
                top: 4,
                marginRight: 10,
              }}
            />{' '}
            {t('orders.shipment.fbmConsideration')}:
          </p>
          <ul style={{ marginLeft: 20 }}>
            <li>
              {t('orders.notifySectionOne.message1')}{' '}
              <a
                target="_blank"
                href="https://ayuda.lapmarketplace.com/hc/es-cl/categories/12348948571803"
                rel="noreferrer"
              >
                {t('common.wantKnowMore')}
              </a>
            </li>
            <li>
              {t('orders.notifySectionOne.message2')}{' '}
              <a
                target="_blank"
                href="https://ayuda.lapmarketplace.com/hc/es-cl/sections/12540784099099"
                rel="noreferrer"
              >
                {t('common.wantKnowMore')}
              </a>
            </li>
            <li>
              {t('orders.notifySectionOne.message3')}{' '}
              <a
                target="_blank"
                href="https://ayuda.lapmarketplace.com/hc/es-cl/sections/12350130967067"
                rel="noreferrer"
              >
                {t('common.wantKnowMore')}
              </a>{' '}
            </li>
          </ul>
        </Card>
      </div>
      {step && (
        <div id="contents">
          <Form
            name="contentForm"
            form={form}
            validateMessages={validateMessages}
            className="text-align-left form-padding-top restockStepOne"
            scrollToFirstError
            onFinish={onFinish}
          >
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <h3 className="title-grey-box">
                  {t('orders.shipment.unitMeasurement.title')}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24}>
                <Item
                  className="input-form-margin-bottom"
                  name="unitMeasurement"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                  tooltip={{
                    title: t('orders.shipment.unitMeasurement.title'),
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Radio.Group
                    className="cardRadioGroupContent"
                    name="unitMeasurement"
                    onChange={(e) => {
                      setRadioDefault(true);
                      setUnitMeasurement(e.target.value);
                    }}
                  >
                    <Card
                      className="card-radio-inline cardBaseStyle"
                      style={radioDefault ? inputRadioActive : {}}
                    >
                      <div className="card-content-div">
                        <p className="text-card-radio" style={marginRadio}>
                          {t('orders.shipment.unitMeasurement.metric')}
                        </p>
                        <span className="span-card-radio">CM / KG</span>
                      </div>
                      <Radio className="positionRadioContent" value="CM" />
                    </Card>

                    <Card
                      className="card-radio-inline cardBaseStyle"
                      style={radioDefault ? inputRadioActive : {}}
                    >
                      <div className="card-content-div">
                        <p className="text-card-radio" style={marginRadio}>
                          {t('orders.shipment.unitMeasurement.imperial')}
                        </p>
                        <span className="span-card-radio">IN / LB</span>
                      </div>
                      <Radio className="positionRadioContent" value="IN" />
                    </Card>
                  </Radio.Group>
                </Item>
              </Col>
            </Row>
            <Col
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                marginTop: '3em',
              }}
            >
              <h3 className="title-grey-box">
                {t('orders.shipment.detailShipments')}
              </h3>
            </Col>
            <Collapse defaultActiveKey={orders?.map((order) => order.id)}>
              {orders?.map((order) => (
                <Panel
                  header={`${order.destinationAddress.city} - ID: ${order.orderId} `}
                  key={order.id}
                >
                  <Col
                    style={{
                      alignItems: 'flex-start',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                    }}
                  >
                    <h3 className="title-grey-box">
                      {t('orders.shipment.taxInformation.title')}
                    </h3>
                  </Col>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label={t('orders.shipment.taxInformation.enableForm')}
                      >
                        <Switch
                          checked={taxIdEnable}
                          onChange={setTaxIdEnable}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label={t('orders.shipment.taxInformation.number.label')}
                        name={`number_${order.id}`}
                        rules={[
                          { required: taxIdEnable },
                          {
                            max: 18,
                            message: t(
                              'orders.shipment.taxInformation.number.lengthError'
                            ),
                          },
                        ]}
                      >
                        <Input
                          maxLength={18}
                          placeholder={t(
                            'orders.shipment.taxInformation.number.placeholder'
                          )}
                          disabled={!taxIdEnable}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label={t('orders.shipment.taxInformation.type.label')}
                        name={`tinType_${order.id}`}
                      >
                        <Select
                          placeholder={t(
                            'orders.shipment.taxInformation.type.placeholder'
                          )}
                          disabled={!taxIdEnable}
                        >
                          {taxIdOptions.map((o) => (
                            <Select.Option value={o}>
                              {t(`orders.shipment.taxInformation.types.${o}`)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label={t('orders.shipment.taxInformation.usage.label')}
                        name={`usage_${order.id}`}
                      >
                        <Input
                          placeholder={t(
                            'orders.shipment.taxInformation.usage.placeholder'
                          )}
                          disabled={!taxIdEnable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Col
                    style={{
                      alignItems: 'flex-start',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                      marginTop: '3em',
                    }}
                  >
                    <h3 className="title-grey-box">
                      {t('orders.shipment.products.title')}
                    </h3>
                  </Col>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FedexFbmProductTable
                        form={form}
                        order={order}
                        products={products}
                        shipmentType={shipmentType}
                        unit={unitMeasure}
                      />
                    </Col>
                  </Row>
                  <Col
                    style={{
                      alignItems: 'flex-start',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                      marginTop: '3em',
                    }}
                  >
                    <h3 className="title-grey-box">
                      {t('orders.shipment.boxConfiguration.title')}
                    </h3>
                  </Col>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FedexFbmBoxTable
                        boxes={boxes}
                        form={form}
                        orderId={order.id}
                        setShowCreateBoxModal={setShowCreateBoxModal}
                        unitMeasure={unitMeasure}
                        initialBoxesNumber={initialBoxesNumber}
                      />
                    </Col>
                  </Row>
                </Panel>
              ))}
            </Collapse>
            <Row justify="end" style={{ marginTop: 24 }}>
              <Col>
                {' '}
                <Affix offsetBottom={10} align>
                  <Row justify="end" align="top">
                    <Alert
                      className="sticky-alert-message"
                      action={
                        <Space>
                          <Button
                            loading={loadingDraft}
                            className="btn-primary"
                            icon={<DownloadOutlined />}
                            onClick={() => handleSendDraft()}
                          >
                            {t('orders.alertButtonSave')}
                          </Button>
                          <Button
                            className="btn-link-filled"
                            icon={<SendOutlined />}
                            htmlType="submit"
                          >
                            {t('orders.alertButtonSend')}
                          </Button>
                        </Space>
                      }
                    />
                  </Row>
                </Affix>
              </Col>
            </Row>
          </Form>
          <CreateBoxModal
            showCreateBoxModal={showCreateBoxModal}
            setShowCreateBoxModal={setShowCreateBoxModal}
            unitMeasure={unitMeasure}
          />
        </div>
      )}
    </>
  );
}

ProductBoxStep.propTypes = {
  orders: PropTypes.arrayOf(Object).isRequired,
  setActualStep: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  warehouseId: PropTypes.number.isRequired,
  courierCredentials: PropTypes.objectOf(Object).isRequired,
  initialUnitMeasure: PropTypes.string.isRequired,
  shipmentType: PropTypes.string.isRequired,
  isPalletized: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(Object).isRequired,
  setProducts: PropTypes.func.isRequired,
};

export default ProductBoxStep;
