import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Card, Button, Space, Spin, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import actionsAnalitycs from '../../redux/analytic/dashboard/actions';
import { getErrorMessage } from '../../api/api';
import openNotification from '../../components/Toastr';
import { sellerMarketplaces } from '../../utils/const';
import dashboardApi from '../../api/dashboard';
import 'antd/dist/antd.min.css';
import './home.css';
import TablePandL from './components/charts/components/TablePandL';
import dashboardV2API from '../../api/dashboardV2';
import PandLFilter from './components/PandLFilter';
import dashboardSelectors from '../../redux/analytic/dashboard/selectors';
import ChartsPandL from './components/charts/components/ChartsPandL';

function PandL() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const session = useSelector((store) => store.Session.session);
  const partnerId = session.userInfo.commercial_partner_id[0];

  const [listMarketplaceAvailableClient, setListMarketplaceAvailableClient] =
    useState([]);
  const [dataFinancialSummary, setDataFinancialSummary] = useState([]);
  const [dataFinancialSummaryLoading, setDataFinancialSummaryLoading] =
    useState(false);
  const [dataFinancialRatios, setDataFinancialRatios] = useState([]);
  const [dataFinancialRatiosLoading, setDataFinancialRatiosLoading] =
    useState(false);
  const [downloadingFile, setDownloadingFile] = useState(false);

  const rawfilter = useSelector(dashboardSelectors.selectRawFilter);

  const getMarketplaces = async (partner) => {
    const defaultFilters = {
      partner: [partner],
      marketplace: Object.values(sellerMarketplaces),
      periodInterval: 'MONTHLY',
      currency: localStorage.getItem('defaultCurrency'),
    };

    await dashboardApi
      .getMarketplacesPerClient(defaultFilters)
      .then((response) => {
        if (response?.data?.length > 0) {
          const availablePandLs = response.data.filter(
            (item) =>
              item.name.includes('Amazon') || item.name.includes('Walmart')
          );
          const marketAvailable = [];
          availablePandLs.forEach((e) => {
            if (e.name === 'Amazon') {
              marketAvailable.unshift(e.name);
            } else {
              marketAvailable.push(e.name);
            }
          });
          dispatch(
            actionsAnalitycs.setFilter({
              ...defaultFilters,
              dashboard: 'p-l',
              marketplace: marketAvailable,
              currency: localStorage.getItem('defaultCurrency'),
            })
          );
          setListMarketplaceAvailableClient(marketAvailable);
        } else {
          setListMarketplaceAvailableClient([]);
        }
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  useEffect(() => {
    getMarketplaces(partnerId);
  }, []);

  const getFinancialSummary = async (formFilter) => {
    setDataFinancialSummaryLoading(true);
    setDataFinancialRatiosLoading(true);
    const updatedFilter = { ...formFilter };
    if (!updatedFilter.startDate) {
      updatedFilter.startDate = moment('2025-01-01').toISOString();
    }
    await dashboardV2API
      .getFinancialSummary(updatedFilter)
      .then((response) => {
        moment.locale('es');
        if (response?.data?.length > 0) {
          setDataFinancialSummary(response.data);
          setDataFinancialRatios(response.data);
        }
        setDataFinancialSummaryLoading(false);
        setDataFinancialRatiosLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        setDataFinancialSummaryLoading(false);
        setDataFinancialRatiosLoading(false);
      });
  };

  const handleDownloadPLTableCSV = async () => {
    setDownloadingFile(true);
    if (!rawfilter.startDate) {
      rawfilter.startDate = moment('2025-01-01').toISOString();
    }
    return dashboardV2API
      .downloadFinancialSummaryCSV(rawfilter)
      .then(() => {
        setDownloadingFile(false);
      })
      .catch(() => {
        openNotification({
          status: false,
          content: t('download.csvError'),
        });
        setDownloadingFile(false);
      });
  };

  const ejectFunctions = (values) => {
    setDataFinancialSummary([]);
    if (values) {
      dispatch(actionsAnalitycs.setFilter(values));
      getFinancialSummary(values);
    }
  };

  return (
    <>
      <div
        className="content-div"
        style={{
          backgroundColor: '#f9f9f9',
          paddingBottom: 0,
          paddingTop: 70,
          paddingLeft: 20,
          paddingRight: 15,
        }}
      >
        <Row className="home-listing-main-row listings-main">
          <Col xs={24} md={24} lg={24} xl={24} span={12}>
            <Card className="ventas-home-listing-card">
              <Row>
                <Col xs={24} span={24} className="intro-filters">
                  <PandLFilter
                    ejectFunctions={ejectFunctions}
                    listMarketplaceAvailableClient={
                      listMarketplaceAvailableClient
                    }
                    partnerId={partnerId}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <div
        className="content-div step3Dashboards"
        style={{
          backgroundColor: '#f9f9f9',
          paddingBottom: 20,
          paddingTop: 5,
          paddingLeft: 40,
          paddingRight: 15,
        }}
      >
        <Row>
          <Col span={24}>
            <div>
              <Card
                bordered={false}
                style={{ width: '100%', textAlign: 'left' }}
              >
                <Tabs>
                  <Tabs.TabPane tab={t('pandl.financialSummary')} key="tab-1">
                    <Row gutter={[12, 12]}>
                      {dataFinancialSummaryLoading ? (
                        <Col span={24}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Spin />
                          </div>
                        </Col>
                      ) : (
                        <>
                          <Col span={24}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                className="btn-basic"
                                onClick={handleDownloadPLTableCSV}
                              >
                                <Space>
                                  {t('download.csv')}{' '}
                                  {downloadingFile ? (
                                    <Spin
                                      style={{ marginTop: '3px' }}
                                      size="small"
                                    />
                                  ) : (
                                    <VerticalAlignBottomOutlined />
                                  )}
                                </Space>
                              </Button>
                            </div>
                          </Col>
                          <Col span={24}>
                            <TablePandL
                              data={dataFinancialSummary}
                              marketplace={rawfilter.marketplace}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={t('pandl.financialRatios')} key="tab-2">
                    <Row>
                      <Col span={24}>
                        <ChartsPandL
                          data={dataFinancialRatios}
                          financialRatiosLoader={dataFinancialRatiosLoading}
                        />
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PandL;
