import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Tooltip,
  Typography,
} from 'antd';
import {
  AlertOutlined,
  ExclamationCircleTwoTone,
  FrownOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import warehousesAPI from '../../../api/warehouses';
import couriersAPI from '../../../api/courier';
import shipmentsAPI from '../../../api/shipments';
import 'antd/dist/antd.min.css';
import DestinationModal from './DestinationModal';
import ThirdPartyShipmentBoxesStep from './ThirdPartyShipmentBoxesStep';
import FedexPickup from '../../order/components/steps/components/Pickup/FedexPickup';
import openNotification from '../../../components/Toastr';
import FBMCouriers from '../../../utils/FBMCouriers';
import { translateErrors } from '../../../utils/functions';
import ServiceSelect from '../../order/ServiceSelect';
import thirdPartyShipmentAPI from '../../../api/thirdPartyShipment';
import PickUp99Minutos from './PickUp99Minutos';
import ProductsTable from './ProductsTable';
import ShipsmartPickupSummaryStep from '../../order/components/couriers/Shipsmart/steps/ShipsmartPickupSummaryStep';
import ShipsmartSpecificInfo from '../../order/components/couriers/Shipsmart/components/ShipsmartSpecificInfo';
import { createCourierShipmentsErrors } from '../../../utils/errors';
import CheckCards from '../../order/components/steps/components/CheckCards';
import StickyAlert from '../../order/components/steps/components/StickyAlert';
import ThirdPartyShipmentReview from './ThirdPartyShipmentReview';
import InformationBox from '../../order/components/steps/components/InformationBox';
import ShipmentCourierEnum from '../enums/shipmentCourier.enum';
import ShipmentImporter from '../../order/components/ShipmentImporter';
import { getErrorMessage } from '../../../api/api';

const { Step } = Steps;

function ThirdPartyShipment() {
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formOfficeDelivery] = Form.useForm();
  const [destinationForm] = Form.useForm();
  const [form99PickUp] = Form.useForm();
  const session = useSelector((store) => store.Session.session);

  const [dateTime, setDateTime] = useState();
  const [warehouses, setWarehouses] = useState([]);
  const [openDestinationModal, setOpenDestinationModal] = useState(false);
  const [selected, setSelected] = useState(0);
  const [lengthUnit, setLengthUnit] = useState('cm');
  const [sendLoading, setSendLoading] = useState(false);
  const [steps, setSteps] = useState({
    content: 'finish',
    boxes: 'wait',
    send: 'wait',
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [products, setProducts] = useState([]);
  const [loadingRates, setLoadingRates] = useState(false);
  const [selectedService, setSelectedService] = useState();
  const [rate, setRate] = useState([]);
  const [rates, setRates] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [initialProductsNumber, setInitialProductsNumber] = useState(1);
  const [initialBoxesNumber, setInitialBoxesNumber] = useState(1);
  const [couriers, setCouriers] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState();
  const [thirdPartyShipmentCoverage, setThirdPartyShipmentCoverage] =
    useState();
  const [shipmentType, setShipmentType] = useState();
  const [destinationCountryCode, setDestinationCountryCode] = useState();
  const [minShipmentWeight, setMinShipmentWeight] = useState(0);
  const warehouseId = Form.useWatch('warehouseId', form);

  const [originCountryCode, setOriginCountryCode] = useState('');
  const [shipsmartSummaryData, setShipsmartSummaryData] = useState([]);
  const [translatePath, setTranslatePath] = useState({});

  const [radioDefaultPallet, setRadioDefaultPallet] = useState(true);
  const [isPalletized, setIsPalletized] = useState(false);
  const [radioDefaultMetric, setRadioDefaultMetric] = useState(true);
  const [isMetric, setIsMetric] = useState(true);
  const [commercialInvoiceRequirements, setCommercialInvoiceRequirements] =
    useState();

  const [radioDefault, setRadioDefault] = useState(false);
  const [isPickup, setIsPickup] = useState();

  const [importerForm] = Form.useForm();
  const [openImporterModal, setOpenImporterModal] = useState(false);
  const [defaultImporter, setDefaultImporter] = useState(null);
  const [importerDisabled, setImporterDisabled] = useState(true);

  useEffect(() => {
    setImporterDisabled(!defaultImporter);
  }, [defaultImporter]);

  const history = useHistory();
  const { thirdPartyShipmentId } = useParams();

  const assembleRecipientPayload = () => {
    const recipientsStreetLines = [
      destinationForm
        .getFieldValue()
        .streetName?.split(',')[0]
        .substring(0, 35),
    ];
    const additionalAddressInfo = destinationForm.getFieldValue(
      'additionalAddressInfo'
    );
    if (additionalAddressInfo) {
      recipientsStreetLines.push(additionalAddressInfo);
    }
    const recipients = [
      {
        address: {
          streetLines: recipientsStreetLines,
          city: destinationForm.getFieldValue('city'),
          stateOrProvinceCode: destinationForm.getFieldValue(
            'stateOrProvinceCode'
          ),
          postalCode: destinationForm.getFieldValue('postalCode'),
          countryCode: destinationForm.getFieldValue('countryCode'),
        },
        contact: {
          personName: destinationForm.getFieldValue('contactName'),
          emailAddress: destinationForm.getFieldValue('email'),
          phoneExtension: destinationForm.getFieldValue('phoneExtension'),
          phoneNumber: destinationForm.getFieldValue('phoneNumber'),
          companyName: destinationForm.getFieldValue('companyName'),
        },
        tins: {
          number: destinationForm.getFieldValue('taxIdNumber'),
          usage: destinationForm.getFieldValue('taxIdReason'),
          tinType: destinationForm.getFieldValue('taxIdType'),
        },
      },
    ];
    return recipients;
  };

  const assembleCommoditiesPayload = () => {
    const commodities = Object.keys(form.getFieldValue().products).map(
      (key) => {
        return {
          description: form.getFieldValue().products[key].description,
          countryOfManufacture:
            form.getFieldValue().products[key].countryOfManufacture,
          quantity: form.getFieldValue().products[key].quantity,
          quantityUnits: 'PCS',
          unitPrice: {
            amount: form.getFieldValue().products[key].unitPrice,
            value: form.getFieldValue().products[key].unitPrice,
            currency: 'USD',
          },
          customsValue: {
            amount:
              form.getFieldValue().products[key].quantity *
              form.getFieldValue().products[key].unitPrice,
            currency: 'USD',
          },
          weight: {
            value: form.getFieldValue().products[key].weight,
            units: lengthUnit === 'cm' ? 'KG' : 'LB',
          },
          unitWeight: {
            value: form.getFieldValue().products[key].weight,
            unit: lengthUnit === 'cm' ? 'KG' : 'LB',
          },
          harmonizedCode: form.getFieldValue().products[key].harmonizedCode,
          isFood: form.getFieldValue().products[key].isFood,
        };
      }
    );
    return commodities;
  };

  const assembleBoxesPayload = () => {
    const boxes = Object.keys(form.getFieldValue().boxes).map((key) => {
      const { id } = form.getFieldValue().boxes[key];
      return {
        id,
        weight: {
          value: form.getFieldValue().boxes[key].weight,
          units: lengthUnit === 'cm' ? 'KG' : 'LB',
          unit: lengthUnit === 'cm' ? 'KG' : 'LB',
        },
        dimensions: {
          height: parseFloat(form.getFieldValue().boxes[key].height),
          width: parseFloat(form.getFieldValue().boxes[key].width),
          length: parseFloat(form.getFieldValue().boxes[key].length),
          units: lengthUnit.toUpperCase(),
          unit: lengthUnit.toUpperCase(),
        },
        pallet: isPalletized,
      };
    });
    return boxes;
  };

  const fillForm = (shipment) => {
    if (shipment.pickup) {
      setDateTime({
        pickupDate: shipment.pickup.pickupDate.substring(0, 10),
        readyTime: shipment.pickup.pickupTime,
        closeTime: shipment.pickup.latestPickupTime,
      });
    }
    const destinationAddress = `${shipment.requestedShipment.recipients[0].address.streetLines[0]}, ${shipment.requestedShipment.recipients[0].address.city}, ${shipment.requestedShipment.recipients[0].address.countryCode}`;
    form.setFieldsValue({
      destination: destinationAddress,
    });
    const existProducts = [];
    shipment.requestedShipment.customsClearanceDetail.commodities.forEach(
      (product) => {
        existProducts.push({
          description: product.description,
          countryOfManufacture: '',
          quantity: product.quantity,
          unitPrice: 0,
          harmonizedCode: '',
          weight: 0,
        });
      }
    );
    setProducts(existProducts);
    const existBoxes = [];
    shipment.requestedShipment.requestedPackageLineItems.forEach((box) => {
      const selectBox = existBoxes.find(
        ({ weight }) => weight === box.weight.value
      );
      if (selectBox) {
        selectBox.quantity += 1;
      } else {
        existBoxes.push({
          quantity: 1,
          weight: box.weight.value,
        });
      }
    });
  };

  const setRatesAndTransitTimesPayload = () => {
    const boxes = assembleBoxesPayload();
    const commodities = assembleCommoditiesPayload();
    const actualWarehouseId = warehouseId || form.getFieldsValue().warehouseId;
    const selectedWarehouse = warehouses.find(
      (warehouse) => warehouse.id === actualWarehouseId
    );
    return {
      courierName: selectedCourier,
      palletized: isPalletized,
      shipper: {
        address: {
          countryCode: selectedWarehouse.address.countryCode,
          city: selectedWarehouse.address.city,
          stateOrProvinceCode: selectedWarehouse.address.stateOrProvinceCode,
          postalCode: selectedWarehouse.address.postalCode,
        },
      },
      recipient: {
        address: {
          countryCode: destinationForm.getFieldValue('countryCode'),
          city: destinationForm.getFieldValue('city'),
          stateOrProvinceCode: destinationForm.getFieldValue(
            'stateOrProvinceCode'
          ),
          postalCode: destinationForm.getFieldValue('postalCode'),
        },
      },
      products: commodities,
      boxes,
      insurance: form.getFieldValue('insurance'),
    };
  };

  const setImporter = () => {
    if (importerDisabled) return null;
    return {
      contact: {
        personName: importerForm.getFieldValue('personName'),
        phoneExtension: importerForm.getFieldValue('phoneExtension'),
        phoneNumber: importerForm.getFieldValue('phoneNumber'),
        companyName: importerForm.getFieldValue('companyName'),
        emailAddress: importerForm.getFieldValue('emailAddress'),
      },
      address: {
        streetLines: [
          importerForm
            .getFieldValue('streetName')
            ?.split(',')?.[0]
            ?.substring(0, 35),
          importerForm.getFieldValue('additionalAddressInfo'),
        ].filter((x) => x),
        city: importerForm.getFieldValue('city'),
        countryCode: importerForm.getFieldValue('countryCode'),
        stateOrProvinceCode: importerForm.getFieldValue('stateOrProvinceCode'),
        postalCode: importerForm.getFieldValue('postalCode'),
      },
      taxIdentification: {
        number: importerForm.getFieldValue('taxIdNumber'),
        reason: importerForm.getFieldValue('taxIdReason'),
      },
    };
  };

  const generateUploadPayload = (isPickUp) => {
    const shipmentPayload = {
      thirdPartyShipmentId: parseInt(thirdPartyShipmentId, 10),
      pickUpInfo: {},
    };
    if (isPickUp) {
      shipmentPayload.pickupDate = dateTime.pickupDate;
      shipmentPayload.readyTime = dateTime.readyTime;
      shipmentPayload.closeTime = dateTime.closeTime;
      shipmentPayload.scheduleDay = dateTime.scheduleDay;
    } else if (selectedCourier === FBMCouriers.INT99MINUTOS) {
      shipmentPayload.pickUpDate = form99PickUp.getFieldsValue().pickUp;
    }
    if (selectedCourier === ShipmentCourierEnum.SHIPSMART) {
      shipmentPayload.taxPayer = form.getFieldValue('taxPayer');
      shipmentPayload.insurance = form.getFieldValue('insurance');
    }
    shipmentPayload.warehouseId = form.getFieldValue().warehouseId;
    shipmentPayload.isPalletized = form.getFieldValue().isPalletized;
    if (Object.keys(form.getFieldValue().boxes).length > 0)
      shipmentPayload.boxes = assembleBoxesPayload();
    shipmentPayload.recipients =
      destinationForm.getFieldValue().countryCode && assembleRecipientPayload();
    shipmentPayload.commodities =
      form.getFieldValue().products && assembleCommoditiesPayload();
    shipmentPayload.rate = rate;
    shipmentPayload.courier = selectedCourier;
    shipmentPayload.importer = setImporter();
    return [shipmentPayload];
  };

  const generateShipsmartRatesPayload = () => {
    const shipmentPayload = {
      thirdPartyShipmentId: parseInt(thirdPartyShipmentId, 10),
      warehouseId: form.getFieldValue().warehouseId,
      boxes:
        Object.keys(form.getFieldValue().boxes).length > 0 &&
        assembleBoxesPayload(),
      recipients:
        destinationForm.getFieldValue().countryCode &&
        assembleRecipientPayload(),
      commodities:
        form.getFieldValue().products && assembleCommoditiesPayload(),
      rate,
      pickUpInfo: {},
      '99minutosData': {},
      isPalletized: form.getFieldValue('isPalletized'),
      taxPayer: form.getFieldValue('taxPayer'),
      insurance: form.getFieldValue('insurance'),
      notaFiscal: form.getFieldValue('notaFiscal'),
      stateTaxId: form.getFieldValue('stateTaxId'),
      federalTaxId: form.getFieldValue('federalTaxId'),
    };
    return [shipmentPayload];
  };

  const generateShipmentPayload = (isPickUp) => {
    if (selectedCourier === ShipmentCourierEnum['99MINUTOS']) {
      return {
        thirdPartyShipmentId: parseInt(thirdPartyShipmentId, 10),
        warehouseId: form.getFieldValue().warehouseId,
        boxes:
          Object.keys(form.getFieldValue().boxes).length > 0 &&
          assembleBoxesPayload(),
        recipients:
          destinationForm.getFieldValue().countryCode &&
          assembleRecipientPayload(),
        commodities:
          form.getFieldValue().products && assembleCommoditiesPayload(),
        rate,
        pickUpInfo: {
          pickupDate: form99PickUp.getFieldsValue().pickUp,
        },
        '99minutosData': thirdPartyShipmentCoverage,
        isPalletized: form.getFieldValue('isPalletized'),
        ...(selectedCourier === FBMCouriers.FEDEX && {
          fedexData: { serviceType: selectedService },
        }),
      };
    }
    const option = rates[0]?.find((o) => o.serviceName === selectedService);
    const serviceType = {
      name: [
        ShipmentCourierEnum.FEDEX,
        ShipmentCourierEnum.LAPCOURIER,
      ].includes(selectedCourier)
        ? option?.code
        : option?.serviceName,
      code: option?.code,
      ...(option?.externalId && {
        externalId: option?.externalId,
      }),
    };
    const shipsmartRate =
      selectedCourier === ShipmentCourierEnum.SHIPSMART
        ? [
            {
              type: 'NET',
              value: parseFloat(
                shipsmartSummaryData[0].shipsmartData?.quotationData
                  ?.currency_quote_amount
              ),
              currency:
                shipsmartSummaryData[0].shipsmartData?.quotationData
                  ?.lapCurrency,
            },
          ]
        : null;
    return {
      orderId: parseInt(thirdPartyShipmentId, 10),
      shipment: {
        courierName: selectedCourier || null,
        ...(selectedCourier === ShipmentCourierEnum.SHIPSMART &&
          shipsmartSummaryData?.length > 0 && {
            invoiceNumber: shipsmartSummaryData[0].notaFiscal,
          }),
        serviceType: {
          ...serviceType,
          ...(selectedCourier === ShipmentCourierEnum.SHIPSMART &&
            shipsmartSummaryData?.length > 0 && {
              name: shipsmartSummaryData[0].shipsmartData?.quotationData?.name,
              code: shipsmartSummaryData[0].shipsmartData?.quotationData?.code,
              externalId:
                shipsmartSummaryData[0].shipsmartData?.quotationData
                  ?.lapQuotationId,
            }),
        },
        pickup: {
          type: isPickUp ? 'SCHEDULE' : 'DROP_OFF',
        },
        importer: setImporter(),
        ...(rate && { rate }),
        ...(shipsmartRate && { rate: shipsmartRate }),
        ...(selectedCourier === ShipmentCourierEnum.SHIPSMART &&
          shipsmartSummaryData?.length > 0 && {
            shipper: {
              stateTaxId: shipsmartSummaryData[0]?.stateTaxId,
              federalTaxId: shipsmartSummaryData[0]?.federalTaxId,
            },
          }),
      },
    };
  };

  const handleSendDraft = async (isPickUp = false) => {
    try {
      setLoadingDraft(true);
      const payload = generateUploadPayload(isPickUp);
      payload[0].status = 'Draft';
      await thirdPartyShipmentAPI.updateThirdPartyShipmentById(
        thirdPartyShipmentId,
        payload[0]
      );
      openNotification({
        status: true,
      });
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    } finally {
      setLoadingDraft(false);
    }
  };

  const validateWeight = () => {
    if (selectedCourier === FBMCouriers.INT99MINUTOS) return true;
    const formValues = form.getFieldsValue();
    const productsWeight = formValues.products.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.weight * currentValue.quantity,
      0
    );
    const boxesWeight = formValues.boxes.reduce(
      (accumulator, currentValue) => accumulator + currentValue.weight,
      0
    );
    if (productsWeight > boxesWeight) {
      openNotification({
        status: false,
        content: `${t(
          'thirdPartyShipments.errors.productBoxWeight'
        )} ${minShipmentWeight}`,
      });
      return false;
    }
    return true;
  };

  const onNextStep = async () => {
    await form.validateFields();
    await handleSendDraft();
    const validWeight = validateWeight();
    if (validWeight) {
      setSteps({ ...steps, boxes: 'finish', content: 'wait' });
      setCurrentStep(1);
      setSelected(1);
      if (selectedCourier === ShipmentCourierEnum.SHIPSMART) {
        setShipsmartSummaryData(generateShipsmartRatesPayload(false));
      }
    }
  };

  const handleCancelForm = async () => {
    try {
      setLoadingDelete(true);
      await thirdPartyShipmentAPI.cancelThirdPartyShipmentById(
        thirdPartyShipmentId
      );
      window.location = '/envios';
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    } finally {
      setLoadingDelete(false);
    }
  };

  const createShipment = async (isPickUp) => {
    if (isPickUp && (!dateTime?.readyTime || !dateTime?.closeTime)) {
      openNotification({
        status: false,
        content: t('externalShipment.pickUp.required'),
      });
      return;
    }
    if (!isPickUp && selectedCourier !== FBMCouriers.LAPCOURIER) {
      await formOfficeDelivery.validateFields();
      setDateTime();
    }
    let apiStep = 'shipment';
    try {
      setSendLoading(true);
      const shipmentPayload = generateShipmentPayload(isPickUp);
      const shipment = await shipmentsAPI.createThirdPartyShipment(
        shipmentPayload
      );
      if (isPickUp) {
        apiStep = 'pickup';
        await couriersAPI.createPickup(selectedCourier, {
          courierShipmentIds: [shipment.data.id],
          warehouseId: form.getFieldValue().warehouseId,
          pickupDate: dateTime.pickupDate,
          readyTime: dateTime.readyTime,
          closeTime: dateTime.closeTime,
        });
      }

      setSteps({
        ...steps,
        content: 'finish',
        send: 'finish',
        boxes: 'finish',
      });
      setCurrentStep(2);
      setSelected(2);
    } catch (error) {
      if (apiStep === 'shipment') {
        setTranslatePath(translateErrors(error, createCourierShipmentsErrors));
      } else {
        openNotification({
          status: false,
          content: getErrorMessage(error),
        });
      }
    } finally {
      setSendLoading(false);
    }
  };

  const getOriginCountryCode = async () => {
    const actualWarehouseId = warehouseId || form.getFieldsValue().warehouseId;
    const selectedWarehouse = warehouses.find(
      (warehouse) => warehouse.id === actualWarehouseId
    );
    if (destinationCountryCode) {
      setShipmentType(
        selectedWarehouse?.address?.countryCode === destinationCountryCode
          ? 'NATIONAL'
          : 'INTERNATIONAL'
      );
    }
    setOriginCountryCode(selectedWarehouse?.address?.countryCode);
  };

  const handleNewDirections = async (
    streetName = null,
    countryCode = null,
    stateOrProvinceCode = null
  ) => {
    if (form.getFieldsValue().warehouseId === null) {
      history.push('/bodegas');
    } else if (
      (form.getFieldsValue().warehouseId &&
        form.getFieldsValue().destination) ||
      (warehouseId && streetName)
    ) {
      const actualWarehouseId =
        warehouseId || form.getFieldsValue().warehouseId;
      const actualWarehouseCountryCode = warehouses.find(
        (warehouse) => warehouse.id === actualWarehouseId
      )?.address?.countryCode;
      if (destinationCountryCode) {
        setShipmentType(
          actualWarehouseCountryCode === destinationCountryCode
            ? 'NATIONAL'
            : 'INTERNATIONAL'
        );
      }
      setOriginCountryCode(actualWarehouseCountryCode);
      try {
        const availability = await couriersAPI.getCourierCredentialsByCoverage(
          null,
          actualWarehouseId,
          countryCode || destinationForm.getFieldsValue().countryCode,
          stateOrProvinceCode ||
            destinationForm.getFieldsValue().stateOrProvinceCode
        );
        if (availability.data.length > 0) {
          setCouriers(availability.data);
        } else {
          openNotification({
            status: false,
            content: t('thirdPartyShipments.couriers.noCouriersAvailable'),
          });
        }
      } catch (error) {
        openNotification({
          status: false,
          content: t('thirdPartyShipments.couriers.noCouriersAvailable'),
        });
      }
    }
  };

  const check99Coverage = async (
    courier,
    city = null,
    countryCode = null,
    streetName = null,
    postalCode = null,
    stateOrProvinceCode = null
  ) => {
    setSelectedCourier(courier);
    if (courier === FBMCouriers.INT99MINUTOS) {
      try {
        const payload = {
          warehouseId: warehouseId || form.getFieldValue().warehouseId,
          city: city || destinationForm.getFieldValue().city,
          countryCode:
            countryCode || destinationForm.getFieldValue().countryCode,
          streetName: streetName || destinationForm.getFieldValue().streetName,
          postalCode: postalCode || destinationForm.getFieldValue().postalCode,
          stateOrProvinceCode:
            stateOrProvinceCode ||
            destinationForm.getFieldValue().stateOrProvinceCode,
        };
        const response = await couriersAPI.getShipmentTypes(
          FBMCouriers.INT99MINUTOS,
          payload
        );
        if (!response.data.hasCoverage) {
          openNotification({
            status: false,
            content: t('thirdPartyShipments.int99minutos.noCoverage'),
          });
          form.setFieldValue('courier', null);
        } else {
          setThirdPartyShipmentCoverage(response.data);
        }
      } catch (error) {
        openNotification({
          status: false,
          content: t('thirdPartyShipments.int99minutos.errorGetCoverage'),
        });
        form.setFieldValue('courier', null);
      }
    }
  };

  const getCommercialInvoiceRequirements = async () => {
    try {
      const response = await couriersAPI.commercialInvoiceRequirements(
        selectedCourier,
        originCountryCode,
        destinationCountryCode
      );
      setCommercialInvoiceRequirements(response.data);
    } catch (error) {
      openNotification({
        status: false,
        content: t('orders.documents.error'),
      });
    }
  };

  const disabledDate = (current) => {
    return current && current < moment(new Date()).subtract(1, 'day');
  };

  const formItems = [
    {
      name: 'warehouseId',
      label: t('orders.newOrder.contents.origin'),
      tooltip: t('thirdPartyShipments.destination.originTooltip'),
      component: (
        <Select
          disabled={currentStep > 0}
          onChange={handleNewDirections}
          placeholder={t('orders.newOrder.contents.chooseWarehouse')}
        >
          {warehouses &&
            warehouses.map((warehouse) => (
              <Select.Option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </Select.Option>
            ))}
          <Select.Option key={null} value={null}>
            <PlusOutlined /> {t('orders.newOrder.contents.newWarehouse')}
          </Select.Option>
        </Select>
      ),
      rules: [{ required: true, message: 'Requerido' }],
      colSpan: 12,
      extraComponent: (
        <DestinationModal
          open={openDestinationModal}
          setOpen={setOpenDestinationModal}
          form={destinationForm}
          externalForm={form}
          handleNewDirections={handleNewDirections}
          setDestinationCountryCode={setDestinationCountryCode}
        />
      ),
    },
    {
      name: 'destination',
      label: t('orders.newOrder.contents.destination'),
      tooltip: t('thirdPartyShipments.destination.tooltip'),
      component: (
        <Input
          disabled={currentStep > 0}
          onClick={() => setOpenDestinationModal(true)}
          placeholder={t('thirdPartyShipments.destination.placeholder')}
        />
      ),
      rules: [{ required: true, message: 'Requerido' }],
      colSpan: 12,
    },
    {
      name: 'courier',
      label: t('orders.newOrder.contents.courier'),
      tooltip: t('thirdPartyShipments.couriers.tooltip'),
      component: (
        <Select
          disabled={currentStep > 0}
          onChange={(e) => check99Coverage(e)}
          placeholder={t('orders.newOrder.contents.chooseCourier')}
        >
          {couriers &&
            couriers.map((courier) => (
              <Select.Option
                key={
                  ShipmentCourierEnum[courier.courierCompanyName.toUpperCase()]
                }
                value={
                  ShipmentCourierEnum[courier.courierCompanyName.toUpperCase()]
                }
              >
                {courier.courierCompanyName === 'LAPCourier'
                  ? 'Envíos LAP'
                  : ShipmentCourierEnum[
                      courier.courierCompanyName.toUpperCase()
                    ]}
              </Select.Option>
            ))}
        </Select>
      ),
      rules: [{ required: true, message: 'Requerido' }],
      colSpan: 12,
      extraComponent: null,
    },
  ];

  useEffect(async () => {
    const response = await warehousesAPI.getCompanyWarehouses(
      20,
      1,
      session?.userInfo?.company_id[0]
    );
    setWarehouses(response.data.results);
    const thirdPartyShipmentResponse =
      await thirdPartyShipmentAPI.getThirdPartyShipmentById(
        thirdPartyShipmentId
      );
    const thirdPartyShipmentData = thirdPartyShipmentResponse.data;
    if (thirdPartyShipmentData?.status === 'Shipped') {
      setSelected(2);
      setSteps({ ...steps, send: 'finish', boxes: 'finish' });
      setCurrentStep(2);
      if (thirdPartyShipmentData.courierShipment?.pickup)
        setDateTime({
          pickupDate:
            thirdPartyShipmentData.courierShipment?.pickup.pickupDate.substring(
              0,
              10
            ),
          readyTime: thirdPartyShipmentData.courierShipment?.pickup.pickupTime,
          closeTime:
            thirdPartyShipmentData.courierShipment?.pickup.latestPickupTime,
        });
    }
    setIsPalletized(thirdPartyShipmentData.isPalletized);
    if (thirdPartyShipmentData.importer) {
      importerForm.setFieldsValue({
        personName: thirdPartyShipmentData.importer.contact?.personName,
        phoneExtension: thirdPartyShipmentData.importer.contact?.phoneExtension,
        phoneNumber: thirdPartyShipmentData.importer.contact?.phoneNumber,
        companyName: thirdPartyShipmentData.importer.contact?.companyName,
        emailAddress: thirdPartyShipmentData.importer.contact?.emailAddress,
        streetName:
          thirdPartyShipmentData.importer.address?.streetLines?.join(', '),
        city: thirdPartyShipmentData.importer.address?.city,
        countryCode: thirdPartyShipmentData.importer.address?.countryCode,
        stateOrProvinceCode:
          thirdPartyShipmentData.importer.address?.stateOrProvinceCode,
        postalCode: thirdPartyShipmentData.importer.address?.postalCode,
        taxIdNumber: thirdPartyShipmentData.importer.taxIdentification?.number,
        taxIdReason: thirdPartyShipmentData.importer.taxIdentification?.reason,
      });
      form.setFieldValue('importer', importerForm.getFieldValue('companyName'));
      setImporterDisabled(false);
    }
    setRadioDefaultPallet(true);
    const boxFormData = {};
    thirdPartyShipmentData.boxes.forEach((thirdPartyShipmentBox, index) => {
      boxFormData[index] = {
        id: thirdPartyShipmentBox.box?.id,
        name: thirdPartyShipmentBox.box?.name,
        length: thirdPartyShipmentBox.box?.length,
        width: thirdPartyShipmentBox.box?.width,
        height: thirdPartyShipmentBox.box?.height,
        weight: thirdPartyShipmentBox.weight,
        isPallet: thirdPartyShipmentBox.box?.isPallet,
      };
    });
    setInitialBoxesNumber(thirdPartyShipmentData.boxes?.length || 1);
    const productFormData = {};
    thirdPartyShipmentData.products.forEach(() => {
      const actualProducNumber = Object.keys(productFormData).length;
      productFormData[actualProducNumber] = {
        description: thirdPartyShipmentData.products[actualProducNumber].name,
        countryOfManufacture:
          thirdPartyShipmentData.products[actualProducNumber]
            .countryOfManufacture,
        quantity: thirdPartyShipmentData.products[actualProducNumber].quantity,
        unitPrice: thirdPartyShipmentData.products[actualProducNumber].unitFOB,
        harmonizedCode:
          thirdPartyShipmentData.products[actualProducNumber].harmonizedCode,
        weight: thirdPartyShipmentData.products[actualProducNumber].weight,
      };
    });
    setInitialProductsNumber(thirdPartyShipmentData.products?.length || 1);
    let unit = null;
    if (thirdPartyShipmentData.products.length > 0) {
      unit =
        thirdPartyShipmentData.products[0].measureUnit === 'KG' ? 'cm' : 'in';
      setRadioDefaultMetric(true);
      setIsMetric(unit === 'cm');
      setLengthUnit(unit);
    }
    form.setFieldsValue({
      warehouseId: thirdPartyShipmentData.warehouse?.id,
      destination: thirdPartyShipmentData.recipientAddress?.streetName,
      products: productFormData,
      boxes: boxFormData,
      ...(unit && { unitMeasurement: unit }),
      courier: thirdPartyShipmentData.courier,
      isPalletized: thirdPartyShipmentData.isPalletized,
    });
    destinationForm.setFieldsValue({
      streetName: thirdPartyShipmentData.recipientAddress?.streetName,
      additionalAddressInfo:
        thirdPartyShipmentData.recipientAddress?.additionalAddressInfo,
      city: thirdPartyShipmentData.recipientAddress?.city,
      postalCode: thirdPartyShipmentData.recipientAddress?.postalCode,
      countryCode: thirdPartyShipmentData.recipientAddress?.countryCode,
      stateOrProvinceCode:
        thirdPartyShipmentData.recipientAddress?.stateOrProvinceCode,
      contactName: thirdPartyShipmentData.recipientName,
      email: thirdPartyShipmentData.recipientMail,
      phoneExtension: thirdPartyShipmentData.recipientPhoneExtension,
      phoneNumber: thirdPartyShipmentData.recipientPhoneNumber,
      companyName: thirdPartyShipmentData.recipientCompanyName,
      taxIdNumber: thirdPartyShipmentData.recipientTaxIdNumber,
      taxIdReason: thirdPartyShipmentData.recipientTaxIdUsage,
      taxIdType: thirdPartyShipmentData.recipientTaxIdType,
    });

    if (
      thirdPartyShipmentData.warehouse?.id &&
      thirdPartyShipmentData.recipientAddress?.streetName
    )
      handleNewDirections(
        thirdPartyShipmentData.recipientAddress?.streetName,
        thirdPartyShipmentData.recipientAddress?.countryCode,
        thirdPartyShipmentData.recipientAddress?.stateOrProvinceCode
      );
    if (thirdPartyShipmentData.courier)
      check99Coverage(
        thirdPartyShipmentData.courier,
        thirdPartyShipmentData.recipientAddress?.city,
        thirdPartyShipmentData.recipientAddress?.countryCode,
        thirdPartyShipmentData.recipientAddress?.streetName,
        thirdPartyShipmentData.recipientAddress?.postalCode,
        thirdPartyShipmentData.recipientAddress?.stateOrProvinceCode
      );

    setDestinationCountryCode(
      thirdPartyShipmentData?.recipientAddress?.countryCode
    );
    setOriginCountryCode(
      thirdPartyShipmentData?.warehouse?.address?.countryCode
    );
    setSelectedCourier(thirdPartyShipmentData?.courier);

    if (location?.state?.shipmentId) {
      try {
        const responseCourier = await couriersAPI.findCourierShipments({
          thirdPartyShipments: true,
          id: location?.state?.shipmentId,
        });
        setSteps({ ...steps, send: 'finish', content: 'wait' });
        setCurrentStep(2);
        setSelected(2);
        fillForm(responseCourier.data[0]);
      } catch (_error) {
        openNotification({
          status: false,
          content: t('thirdPartyShipments.errors.loadShipmentData'),
        });
      }
    }
  }, []);

  useEffect(() => {
    getOriginCountryCode();
    if (originCountryCode && destinationCountryCode) {
      setShipmentType(
        originCountryCode === destinationCountryCode
          ? 'NATIONAL'
          : 'INTERNATIONAL'
      );
    }
  }, [warehouseId, destinationCountryCode]);

  useEffect(() => {
    if (
      selectedCourier === ShipmentCourierEnum.FEDEX &&
      originCountryCode &&
      destinationCountryCode
    )
      getCommercialInvoiceRequirements();
  }, [originCountryCode, destinationCountryCode, selectedCourier]);

  useEffect(() => {
    const textError = t(translatePath.error);
    if (Object.keys(translatePath).length !== 0) {
      notification.error({
        style: { zIndex: 'inherit' },
        duration: 0,
        message: translatePath?.titleError
          ? t(translatePath.titleError)
          : 'Error',
        description: textError,
        ...(translatePath?.tooltip
          ? {
              btn: (
                <Button
                  style={{ color: '#00e5a6' }}
                  type="link"
                  href={translatePath?.tooltip}
                  target="_blank"
                >
                  {t('common.wantKnowMore')}
                </Button>
              ),
            }
          : {}),
        icon: (
          <FrownOutlined
            className="error"
            style={{ color: '#F81D22 !important' }}
          />
        ),
      });
    }
  }, [translatePath]);

  return (
    <Col
      style={{ paddingTop: 80, paddingLeft: 50, paddingRight: 50 }}
      span={24}
    >
      <Card className="shipment-main-card">
        <Row>
          <Col span={24}>
            <Steps
              current={[selected.toString()]}
              onChange={(param1) => {
                setSelected(param1);
              }}
              style={{ marginBottom: 40 }}
            >
              <Step
                key="1"
                title={t('orders.newOrder.contents.title')}
                status={steps.content}
                disabled={
                  steps.send === 'finish' && location?.state?.shipmentId
                }
              />
              <Step
                key="2"
                disabled={
                  steps.content === 'finish' ||
                  (steps.send === 'finish' && location?.state?.shipmentId)
                }
                title={t('thirdPartyShipments.titleStep2')}
                status={steps.boxes}
              />
              <Step
                key="3"
                disabled={steps.send === 'wait'}
                title="Generar Envío"
                status={steps.send}
              />
            </Steps>
          </Col>
        </Row>

        <Form form={form} layout="vertical">
          {selected === 0 && (
            <Spin spinning={loadingDelete || loadingDraft}>
              <div className="title-content">
                {t('orders.newOrder.contents.title')}
              </div>
              <Steps progressDot direction="vertical" className="detail-steps">
                <Step
                  key="1"
                  title={
                    <Typography.Text className="shipment-step-title">
                      {t('externalShipment.step1Title')}
                    </Typography.Text>
                  }
                  status={
                    selected === 0 || currentStep !== 0 ? 'finish' : 'wait'
                  }
                  description={
                    <Row>
                      {(selected === 0 || currentStep !== 0) && (
                        <Row gutter={[16, 16]} className="rowContent">
                          {formItems.map(
                            ({
                              name,
                              label,
                              tooltip,
                              component,
                              rules,
                              colSpan,
                              extraComponent,
                            }) => (
                              <Col span={colSpan} key={name}>
                                <Form.Item
                                  name={name}
                                  label={
                                    <Row justify="flex-start">
                                      <Typography.Text className="form-title">
                                        {label}{' '}
                                        <Tooltip title={tooltip}>
                                          <InfoCircleOutlined />
                                        </Tooltip>
                                      </Typography.Text>
                                    </Row>
                                  }
                                  rules={rules}
                                >
                                  {component}
                                </Form.Item>
                                {extraComponent}
                              </Col>
                            )
                          )}
                        </Row>
                      )}
                      {destinationCountryCode &&
                        originCountryCode &&
                        selectedCourier === ShipmentCourierEnum.SHIPSMART && (
                          <Row
                            className="rowContent"
                            style={{ paddingLeft: 8, paddingRight: 8 }}
                          >
                            <ShipsmartSpecificInfo
                              originCountryCode={originCountryCode}
                              destinationCountryCode={destinationCountryCode}
                              display="block"
                            />
                          </Row>
                        )}
                    </Row>
                  }
                />
                <Step
                  key="2"
                  title={
                    <Typography.Text className="shipment-step-title">
                      {t('externalShipment.step2Title')}
                    </Typography.Text>
                  }
                  status={
                    (selectedCourier &&
                      destinationCountryCode &&
                      originCountryCode) ||
                    currentStep !== 0
                      ? 'finish'
                      : 'wait'
                  }
                  description={
                    ((selectedCourier &&
                      destinationCountryCode &&
                      originCountryCode) ||
                      currentStep !== 0) && (
                      <Row className="rowContent">
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            className="input-form-margin-bottom"
                            name="isPalletized"
                            initialValue={isPalletized}
                            rules={[
                              {
                                required: true,
                                message: 'Requerido',
                              },
                            ]}
                          >
                            <Radio.Group
                              className="cardRadioGroupContent"
                              name="isPalletized"
                              disabled={currentStep > 0}
                              onChange={(e) => {
                                setRadioDefaultPallet(true);
                                setIsPalletized(e.target.value);
                              }}
                            >
                              <CheckCards
                                isPack={isPalletized}
                                radioDefault={radioDefaultPallet}
                                inputRadioActive={{
                                  border: '1px solid rgba(0, 229, 166, 1)',
                                  backgroundColor: 'rgba(0, 229, 166, 0.05)',
                                }}
                                tag={false}
                                title={t(
                                  'orders.newOrder.boxes.separatedBoxes'
                                )}
                                text={t(
                                  'orders.newOrder.boxes.radioGroup1Checkbox3'
                                )}
                                value={false}
                              />
                              <CheckCards
                                isPack={isPalletized}
                                radioDefault={radioDefaultPallet}
                                inputRadioActive={{
                                  border: '1px solid rgba(0, 229, 166, 1)',
                                  backgroundColor: 'rgba(0, 229, 166, 0.05)',
                                }}
                                tag={false}
                                title={t('orders.newOrder.boxes.palletBoxes')}
                                text={t(
                                  'orders.newOrder.boxes.radioGroup1Checkbox4'
                                )}
                                value
                              />
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  }
                />
                <Step
                  key="3"
                  title={
                    <Typography.Text className="shipment-step-title">
                      {t('externalShipment.step3Title')}
                    </Typography.Text>
                  }
                  status={
                    (selectedCourier &&
                      destinationCountryCode &&
                      originCountryCode &&
                      isPalletized !== null) ||
                    currentStep !== 0
                      ? 'finish'
                      : 'wait'
                  }
                  description={
                    ((selectedCourier &&
                      destinationCountryCode &&
                      originCountryCode &&
                      isPalletized !== null) ||
                      currentStep !== 0) && (
                      <Row className="rowContent">
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            className="input-form-margin-bottom"
                            name="unitMeasurement"
                            rules={[
                              {
                                required: true,
                                message: 'Requerido',
                              },
                            ]}
                            initialValue="cm"
                          >
                            <Radio.Group
                              className="cardRadioGroupContent"
                              name="unitMeasurement"
                              onChange={(e) => {
                                form.setFieldValue(
                                  'unitMeasurement',
                                  e.target.value
                                );
                                setLengthUnit(e.target.value);
                                setRadioDefaultMetric(true);
                                setIsMetric(e.target.value === 'cm');
                              }}
                              disabled={currentStep > 0}
                            >
                              <CheckCards
                                isPack={isMetric}
                                radioDefault={radioDefaultMetric}
                                inputRadioActive={{
                                  border: '1px solid rgba(0, 229, 166, 1)',
                                  backgroundColor: 'rgba(0, 229, 166, 0.05)',
                                }}
                                radioOptionValue="cm"
                                tag={false}
                                title={t(
                                  'orders.shipment.unitMeasurement.metric'
                                )}
                                text="CM / KG"
                                value
                              />
                              <CheckCards
                                isPack={isMetric}
                                radioDefault={radioDefaultMetric}
                                inputRadioActive={{
                                  border: '1px solid rgba(0, 229, 166, 1)',
                                  backgroundColor: 'rgba(0, 229, 166, 0.05)',
                                }}
                                radioOptionValue="in"
                                tag={false}
                                title={t(
                                  'orders.shipment.unitMeasurement.imperial'
                                )}
                                text="IN / LB"
                                value={false}
                              />
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  }
                />
                <Step
                  key="4"
                  title={
                    <Typography.Text className="shipment-step-title">
                      {t('externalShipment.step4Title')}
                    </Typography.Text>
                  }
                  status={
                    (selectedCourier &&
                      destinationCountryCode &&
                      originCountryCode &&
                      isPalletized !== null &&
                      lengthUnit !== null) ||
                    currentStep !== 0
                      ? 'finish'
                      : 'wait'
                  }
                  description={
                    ((selectedCourier &&
                      destinationCountryCode &&
                      originCountryCode &&
                      isPalletized !== null &&
                      lengthUnit !== null) ||
                      currentStep !== 0) && (
                      <Row className="rowContent">
                        <ProductsTable
                          form={form}
                          products={products}
                          setProducts={setProducts}
                          lengthUnit={lengthUnit}
                          currentStep={currentStep}
                          initialProductsNumber={initialProductsNumber}
                          selectedCourier={selectedCourier}
                          shipmentType={shipmentType}
                          setDefaultImporter={setDefaultImporter}
                        />
                      </Row>
                    )
                  }
                />
                <Step
                  key="5"
                  status={
                    (selectedCourier &&
                      destinationCountryCode &&
                      originCountryCode &&
                      isPalletized !== null &&
                      lengthUnit !== null) ||
                    currentStep !== 0
                      ? 'finish'
                      : 'wait'
                  }
                  title={
                    <Typography.Text className="shipment-step-title">
                      {t('externalShipment.step5Title')}
                    </Typography.Text>
                  }
                  description={
                    <>
                      {defaultImporter && (
                        <Row style={{ marginTop: '20px' }}>
                          <InformationBox
                            icon={
                              <ExclamationCircleTwoTone twoToneColor="rgb(250, 173, 20, .5)" />
                            }
                            textInformationBox={
                              <div style={{ width: '100%' }}>
                                <li>
                                  {t('orders.newOrder.boxes.importer.warning')}
                                </li>
                              </div>
                            }
                            status="warning"
                            display="block"
                          />
                        </Row>
                      )}
                      <Space className="rowContent">
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            disabled={
                              !(
                                (selectedCourier &&
                                  destinationCountryCode &&
                                  originCountryCode &&
                                  isPalletized !== null &&
                                  lengthUnit !== null) ||
                                currentStep !== 0
                              )
                            }
                            checked={importerDisabled}
                            onChange={(e) =>
                              setImporterDisabled(e.target.checked)
                            }
                          >
                            {t('thirdPartyShipments.importer.sameAsConsignee')}
                          </Checkbox>
                        </Col>
                        <Col>
                          <Form.Item
                            name="importer"
                            rules={[
                              {
                                required: !importerDisabled,
                                message: t(
                                  'thirdPartyShipments.importer.required'
                                ),
                              },
                            ]}
                          >
                            <Input
                              disabled={
                                importerDisabled ||
                                !(
                                  (selectedCourier &&
                                    destinationCountryCode &&
                                    originCountryCode &&
                                    isPalletized !== null &&
                                    lengthUnit !== null) ||
                                  currentStep !== 0
                                )
                              }
                              onClick={() => setOpenImporterModal(true)}
                              placeholder={t(
                                'thirdPartyShipments.importer.placeholder'
                              )}
                            />
                          </Form.Item>
                          <ShipmentImporter
                            open={openImporterModal}
                            setOpen={setOpenImporterModal}
                            form={importerForm}
                            defaultImporter={defaultImporter}
                            destinationCountryCode={destinationCountryCode}
                            syncForm={() =>
                              form.setFieldValue(
                                'importer',
                                importerForm.getFieldValue('companyName')
                              )
                            }
                          />
                        </Col>
                      </Space>
                    </>
                  }
                />
                <Step
                  key="6"
                  title={
                    <Typography.Text className="shipment-step-title">
                      {isPalletized
                        ? t('externalShipment.step6TitlePalletized')
                        : t('externalShipment.step6Title')}
                    </Typography.Text>
                  }
                  status={
                    (selectedCourier &&
                      destinationCountryCode &&
                      originCountryCode &&
                      isPalletized !== null &&
                      lengthUnit !== null &&
                      products.length > 0) ||
                    currentStep !== 0
                      ? 'finish'
                      : 'wait'
                  }
                  description={
                    ((selectedCourier &&
                      destinationCountryCode &&
                      originCountryCode &&
                      isPalletized !== null &&
                      lengthUnit !== null &&
                      products.length > 0) ||
                      currentStep !== 0) && (
                      <Row className="rowContent">
                        <ThirdPartyShipmentBoxesStep
                          form={form}
                          lengthUnit={lengthUnit}
                          currentStep={currentStep}
                          initialBoxesNumber={initialBoxesNumber}
                          setMinShipmentWeight={setMinShipmentWeight}
                          isPalletized={isPalletized}
                          setInitialBoxesNumber={setInitialBoxesNumber}
                        />
                      </Row>
                    )
                  }
                />
              </Steps>
            </Spin>
          )}
          {selected === 0 && (
            <StickyAlert
              handleCancelForm={handleCancelForm}
              handleSaveDraft={() => handleSendDraft()}
              sendContent={onNextStep}
              firstDisabled={currentStep > 0}
              secondDisabled={currentStep > 0}
              thirdDisabled={currentStep > 0}
              fourthDisabled={!selectedCourier || currentStep > 0}
              hidden={currentStep > 0}
            />
          )}
          {selected === 1 &&
            currentStep < 2 &&
            [
              ShipmentCourierEnum.FEDEX,
              ShipmentCourierEnum.LAPCOURIER,
            ].includes(selectedCourier) && (
              <>
                <div className="title-content">
                  {t('thirdPartyShipments.titleStep2')}
                </div>
                <Steps
                  progressDot
                  direction="vertical"
                  className="detail-steps"
                >
                  <Step
                    key="1"
                    title={
                      <Typography.Text className="shipment-step-title">
                        {t('thirdPartyShipments.step2Subtitle1')}
                      </Typography.Text>
                    }
                    status={selected === 1 ? 'finish' : 'wait'}
                    description={
                      <Row className="rowContent">
                        {loadingRates && (
                          <Typography.Text className="grey-box">
                            Calculando costos de envío...
                          </Typography.Text>
                        )}
                        <ServiceSelect
                          shipments={[{}]}
                          setRatesAndTransitTimesPayload={
                            setRatesAndTransitTimesPayload
                          }
                          setRate={setRate}
                          setRates={setRates}
                          setLoadingRates={setLoadingRates}
                          selectedService={selectedService}
                          setSelectedService={setSelectedService}
                          courier={selectedCourier}
                        />
                      </Row>
                    }
                  />
                  {selectedCourier !== FBMCouriers.LAPCOURIER && (
                    <Step
                      key="2"
                      title={
                        <Typography.Text className="shipment-step-title">
                          {t('thirdPartyShipments.step2Subtitle2')}
                        </Typography.Text>
                      }
                      status={selectedService ? 'finish' : 'wait'}
                      description={
                        selectedService && (
                          <>
                            <Radio.Group
                              className="cardRadioGroupContent"
                              name="pickUpSelected"
                              onChange={(e) => {
                                setRadioDefault(true);
                                setIsPickup(e.target.value);
                              }}
                              style={{ marginTop: 24, marginBottom: 18 }}
                            >
                              <CheckCards
                                isPack={isPickup}
                                radioDefault={radioDefault}
                                inputRadioActive={{
                                  border: '1px solid rgba(0, 229, 166, 1)',
                                  backgroundColor: 'rgba(0, 229, 166, 0.05)',
                                }}
                                tag={false}
                                title={t(
                                  'orders.newOrder.pickUp.radioOneTitle'
                                )}
                                text={t('orders.newOrder.pickUp.radioOneText')}
                                value
                              />
                              <CheckCards
                                isPack={isPickup}
                                radioDefault={radioDefault}
                                inputRadioActive={{
                                  border: '1px solid rgba(0, 229, 166, 1)',
                                  backgroundColor: 'rgba(0, 229, 166, 0.05)',
                                }}
                                tag={false}
                                title={t(
                                  'orders.newOrder.pickUp.radioTwoTitle'
                                )}
                                text={t('orders.newOrder.pickUp.radioTwoText')}
                                value={false}
                              />
                            </Radio.Group>
                            <Row className="rowContent">
                              {isPickup && (
                                <Col span={24}>
                                  <div className="pickupDiv">
                                    <FedexPickup
                                      warehouseId={
                                        form.getFieldValue()?.warehouseId
                                      }
                                      courierCredentialId={
                                        couriers.find(
                                          (cred) =>
                                            cred.courierCompanyName ===
                                            FBMCouriers.FEDEX
                                        )?.id
                                      }
                                      showConfirmButton={false}
                                      setDateTime={setDateTime}
                                    />
                                  </div>
                                </Col>
                              )}
                              <Col span={24}>
                                {isPickup === false && (
                                  <div className="pickupDiv">
                                    <Row>
                                      <Col span={8}>
                                        <Form
                                          layout="vertical"
                                          onFinish={() => createShipment(false)}
                                          form={formOfficeDelivery}
                                        >
                                          <Form.Item
                                            label={
                                              <Tooltip
                                                title={t(
                                                  'orders.state.pickUp.referenceDate'
                                                )}
                                              >
                                                Fecha de entrega en oficina{' '}
                                                <InfoCircleOutlined />
                                              </Tooltip>
                                            }
                                            name="officeDeliveryDate"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  'Ingrese la fecha de entrega.',
                                              },
                                            ]}
                                          >
                                            <DatePicker
                                              className="datePickerStyle"
                                              placeholder="YYYY-MM-DD"
                                              disabledDate={disabledDate}
                                              style={{ width: '100%' }}
                                            />
                                          </Form.Item>
                                        </Form>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                                {!commercialInvoiceRequirements?.documentGeneration && (
                                  <Row style={{ marginTop: 5 }}>
                                    <InformationBox
                                      icon={<AlertOutlined />}
                                      status="warning"
                                      textInformationBox={t(
                                        'orders.documents.commercial_invoice_information_box'
                                      )}
                                      display="block"
                                    />
                                  </Row>
                                )}
                                <Button
                                  onClick={
                                    isPickup
                                      ? createShipment
                                      : () => formOfficeDelivery.submit()
                                  }
                                  className="nextButton"
                                  style={{ marginTop: 15 }}
                                  loading={sendLoading}
                                  disabled={
                                    steps.send === 'finish' || !selectedService
                                  }
                                >
                                  {isPickup
                                    ? t('shipments.buttons.createShipment')
                                    : t('common.confirm')}
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )
                      }
                    />
                  )}
                </Steps>
                {selectedCourier === FBMCouriers.LAPCOURIER &&
                  selectedService && (
                    <Row style={{ justifyContent: 'end' }}>
                      <Col>
                        <Button
                          onClick={() => createShipment(false)}
                          className="nextButton"
                          style={{ marginTop: 15 }}
                          loading={sendLoading}
                        >
                          {t('common.next')}
                        </Button>
                      </Col>
                    </Row>
                  )}
              </>
            )}
          {selected === 1 &&
            currentStep < 2 &&
            selectedCourier === FBMCouriers.INT99MINUTOS && (
              <PickUp99Minutos
                createShipment={createShipment}
                sendLoading={sendLoading}
                form={form99PickUp}
              />
            )}
          {selected === 1 &&
            currentStep < 2 &&
            selectedCourier === ShipmentCourierEnum.SHIPSMART && (
              <Row>
                <ShipsmartPickupSummaryStep
                  fboOrigin
                  data={shipsmartSummaryData}
                  setData={setShipsmartSummaryData}
                  hideSendButton
                />
                <Button
                  onClick={() => createShipment(false)}
                  className="nextButton"
                  style={{ marginTop: 15 }}
                  loading={sendLoading}
                >
                  {t('shipments.buttons.createShipment')}
                </Button>
              </Row>
            )}
        </Form>
        {selected === 2 && (
          <ThirdPartyShipmentReview
            thirdPartyShipmentId={thirdPartyShipmentId}
            commercialInvoiceRequirements={commercialInvoiceRequirements}
          />
        )}
      </Card>
    </Col>
  );
}

export default ThirdPartyShipment;
